import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class SubBanner extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: '',
            windowWidth: this.getAdjustedWindowWidth(),
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateWindowWidth);
      }
    
      componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowWidth);
      }
    
      getAdjustedWindowWidth = () => {
        // Calculate the actual window width minus the scrollbar width
        const windowWidth = window.innerWidth;
        const documentWidth = document.documentElement.clientWidth;
        return windowWidth - (windowWidth - documentWidth); 
      };
    
      updateWindowWidth = () => {
        this.setState({ windowWidth: this.getAdjustedWindowWidth() });
      };

    render() {

        const {children, image, title, heading, description, hideNav} = this.props;
        const { windowWidth } = this.state;
        if(children){
            return (children);
        }

        return (
                <div
                    style={{ 
                        backgroundImage: `url(${image})`, 
                        width: `${windowWidth}px`
                    }}
                    className={`sub-banner ${!hideNav ? 'overview-bgi': ''} sub-banner-real-estate`}>
                    <div className="container">
                        <div className="breadcrumb-area">
                            {(title) && <h1>{title}</h1>}
                            {(heading) && <h1>{heading}</h1>}
                            {(description) && <p style={{color:'#fff'}}>{description}</p>}
                            {(!hideNav) && <ul className="breadcrumbs">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="active">{title}</li>
                            </ul>}
                        </div>
                    </div>
                </div>
        )
    }
}

export default SubBanner
