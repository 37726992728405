import React, { Component } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody, Container } from "reactstrap";
import firebase from "firebase/app";
import "firebase/firestore";
import _ from "lodash";

class AgentInformationModal extends Component {
  state = {
    agentInfo: false,
    buyerModal: false,
    logo: null,
    image: "/images/face-placeholder.gif",
    agent: null,
    // this.props.globalAgents: this.props.user?.hasOwnProperty("myGlobalAgents")
    //   ? this.props.user.myGlobalAgents
    //   : [],
    added: false,
  };

  componentDidMount() {
    this.setState({
      added: this.props.globalAgents?.some(
        (agent) => agent.agentName === this.props.offer.agentName
      ),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.globalAgents !== this.props.globalAgents) {
      this.setState({
        added: this.props.globalAgents?.some(
          (agent) => agent.agentName === this.props.offer.agentName
        ),
      });
    }
  }

  setImageById = (id) => {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const realtorImageRef = storageRef.child(`images/users/${id}.jpg`);
    const builderLogoRef = storageRef.child(`images/logos/${id}.jpg`);
    builderLogoRef
      .getDownloadURL()
      .then((url) => {
        this.setState({ logo: url });
      })
      .catch((error) => {});
    realtorImageRef
      .getDownloadURL()
      .then((url) => {
        this.setState({ image: url });
      })
      .catch((error) => {});
  };

  constructor(props) {
    super(props);
    const { offer, sellerAgent } = this.props;
    const db = firebase.firestore();
    if (sellerAgent) {
      db.collection("realtors")
        .where("realtor_name", "==", offer.viewedBy)
        .get()
        .then((snap) => {
          if (snap.docs.length > 0) {
            const doc = snap.docs[0];
            this.setImageById(doc.id);
            const data = doc.data();
            data.id = doc.id;
            this.setState({ agent: data });
          } else {
            console.error(
              "No Agent information found. using given number. this should not happen."
            );
          }
        });
    } else {
      if (offer.licenseNo) {
        db.collection("realtors")
          .where("realtor_license", "==", offer.licenseNo)
          .get()
          .then((snap) => {
            if (snap.docs.length > 0) {
              const doc = snap.docs[0];
              this.setImageById(doc.id);
              const data = doc.data();
              data.id = doc.id;
              this.setState({ agent: data });
            } else {
              console.error(
                "No Agent information found. using given number. this should not happen."
              );
            }
          });
      }
    }
  }

  render() {
    const { agentInfo, agent, image, added, buyerModal } = this.state;
    const { offer, comingFrom, sellerAgent, buyerInfo } = this.props;

    return (
      <>
        <td>
          <p
            onClick={(e) => {
              this.setState(buyerInfo ? { buyerModal: true } : { agentInfo: true });
              e.preventDefault();
            }}
            className={
              comingFrom
                ? `underline black-cursor-pointer ${comingFrom==="globalLocationChart" ? "mb-0" : ""}`
                : "analytics-lined-td"
            }
          >
            {buyerInfo ? offer?.buyerName : sellerAgent ?
              offer?.viewedBy : offer?.agentName}
          </p>
        </td>
        {comingFrom === "globalLocationChart" &&
          `, ${agent?.brokerage_name || offer?.brokerageName}`}
        <Modal
          dal
          isOpen={agentInfo}
          size="md"
          fade={false}
          toggle={() => this.setState({ agentInfo: !agentInfo })}
        >
          <ModalHeader
            className="modal-header"
            toggle={() => this.setState({ agentInfo: !agentInfo })}
          ></ModalHeader>
          <ModalBody className="modal-body">
            <Container>
              <Row>
                <Col>
                  <div className="flexCenter">
                    <img
                      alt="Agent"
                      src={image}
                      className="analytics-agent-img"
                    />
                    <h3>{agent?.realtor_name || offer.agentName}</h3>
                  </div>
                  <div className="analytics-agent-details">
                    <div className="analytics-row center-me mt-10 flex-row">
                      <i className="fa fa-phone phone-icon"></i>
                      <p>{agent?.realtor_phone || offer?.phoneNumber}</p>
                    </div>

                    <div className="analytics-row center-me flex-row">
                      <i className="fa fa-envelope"></i>
                      <p>{agent?.realtor_email || offer?.email}</p>
                    </div>

                    {agent && (
                      <div className="analytics-row center-me">
                        <p style={{ fontSize: 14, marginTop: "3px" }}>
                          <i className="flaticon-pin" /> {agent?.street_number}{" "}
                          {agent?.street_name}, {agent?.city} {agent?.state}{" "}
                          {agent?.zip_code}
                        </p>
                      </div>
                    )}

                    <small style={{ fontWeight: "bold" }}>Brokerage</small>
                    <h6 style={{ marginBottom: 0 }}>
                      {agent?.brokerage_name || offer?.brokerageName}
                    </h6>
                    <small style={{ fontWeight: "bold" }}>License #</small>
                    <p style={{ fontSize: 14 }}>
                      {agent?.realtor_license || offer?.licenseNo}
                    </p>
                  </div>
                  <div className="row mt-4 mb-5">
                    <div className="col-12 col-md-6 p-0 m-0 px-1 mx-auto">
                      <button
                        className="cancel-agent-btn mr-0 w-100 mb-2 mb-md-0"
                        onClick={() => this.setState({ agentInfo: false })}
                      >
                        Close
                      </button>
                    </div>

                    {comingFrom === "globalOffers" &&
                      offer?.sellerEmail != this.props.user.email && (
                        <div className="col-12 col-md-6 p-0 m-0 px-1">
                          <button
                            className="add-agent-btn mr-0 w-100"
                            onClick={() => {
                              const db = firebase.firestore();
                              if (!added) {
                                // this.state.this.props.globalAgents.push(this.props.offer);
                                db.collection("users")
                                  .doc(this.props.user.uid)
                                  .update({
                                    myGlobalAgents:
                                      firebase.firestore.FieldValue.arrayUnion(
                                        this.props.offer
                                      ),
                                  })
                                  .then(() => {
                                    console.log("succeed");
                                    this.props.getGlobalAgents();
                                  });
                                this.setState({ added: true });
                              } else {
                                // this.state.this.props.globalAgents.filter(
                                //   (offer) =>
                                //     offer.agentName !== this.props.offer.agentName
                                // );
                                db.collection("users")
                                  .doc(this.props.user.uid)
                                  .update({
                                    myGlobalAgents:
                                      firebase.firestore.FieldValue.arrayRemove(
                                        this.props.offer
                                      ),
                                  })
                                  .then(() => {
                                    console.log("succeed");
                                    this.props.getGlobalAgents();
                                  });
                                this.setState({ added: false });
                              }
                            }}
                          >
                            <i
                              class={`fa ${
                                !added ? "fa-user-plus" : "fa-user-times"
                              } mr-2`}
                              aria-hidden="true"
                            ></i>{" "}
                            {added ? "Remove Agent" : "Add Agent"}
                          </button>
                        </div>
                      )}
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>


        <Modal
          dal
          isOpen={buyerModal}
          size="md"
          fade={false}
          toggle={() => this.setState({ buyerModal: !buyerModal })}
        >
          <ModalHeader
            className="modal-header"
            toggle={() => this.setState({ buyerModal: !buyerModal })}
          ></ModalHeader>
          <ModalBody className="modal-body">
            <Container>
              <Row>
                <Col>
                  <div className="flexCenter">
                    <img
                      alt="Agent"
                      src={image}
                      className="analytics-agent-img"
                    />
                    <h3>{offer?.buyerName}</h3>
                  </div>
                  <div className="analytics-agent-details">
                    {offer?.buyerPhoneNumber && <div className="analytics-row center-me mt-10 flex-row">
                      <i className="fa fa-phone phone-icon"></i>
                      <p>{offer?.buyerPhoneNumber}</p>
                    </div>}

                    {offer?.buyersEmail && <div className="analytics-row center-me flex-row">
                      <i className="fa fa-envelope"></i>
                      <p>{offer?.buyersEmail}</p>
                    </div>}

                  </div>
                  <div className="row mt-4 mb-5">
                    <div className="col-12 col-md-6 p-0 m-0 px-1 mx-auto">
                      <button
                        className="cancel-agent-btn mr-0 w-100 mb-2 mb-md-0"
                        onClick={() => this.setState({ buyerModal: false })}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default AgentInformationModal;
