import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import SellerList from 'components/SellerList';
import { getUsersSellers } from './../Services/SellerService';
import "react-toastify/dist/ReactToastify.css";
import { Button } from 'reactstrap';
import qs from 'query-string';
import '../assets/css/sellers.css';
import Axios from 'axios';

class Sellers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            sellers: [],
            filteredSellers: [],
            loading: true,
            showDetails: true,
            searchTerm: '',
            offerStatusFilter: '',
            statusFilterOptions: [
                'Active',
                'Active Option Contract',
                'Pending',
                'Active Contingent',
                'Active Kick Out',
                'Temporary Off Market',
                'Cancelled',
                'Withdrawn',
                'Closed',
            ],
        };
    }

    componentDidMount() {
        this.mounted = true;

        const { user } = this.props;
        const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        const { searchId } = query;
        let showDetails = true;
        if (searchId) showDetails = false;

        getUsersSellers(user, searchId)
            .then(sellers => {
                if (this.mounted) {
                    const flattenedSellers = sellers.map(seller => ({
                        ...seller.data(),
                        id: seller.id,
                    }));

                    const storage = firebase.storage();
                    const storageRef = storage.ref();

                    flattenedSellers.forEach(seller => {
                        const sellerImageRef = storageRef.child(`images/properties/${seller.id}.jpg`);
                        sellerImageRef.getDownloadURL()
                            .then(url => {
                                seller.photo = url;
                                if (this.mounted) {
                                    this.setState({ sellers: flattenedSellers, filteredSellers: flattenedSellers });
                                }
                            })
                            .catch(error => {});
                    });

                    if (this.mounted) {
                        this.setState({ sellers: flattenedSellers, filteredSellers: flattenedSellers, loading: false, showDetails });
                    }
                }
            })
            .catch(error => {
                if (this.mounted) {
                    console.error(error);
                    this.setState({ loading: false, error });
                }
            });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        const searchKeys = [
            "address",
            "seller_name",
            "street_number",
            "year_built",
            "listing_price",
            "realtor_name",
            "represendtedBy",
            "sqft",
            "subdivision",
            "street_name",
            "bm_purchase_price",
            "location",
            "search",
        ];
    
        const filteredSellers = this.state.sellers.filter(seller => 
            searchKeys.some(key => {
                const value = seller[key];
                
                if (!value) return false;
    
                if (key === "location") {
                    const locationString = `${value.name || ""} ${value.lat || ""} ${value.lng || ""}`.toLowerCase();
                    return locationString.includes(searchTerm);
                }
    
                if (key === "search" && Array.isArray(value)) {
                    return value.some(searchTermItem => searchTermItem.toLowerCase().includes(searchTerm));
                }
    
                return value.toString().toLowerCase().includes(searchTerm);
            })
        );
        this.setState({ searchTerm, filteredSellers});
    };
    

    filterByStatus = (status) => {
        const { user } = this.props;
        this.setState({ offerStatusFilter: status, loading: true });
    
        getUsersSellers(user, { status })
            .then(sellers => {
                if (this.mounted) {
                    const flattenedSellers = sellers.map(seller => ({
                        ...seller.data(),
                        id: seller.id,
                    }));
    
                    const storage = firebase.storage();
                    const storageRef = storage.ref();
    
                    flattenedSellers.forEach(seller => {
                        const sellerImageRef = storageRef.child(`images/properties/${seller.id}.jpg`);
                        sellerImageRef.getDownloadURL()
                            .then(url => {
                                seller.photo = url;
                                if (this.mounted) {
                                    this.setState({ sellers: flattenedSellers, filteredSellers: flattenedSellers, loading: false });
                                }
                            })
                            .catch(error => {});
                    });
    
                    if (this.mounted) {
                        this.setState({ sellers: flattenedSellers, filteredSellers: flattenedSellers, loading: false });
                    }
                }
            })
            .catch(error => {
                if (this.mounted) {
                    console.error(error);
                    this.setState({ loading: false, error });
                }
            });
    };    

    copyToClipboard = async () => {
        // let { user } = this.props;
        // let url = firebaseConfig.host+'/share/sellers/'+user.uid;
        // copy(url, {
        //     debug: true
        //   });
        // toast.success('URL has been copied to your clipboard successfully.', {
        //     position: toast.POSITION.TOP_RIGHT
        // });

        alert(`Data will be updated in a few minutes.`)
        await Axios.get(`https://us-central1-homematchx-prod.cloudfunctions.net/updateSellersOnDemand`)
    }

    render() {
        const { loading, error, searchTerm, filteredSellers, statusFilterOptions, offerStatusFilter, showDetails } = this.state;
        const { user } = this.props;
        const sellerLabel = user.role === 'builder' ? 'Builder Homes' : 'My Properties';
        const createSellerLabel = user.role === 'builder' ? 'Create Home' : 'Create Seller';
        return (
            <div className="content-area5">
                <div className="dashboard-content">
                <div className="dashboard-header clearfix">
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <h4 className="seller-main-heading">{sellerLabel}</h4>
                            </div>
                            <div className="col-sm-12 col-md-12 mobile-btn-view-container row-spacebtwn align-item-center seller-filters-mob-view">
                                <input
                                    type="text"
                                    placeholder="Search sellers..."
                                    value={searchTerm}
                                    onChange={this.handleSearch}
                                    className="search-box"
                                />
                                <div>
                                    <div className="d-inline-block mob-status-filter">
                                        <span className="period-filter-label mob-no-label">Status:</span>
                                        <div className="d-inline-block">
                                        <select
                                            name="offerStatusFilter"
                                            value={offerStatusFilter}
                                            onChange={e => this.filterByStatus(e.target.value)}
                                            className="search-fields offers-search-fields mr-0 mr-md-4 sellers-status-filter"
                                        >
                                            <option value="">Select status</option>
                                            {statusFilterOptions.map((status, i) => (
                                                <option key={i} value={status}>{status}</option>
                                            ))}
                                        </select>
                                        </div>
                                    </div>
                                    <Button className="back-btn back-sellers margin-top-none mobile-responsive-back-btn">
                                        Back
                                    </Button>
                                    <Button
                                        className="share-btn mr-8 margin-top-none mobile-responsive-share-btn update-sellers-mob-view"
                                        onClick={this.copyToClipboard}
                                        >
                                        Update Sellers
                                    </Button>
                                    <Link to="/profiles/sellers/new" className="btn create-seller-btn">
                                        {createSellerLabel}
                                    </Link>
                                </div>
                            </div>
                            <div className="col">

                            </div>
                        </div>
                    </div>
                    <div className="dashboard-list dashboard-list-single sellers-bg-transparent">
                        {loading ? (
                            <p>Loading...</p>
                        ) : error ? (
                            <p>{error}</p>
                        ) : (
                            <SellerList
                                sellers={filteredSellers}
                                user={user}
                                showDetails={showDetails}
                                remove={this.removeSeller}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Sellers;
