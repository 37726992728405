import React, { Component } from "react";
import firebase from "firebase/app";
import { Tabs, Tab } from "react-bootstrap";
import "firebase/firestore";
import "firebase/storage";
import {
  Label,
  UncontrolledCollapse,
  FormGroup,
  Table,
  Card,
  CardBody,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Form,
  Alert,
} from "reactstrap";
import Select from "react-select";
import XLSX from "xlsx";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import shareOffersTemplate from "../templates/email/share-offers";
import { Link } from "react-router-dom";
import qs from "query-string";
import moment from "moment";
import Loader from "react-loader-spinner";
import { Tooltip } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropzone from "react-dropzone";
import OfferDetails from "components/Offers/OfferDetails";
import offerDetails from "../templates/email/offer-details";
import NumberFormat from "react-number-format";
import ConfirmationModal from "components/Common/Modals/ConfirmationModal";
import ShareButtonsModal from "components/Common/Modals/ShareButtonsModal";
import InstantOfferDetails from "components/Offers/InstantOfferDetails";
import "../assets/css/offer-details.css";
import House from "../assets/icons/gallery/house-avatar.jpg";
import Replay from "../assets/icons/replay_arrow.svg";
import ReplayWhite from "../assets/icons/replay_arrow_white.svg";
import Person from "../assets/icons/person.svg";
import Building from "../assets/icons/building.svg";
import Transaction from "../assets/icons/transaction.svg";
import Lender from "../assets/icons/lender.svg";
import Calendar from "../assets/icons/calendar.svg";
import Flag from "../assets/icons/flag-21.svg";
import BackArrow from "../assets/icons/back_arrow.svg";
import nextPrev from "../assets/icons/next-prev.png";
import Tick from "../assets/icons/tick.svg";
import Cross from "../assets/icons/cross.svg";
import AgentAvatar from "../assets/icons/agent.jpg";
import Download from "../assets/icons/Plus.svg";
import Arrow from "../assets/icons/arrow.png";
import Archive from "../assets/icons/Archive.svg";
import DropDownArrow from "../assets/icons/drop-down-arrow.svg";
import DropDownArrowRed from "../assets/icons/drop-down-arrow-red.svg";
import Plus from "../assets/icons/add.svg";
import Minus from "../assets/icons/minus.svg";
import VerifiedListing from "../assets/icons/verified-icon.svg";
import OfferComments from "../components/OfferComments";
import OfferSubmitComment from "../components/OfferSubmitComment";
import { saveActivity } from "../Services/Activity";
import AgentInformationModal from "../components/Analytics/AgentInformationModal";
import { generateZipFromCloud } from "../Services/generate-zip";
import Share from "../assets/icons/iconizer-Share.svg";
import { useEffect } from "react";
import { all, select } from "underscore";
import { add } from "lodash";
import sendToTitleEmail from "../templates/email/send-to-title-email";
import TimeAgo from "react-timeago";
import { shortURL } from "helpers/url-shortner";
import { file } from "jszip";
import Emails from "components/OfferDetailsPage/Emails";

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const storage = firebase.storage();

const secondaryConfig = {
  apiKey: "AIzaSyArN1jui5mPGdGydl89WaIXMULJhjD3VIw",
  authDomain: "homematchx-prod.firebaseapp.com",
  databaseURL: "https://homematchx-prod.firebaseio.com",
  projectId: "homematchx-prod",
  storageBucket: "homematchx-prod.appspot.com",
  messagingSenderId: "182635199829",
  measurementId: "G-6Z98DBM1LY",
  appId: "1:182635199829:web:bc8ce8e65a706e1b6983dc",
  cloudFunctionHost: "https://us-central1-homematchx-prod.cloudfunctions.net",
};

const secondaryApp = firebase.initializeApp(secondaryConfig, "Secondary");

let { logEvent } = firebase.analytics();

const grid = 8;

const theme = {
  font: "Poppins, sans-serif",
  id: "__default",
  background: "#222222",
  fonts: {
    main: "Poppins, sans-serif",
    secondary: "Poppins, sans-serif",
  },
  border: {
    color: "#222222",
    radius: "1px",
  },
  timeline: {
    color: "#f5f5f5",
    spacing: {
      top: "10px",
      bottom: "10px",
    },
    point: {
      spacing: {
        top: "0px",
        bottom: "2px",
      },
    },
  },
  header: {
    color: "#f5f5f5",
  },
  card: {
    colors: {
      text: "#f5f5f5",
      background: "#222222",
      active: {
        border: "#222222",
        background: "#222222",
        text: "#f5f5f5",
      },
    },
    //   shadow: "2px 2px 4px 0px rgba(0,0,0, 0.2)",
    spacing: {
      top: "10px",
      bottom: "15px",
    },
  },
  panel: {
    colors: {
      title: "#5F646A",
      subtitle: "#5F646A",
      content: "#5F646A",
    },
    padding: {
      left: "40px",
      right: "20px",
    },
  },
  breakpoints: {
    lg: "1200px",
    md: "1040px",
    sm: "720px",
    xs: "400px",
  },
};

const dummyEmailData = {
  from: "dummy@email.com",
  to: "dummy@email.com",
  subject: "dummy subject",
  body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies ultrices, nunc nisl aliquam nunc, quis aliquet ni
  sl nunc eget nisl. Donec euismod, nisl eget ultricies ultrices, nunc nisl aliquam nunc, quis aliquet nisi nunc eget nisl. Donec euismod, nisl eget ultricies ultrices, nunc nisl aliquam nunc, quis aliquet nisi nunc eget nisl. Donec euismod, nisl eget ultricies ultrices, nunc nisl aliquam nunc, quis aliquet nisi nunc eget nisl. Donec euismod, nisl eget ultricies ultrices, nunc nisl aliquam nunc, quis aliquet nisi nunc eget nisl. Donec euismod, nisl eget ultricies ultrices, nunc nisl aliquam nunc, quis aliquet nisi nunc eget nisl.`,
  attachments: [],
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: "8px 16px",
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "#ffa5b8" : "white",
  ...draggableStyle,
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getList1Style = () => ({
  background: "#EBECF0",
  // background: isDraggingOver ? '#EBECF0' : '#ffeaee',
  padding: grid * 2,
  width: "50%",
});

const getList2Style = (isDraggingOver) => ({
  background: isDraggingOver ? "#ffeaee" : "#EBECF0",
  padding: grid * 2,
  width: "50%",
  border: isDraggingOver ? "1px dashed #ff214f" : 0,
});

class Offers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessage: true,
      sendSMSModal: false,
      showOfferTrendsMobile: window.innerWidth > 480 ? true : false,
      updateModalText: "Update Offer",
      smsBlast: false,
      error: "",
      loading: true,
      isBuyer: false,
      isOpened1: false,
      isOpened2: false,
      isOpened3: false,
      isOpened4: false,
      isOpened5: false,
      isOpened6: false,
      isOpened7: false,
      isOpened8: false,
      isOpened9: false,
      isOpened10: false,
      isOpened11: false,
      isOpened12: false,
      isOpened13: false,
      isOpened14: false,
      isOpened15: false,
      isOpened16: false,
      isOpened17: false,
      isUploading: false,
      counterOfferModal: false,
      executionModal: false,
      offer: {
        status: null,
        updateHistory: [],
        acceptComment: "",
        status: "",
      },
      allOffers: [],
      avgFinancingType: "",
      totalOptionDays: "",
      totalDownPayment: "",
      totalOptionFee: "",
      avgCloseingDate: "",
      avgViewingTime: "",
      totalEarnestMoney: "",
      totalRightToTerminate: "",
      totalSaleOfProperty: "",
      totalExpenseType: "",
      totalPosseions: "",
      offerAudio: null,
      offerVideo: null,
      counterOfferData: {},
      executeOfferData: {},
      showArchived: false,
      archivedDocs: [],
      uploadingDocument: false,
      seller: {
        seller_name: "",
        agents: [],
      },
      deletingOffer: false,
      offerActions: false,
      agentModal: false,
      realtor: null,
      verifiedLoading: false,
      isTooltip: false,
      updatedDocs: [],
      sendToTitleUpdatedDocs: [],
      notifications: [],
      unreadNotifications: 0,
      smsTxt:
        "You made an offer on one or more of %name% with Ink Realty Group listings. They have a new listing you may be interested in at %sellerAddress%: https://homematchx.com/sellers/%listingKey%",

      offersToShare: [],
      shareOffersModel: false,
      shareOfferEmail: null,
      shareOfferName: null,
      sendingOffers: false,
      acceptCommentModal: false,
      selectedSellers: [],
      sendOfferToSellerModal: false,
      sendingOffersToSeller: false,
      isAllOffers: false,
      sendingOffersToSellerSMS: false,
      groups: [],
      selectedGroup: {},
      verifyOfferModal: false,
      verifyOffer: false,
      greenColor: false,
      sendToTitleModal: false,
      sellerAgents: [],
      keyDates: {},
      sendToTitleDocs: [],
      sendingOfferToTitle: false,
      buyers: [],
      showSellerCompanyAlert: false,
      emailBody: "",
      emailClosing:
        "Should you have any questions or would like to speak about the executed contract, please contact me below. I look forward to partnering with you.",
      sendToTitleIncludeOptions: false,
      includeOptions: {
        sellers: true,
        buyersAgent: true,
        listingAgent: true,
        transactionCoordinator: true,
        lender: true,
      },
      options: [
        {
          label: "Sellers",
          value: "sellers",
        },
        {
          label: "Buyers Agent",
          value: "buyersAgent",
        },
        {
          label: "Listing Agent",
          value: "listingAgent",
        },
        {
          label: "Transaction Coordinator",
          value: "transactionCoordinator",
        },
        {
          label: "Lender",
          value: "lender",
        },
      ],
      titleCompanyModal: false,
      newTitleCompanyId: "",
      tcAgentModal: false,
      spreadsheetSelected: [],
      spreadsheetOptions: [
        {
          key: "address",
          name: "Address",
        },
        {
          key: "agentName",
          name: "Agent Name",
        },
        {
          key: "bathrooms",
          name: "Bathrooms",
        },
        {
          key: "bedrooms",
          name: "Bedrooms",
        },
        {
          key: "brokerageName",
          name: "Brokerage Name",
        },
        {
          key: "buyerEmail",
          name: "Agent Email",
        },
        {
          key: "buyerName",
          name: "Buyer Name",
        },
        {
          key: "closingDate",
          name: "Closing Date",
        },
        {
          key: "comments",
          name: "Comments",
        },
        {
          key: "createdAt",
          name: "Created At",
        },
        {
          key: "earnestMoney",
          name: "Earnest Money",
        },
        {
          key: "email",
          name: "Email",
        },
        {
          key: "featured_image",
          name: "Featured Image",
        },
        {
          key: "financeAmount",
          name: "Finance Amount",
        },
        {
          key: "financingType",
          name: "Financing Type",
        },
        {
          key: "leasebackDays",
          name: "Leaseback Number of Days",
        },
        {
          key: "licenseNo",
          name: "License No",
        },
        {
          key: "nonRealtyItem",
          name: "Non Realty Item",
        },
        {
          key: "purchasePrice",
          name: "Purchase Price",
        },
        {
          key: "phoneNumber",
          name: "Phone Number",
        },
        {
          key: "possession",
          name: "Possession",
        },
        {
          key: "actual_price",
          name: "Listing Price",
        },
        {
          key: "saleOfOtherProperty",
          name: "Sale Of Other Property",
        },
        {
          key: "search",
          name: "Search",
        },
        {
          key: "sellerEmail",
          name: "Seller Email",
        },
        {
          key: "seller_id",
          name: "Seller Id",
        },
        {
          key: "sqft",
          name: "Sqft",
        },
        {
          key: "status",
          name: "Status",
        },
        {
          key: "statusChangedAt",
          name: "Status Changed At",
        },
        {
          key: "titlePolicyExpense",
          name: "Title Policy Expense",
        },
        {
          key: "updateHistory",
          name: "Update History",
        },
        {
          key: "viewed",
          name: "Viewed",
        },
        {
          key: "viewedAt",
          name: "Viewed At",
        },
        {
          key: "viewedBy",
          name: "Viewed By",
        },
        {
          key: "optionFee",
          name: "Option Fee",
        },
        {
          key: "optionDays",
          name: "Option Days",
        },
        {
          key: "rightToTerminate",
          name: "Right to Terminate",
        },
        {
          key: "residentialServiceContract",
          name: "Residential Service Contract",
        },
        {
          key: "downPayment",
          name: "Down Payment",
        },
      ],
      isAttachSpreadsheet: false,
      spreadSheetModal: false,
      smsTemplates: [
        {
          label:
            "You made an offer on one or more of %name% with Ink Realty Group listings. They have a new listing you may be interested in at %sellerAddress%: https://homematchx.com/sellers/%listingKey%",
          value: "default",
        },
        {
          label:
            "Hi %agentName% we have multiple offers on %sellerAddress%. Deadline is %deadline%.",
          value: "hi_agent_we_have_multiple_offers",
        },
        {
          label:
            "Thank you for submitting an offer. The seller is reviewing all offers and will make a decision soon.",
          value: "thank_you_for_submitting_an_offer",
        },
        {
          label: "Seller is reviewing all offers and will make a decision soon",
          value: "seller_is_reviewing_all_offers_and_will_make_a_decision_soon",
        },
        {
          label:
            "You represented a cash offer buyer on one of my listings and we are looking for a cash offer on this new listing %sellerAddress%",
          value:
            "you_represented_a_cash_offer_buyer_on_one_of_my_listings_and_we_are_looking_for_a_cash_offer_on_this_new_listing",
        },
        {
          label:
            "If you are receiving this message your offer has been received and viewed by the Listing Agent. Thank you!",
          value:
            "if_you_are_receiving_this_message_your_offer_has_been_received_and_viewed_by_the_listing_agent_thank_you",
        },
        {
          label: "The counteroffer has been submitted to the buyer.",
          value: "the_counteroffer_has_been_submitted_to_the_buyer",
        },
        {
          label: "The counteroffer has been submitted to the seller.",
          value: "the_counteroffer_has_been_submitted_to_the_seller",
        },
        {
          label:
            "The seller has agreed to the buyers offer terms and will accept the offer.",
          value:
            "the_seller_has_agreed_to_the_buyers_offer_terms_and_will_accept_the_offer",
        },
        {
          label:
            "The buyer has agreed to the sellers offer terms and will accept the offer.",
          value:
            "the_buyer_has_agreed_to_the_sellers_offer_terms_and_will_accept_the_offer",
        },
        {
          label:
            "The seller has agreed to the buyers offer terms and will not accept the offer.",
          value:
            "the_seller_has_agreed_to_the_buyers_offer_terms_and_will_not_accept_the_offer",
        },
        {
          label:
            "The buyer has agreed to the sellers offer terms and will not accept the offer.",
          value:
            "the_buyer_has_agreed_to_the_sellers_offer_terms_and_will_not_accept_the_offer",
        },
      ],
      deadline: null,
      isSendFuture: false,
      futureDate: null,
      smsBlastSelectedAgents: [],
      isEditDocName: {
        status: false,
        docName: null,
      },
      offerStatusFilter: "",
      offerStatusFilterOptions: [
       
    ],
    };
    this.videoRef = React.createRef();
  }

  spreadSheetToggle = () => {
    this.setState({
      spreadSheetModal: !this.state.spreadSheetModal,
    });
  };

  async componentDidMount() {
    const id = this.props.match.params.id;
    const search = qs.parse(this.props.location.search);
    const { curItem, totalItems } = search;
    const { user } = this.props;
    const isBuyer = user.uid === this.props.location.state?.offer?.sender;
    this.setState({ curItem, totalItems, isBuyer });
    
    try {
      const db = firebase.firestore();

      const doc = await db.collection("offers").doc(id).get();

      this.getUserSpreadSheetAttributes();
      const offer = { id: id, ...doc.data() };

      let seller = null;
      let allOffers = null;
      let haveAccepted = false;
      let offersDocs;
      if (offer.seller) {
        const sellerdoc = await db
          .collection("sellers")
          .doc(offer.seller)
          .get();
        const offersDoc = await db
          .collection("offers")
          .where("seller", "==", offer.seller)
          .get();
        allOffers = offersDoc.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
          };
        });

        const offers = await db
          .collection("offers")
          .where("seller", "==", offer.seller)
          .where("verified", "==", true)
          .get();

        const offers2 = await db
          .collection("offers")
          .where("seller", "==", offer.seller)
          .where("status", "==", "Executed")
          .get();

        await db
          .collection("network")
          .doc(user?.uid)
          .collection("groups")
          .onSnapshot((snaps) => {
            const groups = snaps.docs.map((doc) => {
              return {
                id: doc.id,
                ...doc.data(),
              };
            });
            this.setState({ groups });
          });

        offersDocs = ([...offers.docs, ...offers2.docs] || []).map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
          };
        });
        seller = { id: id, ...sellerdoc.data() };
      }

      let realtor = null;
      if (seller?.seller_email) {
        const realtorDoc = await db
          .collection("realtors")
          .where("realtor_email", "==", seller.seller_email)
          .get();
        realtorDoc.forEach((realtor) => {
          realtor = realtor.data();
        });
      }
      this.setState({
        realtor,
      });

      const otherAcceptedOfferSnapshot = await db
        .collection("offers")
        .where(firebase.firestore.FieldPath.documentId(), "!=", id)
        .where("seller", "==", offer.seller)
        .where("status", "==", "Accepted")
        .get();

      const otherAcceptedOffer = [];
      otherAcceptedOfferSnapshot.forEach((dc) => {
        otherAcceptedOffer.push(dc.data());
      });
      const isOtherOfferAccepted = !!otherAcceptedOffer.length;

      if (offer.executeOfferData) {
        this.setState({ greenColor: true });
      }

      this.setState(
        {
          archivedDocs: user?.archivedDocuments || [],
          offer,
          offerVideo: offer?.offer_video,
          offerAudio: offer?.offer_audio,
          isBuyer: user.uid === offer.sender,
          isOtherOfferAccepted,
          loading: false,
          seller: seller,
          keyDates: {
            ...this.state.keyDates,
            closingDate: offer.closingDate,
          },
          sellerAgents:
            seller?.agents?.map((agent) => {
              return {
                value: agent.agent_name,
                label: agent.agent_name,
              };
            }) || [],
          allOffers,
          totalPurchasePrice: offersDocs.length
            ? this.calculatePrice(offersDocs)
            : 0,
          avgFinancingType: offersDocs.length
            ? this.findFinancingType(offersDocs)
            : 0,
          totalOptionDays: offersDocs.length ? this.optionDays(offersDocs) : 0,
          avgCloseingDate: offersDocs.length
            ? this.calculateNumberOfDays(offersDocs)
            : 0,
          totalEarnestMoney: offersDocs.length
            ? this.calculateEarnestMoney(offersDocs)
            : 0,
          avgViewingTime: offersDocs.length
            ? this.calculateViewTime(offersDocs)
            : 0,
          totalDownPayment: this.calculateDownPayment(offersDocs),
          totalPosseions: this.getAllPossession(offersDocs),
          totalOptionFee: this.optionFee(offersDocs),
          totalRightToTerminate: this.getAllRightToTerminate(offersDocs),
          totalSaleOfProperty: this.getAllSaleOfProperty(offersDocs),
          totalExpenseType: this.getAllExpenseType(offersDocs),
          smsTxt: this.state.smsTxt
            .replace("%name%", user.realtor_name)
            .replace("%sellerAddress%", seller.UnparsedAddress)
            .replace("%listingKey%", seller.id),
          smsTemplates: this.state.smsTemplates.map((template) => {
            return {
              ...template,
              label: template.label
                .replace("%name%", user.realtor_name)
                .replace("%sellerAddress%", seller.UnparsedAddress)
                .replace("%listingKey%", seller.id),
            };
          }),
        },
        () => this.documentsCode()
      );

      if (!offer.viewed && !this.props.location.state?.offer?.viewed) {
        await db
          .collection("offers")
          .doc(id)
          .update({
            viewed: true,
            viewedAt: moment().utc().unix(),
            viewedBy: this.props.user.full_name || this.props.user.realtor_name,
          });

        if (offer.buyer) {
          await db.collection("notifications").add({
            user_id: offer.buyer,
            property_id: offer.id,
            text: `Offer has been viewed by the seller agent.`,
            type: `offer`,
            isRead: 0,
            createdAt: moment().utc().unix(),
          });
        }

        const sendSMS = firebase.functions().httpsCallable("sendSMS");
        await sendSMS({
          to: `+1${offer.phoneNumber}`,
          body: `OFFER VIEWED at ${moment().format("YYYY-MM-DD hh:mmA")}, ${
            this.props.user.full_name || this.props.user.realtor_name
          }, ${offer.address}
Details: https://homematchx.com/offers/${offer.id}`,
        });
      }

    } catch (error) {
      this.setState({
        loading: false,
        error: error.message || "Something went wrong, please try again !",
      });
    }
  }

  archiveOrUnarchiveOffer = async () => {
    try {
      const { offer } = this.state;
      const { user } = this.props;
      const db = firebase.firestore();
      const userId = user?.uid;
  
      if (offer && offer.id && userId) {
        const isArchived = offer.archived?.includes(userId);
  
        // Update Firestore with arrayUnion or arrayRemove
        await db.collection("offers").doc(offer.id).update({
          archived: isArchived
            ? firebase.firestore.FieldValue.arrayRemove(userId)
            : firebase.firestore.FieldValue.arrayUnion(userId),
        });
  
        const updatedArchived = isArchived
          ? offer.archived.filter((id) => id !== userId)
          : [...(offer.archived || []), userId];
  
        this.setState({
          offer: {
            ...offer,
            archived: updatedArchived,
          },
        });
  
        toast.success(
          isArchived
            ? "Offer successfully unarchived."
            : "Offer successfully archived.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        toast.error("Offer not found or user ID is invalid.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(`Failed to update archive status: ${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };  
  
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  toggleOfferTrends = () => {
    this.setState((prevState) => ({
      showOfferTrendsMobile: !prevState.showOfferTrendsMobile
    }));
  };

  handleResize = () => {
    if (window.innerWidth > 480) {
      this.setState({ showOfferTrendsMobile: true });
    }
  };

  handleSendToTitleDocs = async (e, doc) => {
    if (e.target.checked) {
      this.setState({
        sendToTitleDocs: [...this.state.sendToTitleDocs, doc],
      });
    } else {
      this.setState({
        sendToTitleDocs: this.state.sendToTitleDocs.filter(
          (item) => item.name !== doc.name
        ),
      });
    }
  };

  handleSendToTitleDocsNameChange = (e, doc) => {
    const { value } = e.target;
    const { sendToTitleUpdatedDocs } = this.state;
    const index = sendToTitleUpdatedDocs.findIndex(
      (item) => item.name === doc.name
    );
    sendToTitleUpdatedDocs[index].name = value;
    this.setState({
      sendToTitleUpdatedDocs,
    });
  };

  handleDocNameChange = (e, doc) => {
    const { value } = e.target;
    const { updatedDocs } = this.state;
    const index = updatedDocs.findIndex((item) => item.name === doc.name);
    updatedDocs[index].name = value;
    this.setState({
      updatedDocs,
    });
  };

  handleIncludeOptionsChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      includeOptions: {
        ...this.state.includeOptions,
        [name]: checked ? true : false,
      },
    });
  };

  handleSendOfferToTitle = async () => {
    const {
      sendToTitleDocs,
      offer,
      keyDates,
      seller,
      sellerAgents,
      buyers,
      emailBody,
      emailClosing,
      includeOptions,
      newTitleCompanyId,
    } = this.state;

    this.setState({ sendingOfferToTitle: true });

    const updateHistory = `${moment().format("MM/DD/YYYY")} ${
      sendToTitleDocs.length
        ? sendToTitleDocs.map((doc) => doc.name.split(".")[0] + ", ")
        : "Executed Contract"
    } sent to ${seller?.escrow_officer} at ${seller?.title_company}`;

    const db = firebase.firestore();
    const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");
    try {
      let password;

      const isUser = await db
        .collection("users")
        .where("escrowOfficerEmail", "==", seller?.escrow_officer_email)
        .get();

      if (isUser.docs.length) {
        let user = {
          id: isUser.docs[0].id,
          ...isUser.docs[0].data(),
        };
        db.collection("users")
          .doc(user.id)
          .update({
            recievedOffers: firebase.firestore.FieldValue.arrayUnion(offer),
          });
      }

      if (!isUser.docs.length) {
        password =
          "1234567890qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM"
            .split("")
            .sort(function () {
              return 0.5 - Math.random();
            })
            .join("")
            .substring(1, 8);
        const res = await secondaryApp
          .auth()
          .createUserWithEmailAndPassword(
            seller.escrow_officer_email,
            password
          );
        setTimeout(async () => {
          db.collection("users")
            .doc(res.user.uid)
            .update({
              profileComplete: true,
              verifiedUser: true,
              companyName: seller?.title_company,
              escrowOfficerEmail: seller?.escrow_officer_email,
              role: "titleCompany",
              recievedOffers: firebase.firestore.FieldValue.arrayUnion(offer),
            })
            .then(() => {
              console.log("Added");
            })
            .catch((err) => {
              console.log(err);
            });
        }, 10000);
      }
      const EmailBody = sendToTitleEmail(
        {
          ...keyDates,
          sellers_agent: includeOptions.listingAgent ? seller?.seller_name : "",
          transaction_coordinator: includeOptions.transactionCoordinator
            ? seller?.transaction_coordinator
            : "",
          transaction_coordinator_email:
            this.state.seller?.transaction_coordinator_email,
          sellers: includeOptions.sellers
            ? sellerAgents.map((agent) => agent.value)
            : [],
          buyers_agent: includeOptions.buyersAgent
            ? offer.agentName || offer.buyerName
            : "",
          buyers: buyers,
          lender: includeOptions.lender ? offer.lenderCompany : "",
          title_company: seller?.title_company,
          createdAt: offer.createdAt,
          agentName: offer.agentName || offer.buyerName,
          body: emailBody,
          closing: emailClosing,
          sellerEmail: seller.seller_email,
          phoneNumber: seller.seller_phone,
          profilePhoto: this.props.user?.profilePhoto["64"],
          brokerageName: this.props.user?.brokerage_name,
          password: isUser.docs.length ? false : password,
          email: seller?.escrow_officer_email,
        },
        seller,
        offer.id
      );
      await sendEmail({
        subject: `Offer Sent to Title Company - Homematchx`,
        email: seller?.escrow_officer_email,
        body: EmailBody,
        attachments: sendToTitleDocs,
      });
      const sendSMS = firebase.functions().httpsCallable("sendSMS");
      await sendSMS({
        to: `+1${seller.escrow_officer_phone}`,
        body: `You've got an executed contract on a property,, at ${moment().format(
          "YYYY-MM-DD hh:mmA"
        )}, ${this.props.user.full_name || this.props.user.realtor_name}, ${
          offer.address
        }
      ,
        Details: ${await shortURL(
          `https://homematchx.com/offers/${offer.id}`
        )}`,
      });
      await db
        .collection("offers")
        .doc(offer.id)
        .update({
          updateHistory: offer.updateHistory.length
            ? [...offer.updateHistory, updateHistory]
            : [updateHistory],
        });
    } catch (error) {
      console.log("error :>> ", error);
    }

    toast.success(
      `Executed Contract sent to ${seller?.escrow_officer} at ${seller?.title_company}.`,
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );
    this.setState({
      sendingOfferToTitle: false,
      sendToTitleModal: false,
      sendToTitleDocs: [],
      sendToTitleUpdatedDocs: [
        ...this.state.updatedDocs,
        ...offer.executeOfferData?.documents.map((doc, i) => ({
          name: `Execution Document ${i + 1}`,
          path: doc,
        })),
      ],
      offer: {
        ...offer,
        updateHistory: offer.updateHistory.length
          ? [...offer.updateHistory, updateHistory]
          : [updateHistory],
      },
    });
  };

  handleKeyDates = (e) => {
    const { keyDates } = this.state;
    const { name, value } = e.target;
    keyDates[name] = value;
    this.setState({ keyDates });
  };

  formatNumber(number) {
    const formattedNumber = number;
    const parts = formattedNumber.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return "$" + parts.join(".");
  }

  verifyOfferModalToggle = () => {
    this.setState({
      verifyOfferModal: !this.state.verifyOfferModal,
    });
  };

  id2List = {
    droppable: "spreadsheetOptions",
    droppable2: "spreadsheetSelected",
  };

  getList = (id) => this.state[this.id2List[id]];

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === "droppable2") {
        state = { spreadsheetSelected: items };
      }

      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        spreadsheetOptions: result.droppable.filter(
          (el) => JSON.stringify(result.droppable2).indexOf(el.key) < 0
        ),
        spreadsheetSelected: result.droppable2,
      });
    }
  };

  handleSendOffers = async () => {
    try {
      const db = firebase.firestore();
      const {
        isAllOffers,
        seller,
        selectedSellers,
        sendMessage,
        offer,
        allOffers,
        isAttachSpreadsheet,
        spreadsheetSelected,
        isSendFuture,
        futureDate,
      } = this.state;
      const {
        profilePhoto,
        realtor_phone,
        broker_phone,
        brokerage_name,
        realtor_name,
        email,
        realtor_email,
        displayName,
      } = this.props.user;

      const userDetails = {
        profilePhoto: profilePhoto["64"],
        realtor_phone: realtor_phone || null,
        broker_phone: broker_phone || null,
        brokerage_name: brokerage_name || null,
        realtor_name: displayName || realtor_name,
        email: realtor_email || email,
      };
      const offersToSend = isAllOffers ? allOffers : [offer];
      const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");
      const sendSMS = firebase.functions().httpsCallable("sendSMS");
      const fileReader = new FileReader();

      const sharedOffersRef = db.collection("sharedOffers");
      const sharedOfferDoc = await sharedOffersRef.doc(offer.id).get();

      if (sharedOfferDoc.exists) {
        await sharedOffersRef.doc(offer.id).update({
          includeOfferPackage: true,
          includeComments: true,
        });
        this.setState({
          sharedOfferId: offer.id,
        });
      } else {
        await sharedOffersRef.doc(offer.id).set({
          offerId: offer.id,
          includeOfferPackage: true,
          includeComments: true,
        });
        this.setState({
          sharedOfferId: offer.id,
        });
      }

      if (isSendFuture) {
        this.setState({ sendingOffersToSeller: true });
        let data;
        const futureDateMoment = moment(futureDate);
        const now = moment();
        if (futureDateMoment.isBefore(now)) {
          toast.error("Future date must be after current date", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
        if (isAttachSpreadsheet) {
          const filteredXlsxAttributes = spreadsheetSelected.map((e) => e);
          data = offersToSend.map((offer) => {
            const updatedOffer = {};
            filteredXlsxAttributes.forEach((e) => {
              updatedOffer[e.name] = offer[e.key] || null;
            });

            db.collection("users")
              .doc(this.props.user.uid)
              .update({
                spreadsheet_attributes: spreadsheetSelected,
              })
              .then(() => {
                console.log("Added");
              });

            return updatedOffer;
          });
        }
        await db
          .collection("users")
          .doc(this.props.user.uid)
          .update({
            scheduledEmails:
              this.props.user.scheduledEmails !== undefined
                ? [
                    ...this.props.user.scheduledEmails,
                    {
                      userId: this.props.user.uid,
                      recipients: selectedSellers,
                      sendDateTime: new Date(futureDate).toISOString(),
                      sellerId: offer.seller,
                      agents: seller.agents,
                      attachmentData: isAttachSpreadsheet ? data : null,
                      sendThisOffer: isAllOffers ? false : true,
                      offers: [offer],
                      userDetails,
                    },
                  ]
                : [
                    {
                      userId: this.props.user.uid,
                      recipients: selectedSellers,
                      sendDateTime: new Date(futureDate).toISOString(),
                      sellerId: offer.seller,
                      agents: seller.agents,
                      attachmentData: isAttachSpreadsheet ? data : null,
                      sendThisOffer: isAllOffers ? false : true,
                      offers: [offer],
                      userDetails,
                    },
                  ],
            spreadsheet_attributes: spreadsheetSelected,
          });

        for (const offerToSend of offersToSend) {
          const updateHistory = `${moment().format(
            "MM/DD/YYYY"
          )} The Offer from ${
            offer.agentName || offer.buyerName
          } has been scheduled to send to ${
            seller?.hasOwnProperty("showAgentsEmail") && seller.showAgentsEmail
              ? selectedSellers
              : "seller"
          } on ${futureDateMoment.format("MM/DD/YYYY [at] hh:mmA")}.`;
          await db
            .collection("offers")
            .doc(offerToSend.id)
            .update({
              updateHistory: offerToSend.updateHistory
                ? [...offerToSend.updateHistory, updateHistory]
                : [updateHistory],
              status:
                offerToSend.status === "In Review"
                  ? "Seller Review"
                  : offerToSend.status,
            });
          if (offerToSend.id === offer.id) {
            this.setState((prevState) => ({
              offer: {
                ...prevState.offer,
                updateHistory: [
                  ...prevState.offer.updateHistory,
                  updateHistory,
                ],
                status:
                  offer.status === "In Review" ? "Seller Review" : offer.status,
              },
            }));
          }
        }

        toast.success(
          `Selected offers will be sent to ${selectedSellers} on ${futureDateMoment.format(
            "MM/DD/YYYY"
          )}`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        this.setState({
          sendingOffersToSeller: false,
          selectedSellers: [],
          sendOfferToSellerModal: false,
          sendingOffersToSellerSMS: false,
          spreadSheetModal: false,
        });
        return;
      }

      let csvData;

      for (const selectedSeller of selectedSellers) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(selectedSeller)) {
          console.log(`Invalid email address: ${selectedSeller}`);
          continue;
        }
        this.setState({ sendingOffersToSeller: true });
        if (isAttachSpreadsheet) {
          const filteredXlsxAttributes = spreadsheetSelected.map((e) => e);
          const data = offersToSend.map((offer) => {
            const updatedOffer = {};
            filteredXlsxAttributes.forEach((e) => {
              updatedOffer[e.name] = offer[e.key] || "N/A";
            });

            return updatedOffer;
          });
          const db = firebase.firestore();
          await db.collection("users").doc(this.props.user.uid).update({
            spreadsheet_attributes: spreadsheetSelected,
          });

          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(data);

          const wscols = [];
          for (let i = 0; i < Object.keys(data[0]).length; i++) {
            if (i == 0) {
              wscols.push({ width: 30 });
            } else {
              wscols.push({ width: 10 });
            }
          }
          ws["!cols"] = wscols;

          XLSX.utils.book_append_sheet(wb, ws, "users_sheet");

          csvData = XLSX.write(wb, {
            type: "base64",
            bookType: "xlsx",
            bookSST: false,
          });
        }

        const res = await sendEmail({
          subject: `Offers Shared With You - Homematchx`,
          email: selectedSeller,
          body: shareOffersTemplate(
            offersToSend,
            seller?.agents?.find(
              (agent) => agent.agent_email === selectedSeller
            ).agent_name,
            {
              profilePhoto: profilePhoto["64"],
              realtor_phone,
              broker_phone,
              brokerage_name,
              realtor_name: displayName || realtor_name,
              email: realtor_email || email,
            }
          ),
          attachments: isAttachSpreadsheet
            ? [
                {
                  path: `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
                  ;base64,${csvData}`,
                  filename: "offers.xlsx",
                },
              ]
            : [],
        });

        for (const offerToSend of offersToSend) {
          if (sendMessage) {
            await sendSMS({
              to: `+1${offerToSend.phoneNumber}`,
              body: `OFFER Sent to ${
                seller?.hasOwnProperty("showAgentsEmail") &&
                seller.showAgentsEmail
                  ? selectedSeller
                  : "seller"
              } at ${moment().format("YYYY-MM-DD hh:mmA")},
            Details: https://homematchx.com/offers/${offerToSend.id}`,
            });
          }

          const updateHistory = `${moment().format(
            "MM/DD/YYYY"
          )} The Offer from ${
            offerToSend.agentName || offerToSend.buyerName
          } has been sent to ${
            seller?.hasOwnProperty("showAgentsEmail") && seller.showAgentsEmail
              ? selectedSeller
              : "seller"
          }.`;
          await db
            .collection("offers")
            .doc(offerToSend.id)
            .update({
              updateHistory: offerToSend.updateHistory
                ? [...offerToSend.updateHistory, updateHistory]
                : [updateHistory],
              status:
                offerToSend.status === "In Review"
                  ? "Seller Review"
                  : offerToSend.status,
            });
          if (offerToSend.id === offer.id) {
            this.setState((prevState) => ({
              offer: {
                ...prevState.offer,
                updateHistory: [
                  ...prevState.offer.updateHistory,
                  updateHistory,
                ],
                status:
                  offer.status === "In Review" ? "Seller Review" : offer.status,
              },
            }));
          }
        }
        toast.success(
          `Selected offers have been shared with ${selectedSeller} successfully`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
      this.setState({
        sendingOffersToSeller: false,
        selectedSellers: [],
        sendOfferToSellerModal: false,
        sendingOffersToSellerSMS: false,
        spreadSheetModal: false,
      });
    } catch (error) {
      console.error("Error while sending offers:", error);
    }
  };

  handleShareOffers = async () => {
    const {
      shareOfferEmail,
      shareOffersModel,
      shareOfferName,
      sendingOffers,
      offersToShare,
    } = this.state;
    const {
      profilePhoto,
      realtor_phone,
      broker_phone,
      brokerage_name,
      realtor_name,
      email,
      realtor_email,
      displayName,
    } = this.props.user;

    const filter =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (filter.test(shareOfferEmail)) {
      this.setState({
        sendingOffers: true,
      });
      const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");
      await sendEmail({
        subject: `Offers Shared With You - Homematchx`,
        email: shareOfferEmail,
        body: shareOffersTemplate(offersToShare, shareOfferName, {
          profilePhoto: profilePhoto["64"],
          realtor_phone,
          broker_phone,
          brokerage_name,
          realtor_name: displayName || realtor_name,
          email: realtor_email || email,
        }),
      });
      this.setState({
        sendingOffers: false,
      });
      toast.success("Selected offers has been shared successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        offersToShare: [],
      });
      this.setState({ shareOffersModel: false });
    } else {
      alert("Please enter correct email address.");
    }
  };

  sendSMSModalToggle = () => {
    this.setState({
      sendSMSModal: !this.state.sendSMSModal,
    });
  };

  agentModalToggle = () => {
    this.setState({
      agentModal: !this.state.agentModal,
    });
  };
  updateOfferStatus = async (
    offerStatus,
    actionTaken,
    declineOthers = false,
    addAcceptComment = false,
    comment
  ) => {
    const seller = this.state.seller;
    const { user } = this.props;
    let { offer } = this.state;
    offer = offer ? offer : this.props.location.state?.offer;
    const id = offer.id;

    try {
      const db = firebase.firestore();
      const updateHistory = `${moment().format(
        "MM/DD/YYYY"
      )} The agent has ${actionTaken} an offer.`;

      if (addAcceptComment) {
        await db
          .collection("offers")
          .doc(id)
          .update({
            status: offerStatus,
            statusChangedAt: moment().utc().unix(),
            updateHistory:
              firebase.firestore.FieldValue.arrayUnion(updateHistory),
            acceptComment: comment,
          });
      } else {
        await db
          .collection("offers")
          .doc(id)
          .update({
            status: offerStatus,
            statusChangedAt: moment().utc().unix(),
            updateHistory:
              firebase.firestore.FieldValue.arrayUnion(updateHistory),
          });
      }

      this.setState({
        offer: {
          ...offer,
          status: offerStatus,
          acceptComment: comment,
        },
      });

      if (declineOthers) {
        await db
          .collection("offers")
          .where(firebase.firestore.FieldPath.documentId(), "!=", id)
          .where("seller_id", "==", seller.seller_id)
          .get()
          .then((response) => {
            let batch = firebase.firestore().batch();
            response.docs.forEach((doc) => {
              const docRef = firebase
                .firestore()
                .collection("offers")
                .doc(doc.id);
              batch.update(docRef, {
                status:
                  offerStatus === "Accepted" ? "Not Accepted" : "In Review",
              });
            });
            batch.commit();
          });
      }

      const statusWords = {
        "Un-Accept": "un accepted",
        "Un-Decline": "un declined",
        Accept: "accepted",
        Decline: "declined",
      };

      const sendSMS = firebase.functions().httpsCallable("sendSMS");

      if (offerStatus !== "Withdraw") {
        await db.collection("notifications").add({
          user_id: offer.buyer,
          property_id: offer.seller,
          text: `Your offer has been ${
            statusWords[actionTaken] ? statusWords[actionTaken] : actionTaken
          } by the seller.`,
          type: `offer`,
          isRead: 0,
          createdAt: moment().utc().unix(),
        });
        if (this.state.sendMessage) {
          await sendSMS({
            to: `+1${offer.phoneNumber}`,
            body: `OFFER ${offerStatus} at ${moment().format(
              "YYYY-MM-DD hh:mmA"
            )}, ${offer.address}.
${this.props.user.full_name || this.props.user.realtor_name},
Finance: ${offer.financingType}
Price: ${offer.purchasePrice}
Closing: ${offer.closingDate}
Details: https://homematchx.com/offers/${offer.id}`,
          });
        }
      } else {
        await db.collection("notifications").add({
          user_id: offer.seller_id,
          property_id: offer.seller,
          text: `An offer has been withdrawn from the property.`,
          type: `offer`,
          isRead: 0,
          createdAt: moment().utc().unix(),
        });

        if (this.state.sendMessage) {
          await sendSMS({
            to: `+1${seller.seller_phone}`,
            body: `OFFER WITHDRAWN at ${moment().format(
              "YYYY-MM-DD hh:mmA"
            )}, ${offer.address}.
${offer.buyerName},
Finance: ${offer.financingType}
Price: ${offer.purchasePrice}
Closing: ${offer.closingDate}
Details: https://homematchx.com/offers/${offer.id}`,
          });
        }
      }
    } catch (error) {
      this.setState({
        loading: false,
        error: error.message || "Something went wrong, please try again !",
      });
    }
    saveActivity({
      text: `Offer status has been changed to ${offerStatus}.`,
      userName: user.full_name || user.realtor_name,
      userId: user.uid,
      type: "offers",
      linkId: id,
      offerAddress: offer.address,
    });
  };

  renderOfferDetails = () => {
    const { loading, error, offer } = this.state;
    const { user } = this.props;
    let errorCase = "Loading...";
    errorCase = error || errorCase;

    if (loading || error) {
      return (
        <table className="manage-table">
          <tbody>
            <tr className="responsive-table">
              <td>
                <p>{errorCase}</p>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }

    const { value } = offer.offerType;

    if (value === "InstantOffer") {
      return <InstantOfferDetails offer={offer} user={user} {...this.props} />;
    }

    return <OfferDetails offer={offer} user={user} {...this.props} />;
  };

  renderStatus = (status, isBuyer, acceptComment) => {
    let statusClass = "";
    let prependIcon = "";
    if (status === "In Review") {
      statusClass = "in-review";
      prependIcon = Replay;
    } else if (status === "Accepted") {
      statusClass = "accepted";
      prependIcon = Tick;
    } else if (status === "Not Accepted") {
      statusClass = "declined";
      prependIcon = Cross;
    } else if (status === "Deleted") {
      statusClass = "declined";
      prependIcon = Cross;
    } else if (status === "Executed") {
      statusClass = "executed";
      prependIcon = Tick;
    } else if (status === "Withdraw" || status === "Terminated") {
      statusClass = "withdrawn";
      prependIcon = Tick;
    } else if (status === "Seller Review") {
      statusClass = "seller-review";
      prependIcon = ReplayWhite;
    } else if (status === "Countered") {
      statusClass = "accepted";
      prependIcon = ReplayWhite;
    }

    if (status == "Executed") {
      return (
        <>
          <li className={`accepted align-items-center`}>
            <button
              onClick={() => {
                if (acceptComment) this.setState({ acceptCommentModal: true });
              }}
              style={{
                paddingTop: "4px",
                paddingBottom: "22px",
              }}
            >
              <img src={prependIcon} alt="prepend icon" />
              Accepted
            </button>
            <button
              className="ml-2"
              style={{
                paddingTop: "4px",
                paddingBottom: "22px",
              }}
            >
              <img src={prependIcon} alt="prepend icon" />
              Executed
            </button>
            <button
              className="ml-2"
              style={{
                backgroundColor: "#9C9D9E",
                color: "white",
                paddingTop: "4px",
                paddingBottom: "22px",
              }}
            >
              {isBuyer ? "Sent" : "Received"}
            </button>
          </li>
        </>
      );
    }

    return (
      <li className={`${statusClass} align-items-center`}>
        <button
          onClick={() => {
            if (status === "Accepted" && acceptComment)
              this.setState({ acceptCommentModal: true });
          }}
          style={{
            paddingTop: "4px",
            paddingBottom: "22px",
          }}
        >
          <img src={prependIcon} alt="prepend icon" />
          {status === "Withdraw" ? "Withdrawn" : status}
        </button>
        <button
          className="ml-2"
          style={{
            backgroundColor: "#9C9D9E",
            color: "white",
            paddingTop: "4px",
            paddingBottom: "22px",
          }}
        >
          {isBuyer ? "Sent" : "Received"}
        </button>
      </li>
    );
  };

  uploadDocument = async ({ target }) => {
    const files = target.files;
    if (!files.length) return;
    let { offer, isBuyer, seller } = this.state;
    offer = offer ? offer : this.props.location.state?.offer;
    this.setState({ isUploading: true });
    const storage = firebase.storage();
    const offersRef = storage.ref().child(`images/offers`);
    let documents = this.state.offer.documents || [];
    const { user } = this.props;
    for (const file of files) {
      const offerImageRef = offersRef.child(
        `${(file, moment().utc().unix())}_${file.name}`
      );
      let document = await offerImageRef.put(file);

      const filePath = await firebase
        .storage()
        .ref()
        .child(document.ref.location.path)
        .getDownloadURL();
      documents.push({
        path: filePath,
        name: file.name,
        owner: user.uid,
        timestamp: Date.now(),
      });
    }

    saveActivity({
      text: `A Document has been uploaded.`,
      userName: user.full_name || user.realtor_name,
      userId: user.uid,
      type: "offers",
      linkId: offer.id,
      offerAddress: offer.address,
    });

    try {
      const sendSMS = firebase.functions().httpsCallable("sendSMS");
      await sendSMS({
        to: `+1${isBuyer ? seller.seller_phone : offer.phoneNumber}`,
        body: `A document has been added to an offer ${
          offer.address
        }, at ${moment().format("YYYY-MM-DD hh:mmA")}.
Details: https://homematchx.com/offers/${offer.id}`,
      });
    } catch (err) {}

    try {
      const db = firebase.firestore();

      await db.collection("notifications").add({
        user_id: offer.buyer,
        property_id: offer.id,
        text: `Document has been added.`,
        type: `offer`,
        isRead: 0,
        createdAt: moment().utc().unix(),
      });

      const updateHistory = `${moment().format(
        "MM/DD/YYYY"
      )} The agent has added a document`;
      await db
        .collection("offers")
        .doc(offer.id)
        .update({
          updateHistory:
            firebase.firestore.FieldValue.arrayUnion(updateHistory),
          documents,
        });
      this.setState({
        isUploading: false,
        offer: {
          ...offer,
          documents,
          updateHistory: [...offer.updateHistory, updateHistory],
        },
        updatedDocs: documents,
      });
    } catch (error) {
      this.setState({
        loading: false,
        error: error.message || "Something went wrong, please try again !",
      });
      this.setState({ isUploading: false });
    }
  };

  deleteDocument = (url) => {
    let { offer } = this.state;
    offer = offer ? offer : this.props.location.state?.offer;

    const documents = this.state.inputs.documents.filter(
      (doc) => doc.url !== url
    );
    this.setState({
      inputs: {
        ...this.state.inputs,
        documents,
      },
    });
    const { user } = this.props;
    saveActivity({
      text: `A Document has been deleted.`,
      userName: user.full_name || user.realtor_name,
      userId: user.uid,
      type: "offers",
      linkId: offer.id,
      offerAddress: offer.address,
    });
  };

  archiveDoc = async (doc) => {
    const { offer } = this.state || this.props.location.state?.offer;

    const db = firebase.firestore();
    db.collection("users")
      .doc(this.props.user.uid)
      .update({
        archivedDocuments: firebase.firestore.FieldValue.arrayUnion({
          ...doc,
          offerId: offer.id,
        }),
      });

    this.setState({
      archivedDocs: [
        ...this.state.archivedDocs,
        {
          ...doc,
          offerId: offer.id,
        },
      ],
    });
  };

  unArchiveDoc = async (doc) => {
    const { offer } = this.state || this.props.location.state?.offer;

    const db = firebase.firestore();
    db.collection("users")
      .doc(this.props.user.uid)
      .update({
        archivedDocuments: firebase.firestore.FieldValue.arrayRemove({
          ...doc,
          offerId: offer.id,
        }),
      });

    this.setState({
      archivedDocs: this.state.archivedDocs.filter((d) => d.url !== doc.url),
    });
  };

  toggleModal = () => {
    this.setState({
      counterOfferModal: !this.state.counterOfferModal,
    });
  };

  toggleExecutionModal = () => {
    this.setState({
      executionModal: !this.state.executionModal,
    });
  };

  updateCounterOffer = (key, value) => {
    const updatedOfferData = {
      ...this.state.counterOfferData,
    };
    updatedOfferData[key] = value;
    this.setState({
      counterOfferData: updatedOfferData,
    });
  };

  acceptCounterOffer = async () => {
    const { isBuyer, seller } = this.state;
    let { offer } = this.state;
    offer = offer ? offer : this.props.location.state?.offer;
    const { counterOffers } = offer ? offer : this.props.location.state?.offer;
    const acceptedOffer = counterOffers?.slice(-1)[0];
    const { user } = this.props;
    if (!acceptedOffer) return;

    const db = firebase.firestore();
    const updateHistory = `${moment().format("MM/DD/YYYY")} The ${
      isBuyer ? "buyer" : "seller"
    } agent has accepted counter offer.`;
    await db
      .collection("offers")
      .doc(this.state.offer.id)
      .update({
        updateHistory: firebase.firestore.FieldValue.arrayUnion(updateHistory),
        ...acceptedOffer.offerData,
        isOfferAccepted: true,
      });

    toast.success(`Counter offer has been accepted successfully.`, {
      position: toast.POSITION.TOP_RIGHT,
    });

    await db.collection("notifications").add({
      user_id: offer.buyer,
      property_id: offer.seller,
      text: `${isBuyer ? "Buyer" : "Seller"} agent has accepted the offer.`,
      type: `offer`,
      isRead: 0,
      createdAt: moment().utc().unix(),
    });

    try {
      const sendSMS = firebase.functions().httpsCallable("sendSMS");
      await sendSMS({
        to: `+1${isBuyer ? seller.seller_phone : offer.phoneNumber}`,
        body: `Counter OFFER ACCEPTED at ${moment().format(
          "YYYY-MM-DD hh:mmA"
        )}, ${offer.address}.
${offer.buyerName},
Finance: ${acceptedOffer.financingType}
Down Payment: ${acceptedOffer.downPayment}
Closing: ${acceptedOffer.closingDate}
Details: https://homematchx.com/offers/${offer.id}`,
      });
    } catch (err) {}

    saveActivity({
      text: `Agent accepted the counter offer.`,
      userName: user.full_name || user.realtor_name,
      userId: user.uid,
      type: "offers",
      linkId: offer.id,
      offerAddress: offer.address,
    });
  };

  deleteOffer = async () => {
    this.setState({
      deletingOffer: true,
    });
    const { user, history } = this.props;
    let { offer } = this.state;
    offer = offer ? offer : this.props.location.state?.offer;

    const db = firebase.firestore();
    await db.collection("offers").doc(offer.id).update({
      status: "Deleted",
    });

    this.setState({
      offer: {
        ...offer,
        status: "Deleted",
      },
    });

    toast.success(`Offer has been deleted successfully.`, {
      position: toast.POSITION.TOP_RIGHT,
    });

    saveActivity({
      text: `Agent has deleted this offer.`,
      userName: user.full_name || user.realtor_name,
      userId: user.uid,
      type: "offers",
      linkId: offer.id,
      offerAddress: offer.address,
    });

    history.push(`/offers`);
  };

  acceptMyCounterOffer = () => {
    this.acceptCounterOffer();
    this.toggleModal();
  };

  terminateOffer = async () => {
    let { offer } = this.state;
    offer = offer ? offer : this.props.location.state?.offer;
    const { user } = this.props;

    const db = firebase.firestore();
    const updateHistory = `${moment().format(
      "MM/DD/YYYY"
    )} The seller agent has terminated the offer.`;
    await db
      .collection("offers")
      .doc(offer.id)
      .update({
        updateHistory: firebase.firestore.FieldValue.arrayUnion(updateHistory),
        status: "Terminated",
        isOfferAccepted: false,
      });

    this.setState({
      offer: {
        ...offer,
        status: "Terminated",
      },
    });

    toast.success(`Offer has been terminated successfully.`, {
      position: toast.POSITION.TOP_RIGHT,
    });

    await db.collection("notifications").add({
      user_id: offer.buyer,
      property_id: offer.id,
      text: `Seller agent has terminated the offer.`,
      type: `offer`,
      isRead: 0,
      createdAt: moment().utc().unix(),
    });

    try {
      const sendSMS = firebase.functions().httpsCallable("sendSMS");
      if (this.state.sendMessage) {
        await sendSMS({
          to: `+1${offer.phoneNumber}`,
          body: `OFFER TERMINATED at ${moment().format("YYYY-MM-DD hh:mmA")}, ${
            offer.address
          }.
${offer.buyerName},
Finance: ${offer.financingType}
Down Payment: ${offer.downPayment}
Closing: ${offer.closingDate}
Details: https://homematchx.com/offers/${offer.id}`,
        });
      }
      saveActivity({
        text: `Agent has terminated the offer.`,
        userName: user.full_name || user.realtor_name,
        userId: user.uid,
        type: "offers",
        linkId: offer.id,
        offerAddress: offer.address,
      });
    } catch (err) {}
  };

  saveCounterOffer = async () => {
    let { offer, counterOfferData, seller } = this.state;
    offer = offer ? offer : this.props.location.state?.offer;
    const { user } = this.props;

    const isSeller = offer.seller_id === user.uid;

    const db = firebase.firestore();
    const updateHistory = `${moment().format("MM/DD/YYYY")} The ${
      isSeller ? "Seller" : "Buyer"
    } agent has sent a counter offer.`;
    await db
      .collection("offers")
      .doc(offer.id)
      .update({
        status: "Countered",
        updateHistory: offer?.updateHistory?.length
          ? [...offer.updateHistory, updateHistory]
          : [updateHistory],
        counterOffers: firebase.firestore.FieldValue.arrayUnion({
          offerData: {
            ...this.state.counterOfferData,
            purchasePrice:
              parseInt(this.state.counterOfferData.purchasePrice) || undefined,
          },
          sentAt: moment().utc().unix(),
          sentBy: this.props.user.full_name || this.props.user.realtor_name,
          sentById: this.props.user.uid,
        }),
      });

    this.setState({
      offer: {
        status: "Countered",
        updateHistory: offer?.updateHistory?.length
          ? [...offer.updateHistory, updateHistory]
          : [updateHistory],
        counterOffers: [
          ...offer.counterOffers,
          {
            offerData: {
              ...this.state.counterOfferData,
              purchasePrice:
                parseInt(this.state.counterOfferData.purchasePrice) ||
                undefined,
            },
            sentAt: moment().utc().unix(),
            sentBy: this.props.user.full_name || this.props.user.realtor_name,
            sentById: this.props.user.uid,
          },
        ],
      },
    });

    toast.success(
      `Counter offer has been sent to ${
        isSeller ? "buyer" : "seller"
      } agent successfully.`,
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );

    await db.collection("notifications").add({
      user_id: offer.buyer,
      property_id: offer.seller,
      text: `${
        isSeller ? "Seller" : "Buyer"
      } agent has sent you a counter offer.`,
      type: `offer`,
      isRead: 0,
      createdAt: moment().utc().unix(),
    });
    try {
      const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");

      const offerDetailsBody = offerDetails(
        {
          // ...offer,
          ...counterOfferData,
          agentName: offer.agentName || offer.buyerName,
          createdAt: moment().format("MM/DD/YYYY"),
          comments: counterOfferData.comments,
          featured_image: offer.featured_image,
        },
        {
          ...seller,
          userName: isSeller
            ? offer.agentName || offer.buyerName
            : seller.seller_name,
        },
        offer.id
      );

      await sendEmail({
        subject: "Counter Offer",
        email: isSeller
          ? offer?.buyerEmail || offer?.email
          : seller.seller_email,
        body: offerDetailsBody,
      });
    } catch (error) {
      console.log(error);
    }
    try {
      const sendSMS = firebase.functions().httpsCallable("sendSMS");
      await sendSMS({
        to: `+1${offer.phoneNumber}`,
        body: `Counter OFFER at ${moment().format("YYYY-MM-DD hh:mmA")}, ${
          offer.address
        }.
    ${offer.buyerName},
    Finance: ${this.state.counterOfferData.financingType}
    Down Payment: ${this.state.counterOfferData.downPayment}
    Closing: ${this.state.counterOfferData.closingDate}
    Details: https://homematchx.com/offers/${offer.id}`,
      });
      saveActivity({
        text: `Agent sent a counter offer.`,
        userName: user.full_name || user.realtor_name,
        userId: user.uid,
        type: "offers",
        linkId: offer.id,
        offerAddress: offer.address,
      });
    } catch (err) {}
  };

  updateExecutionOffer = (key, value) => {
    const executeOfferData = {
      ...this.state.executeOfferData,
    };
    executeOfferData[key] = value;
    this.setState({
      executeOfferData,
    });
  };

  saveExecuteOffer = async () => {
    let { offer, verifyOffer } = this.state;
    offer = offer ? offer : this.props.location.state?.offer;
    const { user } = this.props;

    if (verifyOffer) {
      this.handleVeifyOffer();
    }

    const db = firebase.firestore();
    const updateHistory = `${moment().format(
      "MM/DD/YYYY"
    )} The seller agent has executed this offer.`;

    await db
      .collection("offers")
      .doc(this.state.offer.id)
      .update({
        updateHistory: firebase.firestore.FieldValue.arrayUnion(updateHistory),
        status: "Executed",

        executeOfferData: {
          offerData: this.state.executeOfferData,
          documents: firebase.firestore.FieldValue.arrayUnion(
            this.state.executeOfferData.executionDocument
          ),
          executedAt: moment().utc().unix(),
          executedBy: this.props.user.full_name || this.props.user.realtor_name,
          executedById: this.props.user.uid,
        },
      });

    this.setState({
      offer: {
        ...offer,
        status: "Executed",
        updateHistory: offer?.updateHistory?.length
          ? [...offer.updateHistory, updateHistory]
          : [updateHistory],
        executeOfferData: {
          offerData: this.state.executeOfferData,
          documents: offer.executeOfferData?.documents
            ? [
                ...offer.executeOfferData?.documents,
                this.state.executeOfferData?.executionDocument,
              ]
            : [this.state.executeOfferData?.executionDocument],
          executedAt: moment().utc().unix(),
          executedBy: this.props.user.full_name || this.props.user.realtor_name,
          executedById: this.props.user.uid,
        },
      },
    });

    toast.success(`Offer has been executed successfully.`, {
      position: toast.POSITION.TOP_RIGHT,
    });

    await db.collection("notifications").add({
      user_id: this.state.offer.buyer,
      property_id: this.state.offer.seller,
      text: `Seller agent has executed your offer.`,
      type: `offer`,
      isRead: 0,
      createdAt: moment().utc().unix(),
    });

    const sendSMS = firebase.functions().httpsCallable("sendSMS");
    if (this.state.sendMessage) {
      await sendSMS({
        to: `+1${this.state.offer.phoneNumber}`,
        body: `OFFER EXECUTED at ${moment().format("YYYY-MM-DD hh:mmA")}, ${
          this.state.offer.address
        }.
${this.state.offer.buyerName},
Finance: ${this.state.executeOfferData.financingType}
Down Payment: ${this.state.executeOfferData.downPayment}
Closing: ${this.state.executeOfferData.closingDate}
Details: https://homematchx.com/offers/${this.state.offer.id}`,
      });
    }

    saveActivity({
      text: `Agent has executed offer.`,
      userName: user.full_name || user.realtor_name,
      userId: user.uid,
      type: "offers",
      linkId: this.state.offer.id,
      offerAddress: this.state.offer.address,
    });
  };

  onDrop = async (files, rejectedFiles) => {
    if (rejectedFiles.length) {
      alert(`Please select file less than 20mb.`);
      return;
    }

    this.setState({
      uploadingDocument: true,
    });
    const storage = firebase.storage();
    const offersRef = storage.ref().child(`images/offers`);
    const file = files[0];
    const userImageRef = offersRef.child(
      `${(file, moment().utc().unix())}_${file.name}`
    );
    let document = await userImageRef.put(file);

    const filePath = await firebase
      .storage()
      .ref()
      .child(document.ref.location.path)
      .getDownloadURL();
    this.setState({
      uploadingDocument: false,
      executeOfferData: {
        ...this.state.executeOfferData,
        executionDocument: filePath,
      },
    });
  };

  handleVeifyOffer = async () => {
    try {
      this.setState({
        verifiedLoading: true,
      });
      const verified = !!!this.state.offer.verified;
      const { user } = this.props;
      const db = firebase.firestore();
      const updateHistory = `${moment().format(
        "MM/DD/YYYY"
      )} Offer has been marked as ${verified ? "" : "not "}verified.`;
      await db
        .collection("offers")
        .doc(this.state.offer.id)
        .update({
          updateHistory:
            firebase.firestore.FieldValue.arrayUnion(updateHistory),
          verified,
        });

      this.setState({
        verifiedLoading: false,
        offer: {
          ...this.state.offer,
          verified,
        },
      });

      saveActivity({
        text: `Agent marked offer as ${verified ? "" : "not "}verified.`,
        userName: user.full_name || user.realtor_name,
        userId: user.uid,
        type: "offers",
        linkId: this.state.offer.id,
        offerAddress: this.state.offer.address,
      });
      toast.success(
        `Offer has been marked as ${
          verified ? "" : "not "
        }verified successfully.`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } catch (err) {
      toast.error(err.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

      this.setState({
        verifiedLoading: false,
      });
    }
  };

  downloadDocs = () => {
    const { offer } = this.state;
    const { executeOfferData, documents } = offer
      ? offer
      : this.props.location.state?.offer;
    if (
      executeOfferData &&
      executeOfferData.documents &&
      executeOfferData.documents.length
    )
      generateZipFromCloud(executeOfferData.documents, "execute");
    if (documents && documents.length)
      generateZipFromCloud(
        documents.map((doc) => doc.path),
        "docs"
      );
  };

  documentsCode = async () => {
    const { offer } = this.state;
    const { documents } = offer ? offer : this.props.location.state?.offer;
    if (!documents) return null;

    const docs = [];
    for (const doc of documents) {
      let dURL = doc.path;
      if (!doc.path.includes("token"))
        dURL = await storage.refFromURL(doc.path).getDownloadURL();

      docs.push({
        ...doc,
        path: dURL,
      });
    }

    const sendToTitleUpdatedDocs = [...docs];
    for (
      let i = 0;
      i < offer.executeOfferData?.documents.length || [].length;
      i++
    ) {
      let name = `Execution Document ${i + 1}`;
      let path = offer.executeOfferData?.documents[i];

      sendToTitleUpdatedDocs.push({
        name,
        path,
      });
    }

    this.setState({
      updatedDocs: docs,
      sendToTitleUpdatedDocs,
      // sendToTitleUpdatedDocs: [
      //   ...docs,
      //   ...offer.executeOfferData?.documents.map((doc, i) => ({
      //     name: `Execution Document ${i + 1}`,
      //     path: doc,
      //   })),
      // ],
    });
  };

  calculatePrice(offersDocs) {
    let totalPurchasePrice = 0;
    offersDocs.map((doc, i) => {
      totalPurchasePrice += parseInt(doc.purchasePrice);
    });
    return totalPurchasePrice / offersDocs.length;
  }

  findFinancingType(offersDocs) {
    let financeType = [];
    offersDocs.map(({ financingType }, i) => {
      if (financingType !== financeType.find((elm) => elm === financingType))
        financeType.push(financingType);
    });
    return financeType.join(", ");
  }

  optionDays(offersDocs) {
    const numbers = [];
    offersDocs.forEach((num) => {
      if (num.optionDays) numbers.push(parseInt(num.optionDays));
    });
    return `${Math.min(...numbers)} - ${Math.max(...numbers)}`;
  }

  calculateNumberOfDays(offersDocs) {
    let totalNumberOfDays = 0;
    let newArr = offersDocs.map((doc) => Number(doc.numberOfDays));
    newArr.forEach((num) => {
      if (num) totalNumberOfDays += num;
    });
    return totalNumberOfDays / offersDocs.length;
  }

  calculateViewTime(offersDocs) {
    let totalViewingTime = 0;

    offersDocs.forEach((doc) => {
      const createdAt = moment(doc.createdAt * 1000);
      const viewedAt = moment(doc.viewedAt * 1000);

      if (createdAt.isValid() && viewedAt.isValid()) {
        const viewingTimeInSeconds = viewedAt.diff(createdAt, "seconds");
        totalViewingTime += viewingTimeInSeconds;
      }
    });

    if (offersDocs.length === 0) {
      return 0;
    }

    let avgTimeInSecs = totalViewingTime / offersDocs.length;
    if (avgTimeInSecs < 60) {
      return `${Math.round(avgTimeInSecs)} sec`;
    } else if (avgTimeInSecs < 3600) {
      return `${Math.round(avgTimeInSecs / 60)} min`;
    } else {
      return `${Math.round(avgTimeInSecs / 3600)} hr`;
    }
  }

  getAllPossession(offersDocs) {
    let totalTotalPossession = [];
    offersDocs.map(({ possession }, i) => {
      if (possession !== totalTotalPossession.find((elm) => elm === possession))
        totalTotalPossession.push(possession);
    });
    return totalTotalPossession.join(", ");
  }

  optionFee(offersDocs) {
    let totalOptionFee = 0;
    let newArr = offersDocs.map((doc) => Number(doc.optionFee));
    newArr.forEach((num) => {
      if (num) totalOptionFee += num;
    });
    return totalOptionFee / offersDocs.length;
  }
  calculateEarnestMoney(offersDocs) {
    let totalEarnestMoney = 0;
    let newArr = offersDocs.map((doc) => Number(doc.earnestMoney));
    newArr.forEach((num) => {
      if (num) totalEarnestMoney += num;
    });
    return totalEarnestMoney / offersDocs.length;
  }

  calculateDownPayment = (offersDocs) => {
    let totalDownPayment = 0;
    let newArr = offersDocs.map((doc) => Number(doc.downPayment));
    newArr.forEach((num) => {
      if (num) totalDownPayment += num;
    });
    return totalDownPayment / offersDocs.length;
  };

  getAllRightToTerminate(offersDocs) {
    let totalRightToTerminate = [];
    offersDocs.map(({ rightToTerminate }, i) => {
      if (
        rightToTerminate !==
        totalRightToTerminate.find((elm) => elm === rightToTerminate)
      )
        totalRightToTerminate.push(rightToTerminate);
    });
    return totalRightToTerminate.join(", ");
  }
  getAllSaleOfProperty(offersDocs) {
    let saleOfProperty = [];
    offersDocs.map(({ saleOfOtherProperty }, i) => {
      if (
        saleOfOtherProperty !==
        saleOfProperty.find((elm) => elm === saleOfOtherProperty)
      )
        saleOfProperty.push(saleOfOtherProperty);
    });
    return saleOfProperty.join(", ");
  }

  getAllExpenseType(offersDocs) {
    let totalExpenseType = [];
    offersDocs.map(({ titlePolicyExpense }, i) => {
      if (
        titlePolicyExpense !==
        totalExpenseType.find((elm) => elm === titlePolicyExpense)
      )
        totalExpenseType.push(titlePolicyExpense);
    });
    return totalExpenseType.join(", ");
  }

  sendSMSToAll = async () => {
    const { allOffers, smsTxt, selectedGroup, deadline, smsBlastSelectedAgents } = this.state;
    const { user } = this.props;
    if (!smsBlastSelectedAgents || smsBlastSelectedAgents.length === 0) {
      console.log("No agents selected for SMS blast.");
      return;
    }
  
    const selectedOffers = allOffers.filter((offer) =>
      smsBlastSelectedAgents.includes(offer.id)
    );
    // const numbers = selectedOffers.map((offer) => ({
    //   phoneNumber: offer.phoneNumber?.match(/\d/g)?.join(""),
    //   agentName: offer.agentName || offer.buyerName || "Unknown Agent",
    // }));

    const seller = this.state.offer?.seller || this.props.location.state?.offer?.seller;

    let numbers = selectedOffers.map((o) => {
      return {
        phoneNumber: o.phoneNumber.match(/\d/g)?.join(""),
        agentName: o.agentName || o.buyerName || "Unknown Agent",
      };
    });

    numbers = [...new Set(numbers)];

    // if (selectedGroup) {
    //   numbers = [
    //     ...numbers,
    //     selectedGroup?.followers?.map((f) =>
    //       f.phoneNumberphoneNumber.match(/\d/g)?.join("")
    //     ),
    //   ];
    // }
    const sendSMS = firebase.functions().httpsCallable("sendSMS");
    try{
      numbers.forEach(async (num) => {
        await sendSMS({
          to: `+1${num.phoneNumber.replace("+1", "")}`,
          body: smsTxt
            .replace("%agentName%", num.buyerName || num.agentName || "")
            .replace(
              "%deadline%",
              moment(deadline).format("dddd, MMMM D, YYYY h:mm A")
            ),
        });
    });
    } catch(err) {
      alert(`err:${err?.message}`);
    }
    this.setState({
      smsBlast: false,
    });
  };

  handleGroupChange = (e) => {
    const { groups } = this.state;
    this.setState({
      selectedGroup: groups.find((g) => g.id === e.target.value),
    });
  };

  getUserSpreadSheetAttributes = async () => {
    const db = firebase.firestore();
    db.collection("users")
      .doc(this.props.user?.uid)
      .get()
      .then((snapshot) => {
        const selectedOptions = snapshot.data()?.spreadsheet_attributes
          ? snapshot.data()?.spreadsheet_attributes
          : [];

        this.setState({
          spreadsheetSelected: selectedOptions,
          spreadsheetOptions: this.state.spreadsheetOptions.filter(
            (el) => JSON.stringify(selectedOptions).indexOf(el.key) < 0
          ),
        });
      });
  };

  handleTemplateChange = (e) => {
    const { smsTemplates } = this.state;
    const selectedTemplate = smsTemplates.find(
      (template) => template.value === e.value
    );
    this.setState({
      smsTxt: selectedTemplate?.label,
    });
  };

  handleAgentSelection = (e, agentId) => {
    const { smsBlastSelectedAgents } = this.state;
    if (e.target.checked) {
      this.setState({ smsBlastSelectedAgents: [...smsBlastSelectedAgents, agentId] });
    } else {
      this.setState({
        smsBlastSelectedAgents: smsBlastSelectedAgents.filter((id) => id !== agentId),
      });
    }
  };
  
  toggleSelectAll = (e) => {
    if (e.target.checked) {
      this.setState({ smsBlastSelectedAgents: this.state.allOffers?.map((offer) => offer.id) });
    } else {
      this.setState({ smsBlastSelectedAgents: [] });
    }
  };

  render() {
    const params = new URLSearchParams(this.props.location.search);
    const prevURL = params.get("prevUrl");
    const isMobile = window.innerWidth <= 480;
    const {
      user: { displayName },
      user,
      history,
    } = this.props;
    const {
      offer,
      isOtherOfferAccepted,
      isUploading,
      isBuyer,
      counterOfferModal,
      executionModal,
      deletingOffer,
      offerActions,
      realtor,
      verifiedLoading,
      updatedDocs,
      allOffers,
      sendToTitleUpdatedDocs,
      isEditDocName,
      offersToShare,
      shareOfferEmail,
      shareOfferName,
      shareOffersModel,
      sendingOffers,
      groups,
      selectedGroup,
      greenColor,
      sellerAgents,
      keyDates,
      showSellerCompanyAlert,
      counterOfferData,
      sendToTitleIncludeOptions,
      includeOptions,
      options,
      smsTemplates,
      sendToTitleDocs,
      showArchived,
      archivedDocs,
      showOfferTrendsMobile
    } = this.state;

    let numbers = allOffers.map((offer) =>
      offer.phoneNumber?.match(/\d/g)?.join("")
    );

    const smsBlastAudience = [...new Set(numbers)].length;

    let {
      verified,
      address,
      agentName,
      brokerageName,
      createdAt,
      viewedAt,
      viewedBy,
      purchasePrice,
      downPayment,
      financeAmount,
      financingType,
      sellerConcessions,
      earnestMoney,
      closingDate,
      leasebackDays,
      possession,
      optionFee,
      titlePolicyExpense,
      optionDays,
      nonRealtyItem,
      saleOfOtherProperty,
      rightToTerminate,
      documents,
      counterOffers,
      buyer,
      executeOfferData,
      isOfferAccepted,
      residentialServiceContract,
      comments,
      offer_video,
      offer_audio,
      offer_expire_date,
      globalOfferDataAccess,
      buyerEmail,
      sellerEmail,
      seller_id,
      acceptComment,
      offerType,
      cashType
    } = offer ? offer : this.props.location.state?.offer;
    const notTitleCompany = this.props.user?.role !== "titleCompany";
    let latestCounterOffer;
    if (executeOfferData?.offerData) {
      latestCounterOffer = executeOfferData?.offerData;
    } else if (counterOffers?.length) {
      latestCounterOffer = counterOffers?.sort((a, b) => {
        return b.sentAt - a.sentAt;
      })[0].offerData;
    }

    const updateHistory = offer?.updateHistory?.length
      ? offer?.updateHistory
      : this.props.location.state?.offer?.updateHistory || [];
    const curItem = this.state.curItem || this.props.location.state?.curItem;
    const totalItems =
      this.state.totalItems || this.props.location.state?.totalItems;
    const seller =
      this.state.offer?.seller || this.props.location.state?.offer?.seller;
    const offerId =
      this.state.offer?.id || this.props.location.state?.offer?.id;
    const status =
      offer?.status || this.props.location.state?.offer?.status || "In Review";
    // const seller_id = this.props.location?.state?.offer?.seller_id
    let acceptStatus = "Accept Offer";
    let declineStatus = "Decline Offer";
    const numToFixed = (num) => Number(num).toFixed(2);
    const avgPurchasePrice = this.state.totalPurchasePrice
      ? numToFixed(
          (1 - offer.actual_price / this.state.totalPurchasePrice) * 100
        )
      : 0;
    if (status === "Accepted") acceptStatus = "Un-Accept Offer";
    if (status === "Not Accepted") declineStatus = "Un-Decline Offer";
    if (
      sellerEmail &&
      buyerEmail &&
      seller_id &&
      sellerEmail !== user?.email &&
      buyerEmail !== user?.email &&
      seller_id !== user?.uid &&
      notTitleCompany
    )
      return this.props.history.push("/dashboard");

    let counterData = [
      {
        name: "Purchase Price",
        key: "purchasePrice",
        values: [purchasePrice],
      },
      {
        name: "Finance Type",
        key: "financingType",
        values: [financingType],
      },
      {
        name: "Down Payment",
        key: "downPayment",
        values: [downPayment],
      },
      {
        name: "Finance Amount",
        key: "financeAmount",
        values: [financeAmount],
      },
      {
        name: "Seller Concessions",
        key: "sellerConcessions",
        values: [sellerConcessions],
      },
      {
        name: "Earnest Money Deposit",
        key: "earnestMoney",
        values: [earnestMoney],
      },
      {
        name: "Closing Date",
        key: "closingDate",
        values: [closingDate],
      },
      {
        name: "Lease Back Days",
        key: "leasebackDays",
        values: [leasebackDays],
      },
      {
        name: "Possession",
        key: "possession",
        values: [possession],
      },
      {
        name: "Option Fee",
        key: "optionFee",
        values: [optionFee],
      },
      {
        name: "Option Days",
        key: "optionDays",
        values: [optionDays],
      },
      {
        name: "Non Realty Item",
        key: "nonRealtyItem",
        values: [nonRealtyItem],
      },
      {
        name: "Sale of Other Property",
        key: "saleOfOtherProperty",
        values: [saleOfOtherProperty],
      },
      {
        name: "Right To Terminate Due to Lender’s Appraisal",
        key: "rightToTerminate",
        values: [rightToTerminate],
      },
      {
        name: "Comments",
        key: "comments",
        values: [comments],
      },
    ];

    counterData = counterData.map((d) => {
      let values = d.values;
      if (counterOffers) {
        for (let offer of counterOffers) {
          const originalData = offer ? offer : this.props.location.state?.offer;
          if (offer.offerData && offer.offerData[d.key])
            values.push(offer.offerData[d.key]);
          else values.push(originalData[d.key]);
        }
      }
      d.values = values;
      return d;
    });

    const dropzone = (
      <Dropzone
        onDrop={(files, error) => this.onDrop(files, error)}
        maxSize={20000000}
        disabled={this.state.uploadingDocument}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div
              id="myDropZone"
              style={{
                height: 240,
                fontSize: 12,
                padding: 10,
                marginBottom: 20,
                marginTop: 15,
              }}
              className="dropzone dropzone-design"
            >
              <div className="dz-message" style={{ fontSize: 14 }}>
                <span>
                  {this.state.uploadingDocument
                    ? "Uploading document..."
                    : "Please upload final contract document."}
                </span>
                <br />
                <span>Drop file less than 20 Mb, here to upload</span>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    );

    const toggle = () => this.setState({ isTooltip: !this.state.isTooltip });

    if (this.state.loading) {
      return (
        <Loader
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          type="Rings"
          color="#ff214f"
          height={200}
          width={200}
        />
      );
    }

    return (
      <div className="content-area5 offer-details">
        <Alert
          color="danger"
          isOpen={showSellerCompanyAlert}
          toggle={() => {
            this.setState({ showSellerCompanyAlert: false });
          }}
          className="mobile-responsive-alert"
        >
          <div className="user-verify-wrapper">
            <p>
              You need to{" "}
              <span
                style={{
                  cursor: "pointer",
                  color: "#007bff",
                  fontWeight: "600",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  window.location.href = `/profiles/sellers/${
                    this.state.seller?.ListingKey || this.state.offer.seller
                  }`;
                }}
              >
                {" "}
                add company details
              </span>{" "}
              for your property to send offer to title company.
            </p>
            <Button
              onClick={() => {
                window.location.href = `/profiles/sellers/${
                  this.state.seller?.ListingKey || this.state.offer.seller
                }`;
              }}
            >
              Add Company Details
            </Button>
          </div>
        </Alert>

        <div className="dashboard-content bg-clr">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 p-0">
                <h3>Offer Terms</h3>
              </div>
              <div className="col-12 col-lg-6 d-flex justify-content-end m-0 p-0 mb-2 mt-3 mt-lg-0">
                {this.state.offer?.seller_id === user?.uid && (
                  <>
                    {status === "Executed" && (
                      <button
                        className="follow-my-listing-btn mr-2"
                        onClick={() => {
                          if (
                            this.state.seller?.title_company &&
                            this.state.seller?.escrow_officer &&
                            this.state.seller?.escrow_officer_email
                          ) {
                            this.setState({
                              sendToTitleModal: true,
                            });
                          } else {
                            this.setState({
                              showSellerCompanyAlert: true,
                            });
                            toast.error(
                              "No title company found. Find Property under My Listings, select edit to add Title Company's information",
                              {
                                position: toast.POSITION.TOP_RIGHT,
                              }
                            );
                          }
                        }}
                      >
                        <i class="fa fa-envelope mr-2" aria-hidden="true"></i>
                        Send to Title Company
                      </button>
                    )}
                    <button
                      className="follow-my-listing-btn mr-2"
                      onClick={(e) => {
                        e.preventDefault();
                        if (this.state.seller?.agents?.length > 0) {
                          this.setState({
                            updateModalText: "Send Offer to Sellers",
                            sendingOffersToSellerSMS: true,
                          });
                          this.sendSMSModalToggle();
                          // this.setState({
                          //   sendOfferToSellerModal: true,
                          // });
                        } else {
                          toast.error(
                            "No seller found. Find Property under My Listings, select edit to add Seller's information",
                            {
                              position: toast.POSITION.TOP_RIGHT,
                            }
                          );
                        }
                        // this.handleSendOffers(this.state.seller?.agents, offer);
                      }}
                    >
                      {/* <img
                        src={require("../assets/icons/follow-listing.svg")}
                      /> */}
                      <i className="fa fa-share-alt mr-2"></i>
                      Send Offer to Sellers
                    </button>

                    <button
                      className="follow-my-listing-btn mr-2"
                      onClick={() =>
                        this.setState({
                          smsBlast: true,
                        })
                      }
                    >
                      <img
                        src={require("../../src/assets/icons/follow-listing.svg")}
                      />
                      Follow My Listing
                    </button>
                  </>
                )}
                <ConfirmationModal
                  modalColor={offer?.archived?.includes(this.props.user?.uid) ? "success" : "danger"}
                  title="Confirmation"
                  message={`Are you sure you want to ${
                    offer?.archived?.includes(this.props.user?.uid) ? "unarchive" : "archive"
                  } this offer?`}
                  action={this.archiveOrUnarchiveOffer}
                >
                  <button
                    className="follow-my-listing-btn"
                    onClick={() => {
                      this.setState({
                        updateModalText: offer?.archived?.includes(this.props.user?.uid)
                          ? "Unarchive Offer"
                          : "Archive Offer",
                      });
                    }}
                  >
                    <img
                      className="archive-img"
                      src={require("../../src/assets/icons/archive.png")}
                    />
                    {offer?.archived?.includes(this.props.user?.uid) ? "Unarchive" : "Archive"}
                  </button>
                </ConfirmationModal>
              </div>
              {isMobile && (
                <button
                  className="mob-only-show-offer-trends-btn"
                  onClick={this.toggleOfferTrends}
                >
                  <img src={require("../assets/icons/trend-icon.svg")} alt="Trend icon" />
                  {showOfferTrendsMobile ? "Hide Offer Trends" : "Show Offer Trends"}
                </button>
              )}
              {new Date(offer_expire_date) < new Date() && (
                <>
                  <div className="col-12 d-flex justify-content-end mt-2">
                    {/* Compare date with current date to check if it has passed */}

                    <h3 className="text-danger">Offer Expired</h3>
                  </div>
                </>
              )}
            </div>
          </div>


          {(globalOfferDataAccess || offer.sellerEmail === user.email) && showOfferTrendsMobile && (
            <div className="container">
              <div className="current-offer-stat-cards">
                {/* <div className="container"> */}
                <div className="row justify-content-center">
                  <div className="col-xl-2 mb-2 col-lg-3 col-md-3 col-sm-6 col-12 offers-stats-card-container pl-0">
                    <div className="offers-stats-card current-offer-trends-card">
                      <div>
                        <img src={require("../assets/icons/trend-icon.svg")} />
                        <p> Offers Received</p>
                      </div>
                      <div className="offer-stats current-offer-trends">
                        <h5>
                          {
                            allOffers?.filter((o) => o.status !== "Deleted")
                              .length
                          }
                        </h5>
                        <p>
                          {allOffers?.filter((o) => o.verified)?.length}{" "}
                          verified (
                          {(
                            (allOffers?.filter((o) => o.verified)?.length *
                              100) /
                            allOffers?.length
                          )?.toFixed(1) + "%"}
                          )
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 mb-2 col-lg-3 col-md-3 col-sm-6 col-12 offers-stats-card-container">
                    <div className="offers-stats-card current-offer-trends-card">
                      <div>
                        <img src={require("../assets/icons/trend-icon.svg")} />
                        <p> Purchase Price</p>
                      </div>
                      <div className="offer-stats current-offer-trends">
                        <h5
                          style={{
                            color: avgPurchasePrice > 0 ? "green" : "red",
                          }}
                        >
                          {avgPurchasePrice}%
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 mb-2 col-lg-3 col-md-3 col-sm-6 col-12 offers-stats-card-container">
                    <div className="offers-stats-card current-offer-trends-card">
                      <div>
                        <img src={require("../assets/icons/trend-icon.svg")} />
                        <p> Finance Type</p>
                      </div>
                      <div className="offer-stats current-offer-trends">
                        <h5 className="font-15">
                          {this.state.avgFinancingType}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 mb-2 col-lg-3 col-md-3 col-sm-6 col-12 offers-stats-card-container">
                    <div className="offers-stats-card current-offer-trends-card">
                      <div>
                        <img src={require("../assets/icons/trend-icon.svg")} />
                        <p> Option Days</p>
                      </div>
                      <div className="offer-stats current-offer-trends">
                        <h5>{this.state.totalOptionDays}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 mb-2 col-lg-3 col-md-3 col-sm-6 col-12 offers-stats-card-container">
                    <div className="offers-stats-card current-offer-trends-card">
                      <div>
                        <img src={require("../assets/icons/trend-icon.svg")} />
                        <p> Earnest Money</p>
                      </div>
                      <div className="offer-stats current-offer-trends">
                        <h5>{this.state.totalEarnestMoney.toFixed()}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 mb-2 col-lg-3 col-md-3 col-sm-6 col-12 offers-stats-card-container pr-0">
                    <div className="offers-stats-card current-offer-trends-card">
                      <div>
                        <img src={require("../assets/icons/trend-icon.svg")} />
                        <p> Closing Days</p>
                      </div>
                      <div className="offer-stats current-offer-trends">
                        <h5>
                          {Math.ceil(this.state.avgCloseingDate) + " Days"}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 mb-2 col-lg-3 col-md-3 col-sm-6 col-12 offers-stats-card-container pl-0">
                    <div className="offers-stats-card current-offer-trends-card">
                      <div>
                        <img src={require("../assets/icons/trend-icon.svg")} />
                        <p>View Time</p>
                      </div>
                      <div className="offer-stats current-offer-trends">
                        <h5>{this.state.avgViewingTime}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 mb-2 col-lg-3 col-md-3 col-sm-6 col-12 offers-stats-card-container">
                    <div className="offers-stats-card current-offer-trends-card">
                      <div>
                        <img src={require("../assets/icons/trend-icon.svg")} />
                        <p>Possession</p>
                      </div>
                      <div className="offer-stats current-offer-trends">
                        <h5 className="font-15">
                          {this.state.totalPosseions || "N/A"}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 mb-2 col-lg-3 col-md-3 col-sm-6 col-12 offers-stats-card-container">
                    <div className="offers-stats-card current-offer-trends-card">
                      <div>
                        <img src={require("../assets/icons/trend-icon.svg")} />
                        <p>Down Payment</p>
                      </div>
                      <div className="offer-stats current-offer-trends">
                        <h5>
                          <NumberFormat
                            value={`${Number(
                              this.state.totalDownPayment
                            ).toFixed(0)}`}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            renderText={(value, props) => <>{value || 0}</>}
                          />
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 mb-2 col-lg-3 col-md-3 col-sm-6 col-12 offers-stats-card-container">
                    <div className="offers-stats-card current-offer-trends-card">
                      <div>
                        <img src={require("../assets/icons/trend-icon.svg")} />
                        <p>Option Fee</p>
                      </div>
                      <div className="offer-stats current-offer-trends">
                        <h5>
                          <NumberFormat
                            value={`${Number(this.state.totalOptionFee).toFixed(
                              0
                            )}`}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            renderText={(value, props) => <>{value || 0}</>}
                          />
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 mb-2 col-lg-3 col-md-3 col-sm-6 col-12 offers-stats-card-container">
                    <div className="offers-stats-card current-offer-trends-card">
                      <div>
                        <img src={require("../assets/icons/trend-icon.svg")} />
                        <p>Title policy expense</p>
                      </div>
                      <div className="offer-stats current-offer-trends">
                        <h5 className="font-15">
                          {this.state.totalExpenseType || "N/A"}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 mb-2 col-lg-3 col-md-3 col-sm-6 col-12 offers-stats-card-container pr-0">
                    <div className="offers-stats-card current-offer-trends-card">
                      <div>
                        <img src={require("../assets/icons/trend-icon.svg")} />
                        <p>Sale of other property</p>
                      </div>
                      <div className="offer-stats current-offer-trends">
                        <h5>{this.state.totalSaleOfProperty || "N/A"}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="container">
            <div className="dashboard-list dashboard-list-no-border bg-clr-white">
              <div className="row">
                <div className="col-xl-3 col-lg-6 col-md-12 house">
                  <Link to={`/sellers/${seller}`} className="link">
                    <img
                      src={
                        offer?.featured_image?.url
                          ? offer.featured_image.url
                          : House
                      }
                      alt="house"
                    />
                  </Link>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-12 homeoffer-details">
                  <ul>
                    <li>
                      <p className="offer">
                        Offer {curItem} of {totalItems}
                        {verified ? (
                          <img
                            src={VerifiedListing}
                            style={{ marginLeft: 10 }}
                          />
                        ) : null}
                      </p>
                    </li>
                    {this.renderStatus(status, isBuyer, acceptComment)}
                    <li>
                      <h5>{address}</h5>
                    </li>
                    <li>
                      <img src={Person} />
                      <p>
                        Buyers agent{" "}
                        <span>
                          {/* {sellerEmail === user?.email ? ( */}
                            <AgentInformationModal
                              comingFrom="offersPage"
                              key={this.state.offer.id}
                              offer={this.state.offer}
                            />
                          {/* ) : (
                            <></>
                          )} */}
                          , {brokerageName}
                        </span>
                      </p>
                    </li>
                    {offer?.buyerName && offer?.buyersEmail && sellerEmail === user?.email && <li>
                      <img src={Person} />
                      <p>
                        Buyer{" "}
                        <span>
                            <AgentInformationModal
                              comingFrom="offersPage"
                              key={this.state.offer.id}
                              offer={this.state.offer}
                              buyerInfo={true}
                            />
                        </span>
                      </p>
                    </li>}
                    {offer.lenderCompany && offer.lenderName && (
                      <li>
                        <img src={Lender} />
                        <p>
                          lender info{" "}
                          <span>
                            <span
                              className="underline m-0"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.setState({ agentModal: true })
                              }
                            >
                              {offer.lenderName}
                            </span>
                            , {offer.lenderCompany}
                          </span>
                        </p>
                        <Modal
                          isOpen={this.state.agentModal}
                          size="md"
                          fade={false}
                          toggle={this.agentModalToggle}
                        >
                          <ModalHeader
                            className="modal-header"
                            toggle={this.agentModalToggle}
                          ></ModalHeader>
                          <ModalBody className="modal-body">
                            <Container>
                              <Row>
                                <Col>
                                  <div className="flexCenter">
                                    <img
                                      alt="Agent"
                                      src={AgentAvatar}
                                      className="analytics-agent-img"
                                    />
                                    <h3>{offer.lenderName || "N/A"}</h3>
                                  </div>
                                  <div className="analytics-agent-details">
                                    <div className="analytics-row center-me">
                                      {/* <i className="fa fa-envelope"></i><a href='mailto:inkrealtygroup@gmail.com'>inkrealtygroup@gmail.com</a>  */}
                                      <i className="fa fa-envelope"></i>
                                      <p>{offer.lenderEmail || "N/A"}</p>
                                    </div>
                                    <div className="analytics-row center-me mt-10">
                                      <i className="fa fa-phone phone-icon"></i>
                                      <p>{offer?.lenderPhoneNumber || "N/A"}</p>
                                    </div>
                                    <p className="agent-brokerage mt-10">
                                      Company:
                                    </p>
                                    <p>{offer.lenderCompany || "N/A"}</p>
                                  </div>
                                  <div className="flex-center">
                                    <button
                                      className="cancel-btn mVertical-30"
                                      onClick={this.agentModalToggle}
                                    >
                                      Close
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </ModalBody>
                        </Modal>
                      </li>
                    )}
                    {(offer.status === "Accepted" ||
                      offer.status === "Executed") &&
                      this.state.seller.title_company &&
                      this.state.seller.escrow_officer && (
                        <li>
                          <img src={Building} />
                          <p>
                            title company{" "}
                            <span>
                              <span
                                className="underline m-0"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.setState({ titleCompanyModal: true })
                                }
                              >
                                {this.state.seller.escrow_officer}
                              </span>
                              , {this.state.seller.title_company}
                            </span>
                          </p>
                          <Modal
                            isOpen={this.state.titleCompanyModal}
                            size="md"
                            fade={false}
                            toggle={() => {
                              this.setState({ titleCompanyModal: false });
                            }}
                          >
                            <ModalHeader
                              className="modal-header"
                              toggle={() => {
                                this.setState({ titleCompanyModal: false });
                              }}
                            ></ModalHeader>
                            <ModalBody className="modal-body">
                              <Container>
                                <Row>
                                  <Col>
                                    <div className="flexCenter">
                                      <img
                                        alt="Agent"
                                        src={AgentAvatar}
                                        className="analytics-agent-img"
                                      />
                                      <h3>
                                        {this.state.seller.escrow_officer ||
                                          "N/A"}
                                      </h3>
                                    </div>
                                    <div className="analytics-agent-details">
                                      <div className="analytics-row center-me">
                                        {/* <i className="fa fa-envelope"></i><a href='mailto:inkrealtygroup@gmail.com'>inkrealtygroup@gmail.com</a>  */}
                                        <i className="fa fa-envelope"></i>
                                        <p>
                                          {this.state.seller
                                            .escrow_officer_email || "N/A"}
                                        </p>
                                      </div>
                                      <div className="analytics-row center-me mt-10">
                                        <i className="fa fa-phone phone-icon"></i>
                                        <p>
                                          {this.state.seller
                                            .escrow_officer_phone || "N/A"}
                                        </p>
                                      </div>
                                      <p className="agent-brokerage mt-10">
                                        Company:
                                      </p>
                                      <p>
                                        {this.state.seller.title_company ||
                                          "N/A"}
                                      </p>
                                    </div>
                                    <div className="flex-center">
                                      <button
                                        className="cancel-btn mVertical-30"
                                        onClick={() => {
                                          this.setState({
                                            titleCompanyModal: false,
                                          });
                                        }}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </ModalBody>
                          </Modal>
                        </li>
                      )}
                    {this.state.seller.company_to_tc &&
                      this.state.seller.transaction_coordinator && (
                        <li>
                          <img src={Transaction} />
                          <p>
                            tc info{" "}
                            <span>
                              <span
                                className="underline m-0"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.setState({ tcAgentModal: true })
                                }
                              >
                                {this.state.seller.transaction_coordinator}
                              </span>
                              , {this.state.seller.company_to_tc}
                            </span>
                          </p>
                          <Modal
                            isOpen={this.state.tcAgentModal}
                            size="md"
                            fade={false}
                            toggle={() => {
                              this.setState({ tcAgentModal: false });
                            }}
                          >
                            <ModalHeader
                              className="modal-header"
                              toggle={() => {
                                this.setState({ tcAgentModal: false });
                              }}
                            ></ModalHeader>
                            <ModalBody className="modal-body">
                              <Container>
                                <Row>
                                  <Col>
                                    <div className="flexCenter">
                                      <img
                                        alt="Agent"
                                        src={AgentAvatar}
                                        className="analytics-agent-img"
                                      />
                                      <h3>
                                        {this.state.seller
                                          .transaction_coordinator || "N/A"}
                                      </h3>
                                    </div>
                                    <div className="analytics-agent-details">
                                      <div className="analytics-row center-me">
                                        {/* <i className="fa fa-envelope"></i><a href='mailto:inkrealtygroup@gmail.com'>inkrealtygroup@gmail.com</a>  */}
                                        <i className="fa fa-envelope"></i>
                                        <p>
                                          {this.state.seller
                                            .transaction_coordinator_email ||
                                            "N/A"}
                                        </p>
                                      </div>
                                      <div className="analytics-row center-me mt-10">
                                        <i className="fa fa-phone phone-icon"></i>
                                        <p>
                                          {this.state.seller
                                            .transaction_coordinator_phone ||
                                            "N/A"}
                                        </p>
                                      </div>
                                      <p className="agent-brokerage mt-10">
                                        Company:
                                      </p>
                                      <p>
                                        {this.state.seller.company_to_tc ||
                                          "N/A"}
                                      </p>
                                    </div>
                                    <div className="flex-center">
                                      <button
                                        className="cancel-btn mVertical-30"
                                        onClick={() => {
                                          this.setState({
                                            tcAgentModal: false,
                                          });
                                        }}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </ModalBody>
                          </Modal>
                        </li>
                      )}
                    <li>
                      <img src={Calendar} />
                      <p>
                        <span>
                          {moment(createdAt * 1000).format(
                            "MMMM Do YYYY, [at] h:mm:ss a"
                          )}{" "}
                          CST ({moment(createdAt * 1000).fromNow()})
                        </span>
                      </p>
                    </li>
                    {viewedAt && (
                      <li>
                        <img src={Flag} />
                        <p>
                          The offer has been reviewed{" "}
                          {moment(viewedAt * 1000).format(
                            "MM.DD.YYYY [at] hh:mm A"
                          )}{" "}
                          CST by{" "}
                          <span className="underline">
                            <AgentInformationModal
                              comingFrom="otherAgents"
                              key={this.state.offer.id}
                              offer={this.state.offer}
                              sellerAgent={true}
                            />
                          </span>
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="col-xl-4 col-lg-12 col-md-12">
                  <div className="homeoffer">
                    {!!purchasePrice && <>
                      <p>Purchase Price</p>
                    <NumberFormat
                      className="red-price-tag"
                      value={purchasePrice}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      renderText={(value, props) => <p {...props}>{value}</p>}
                    />
                    </>}
                    {!!downPayment && <>
                      <p>Down Payment </p>
                      <NumberFormat
                        className="black-price-tag"
                        value={downPayment}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value, props) => <p {...props}>{value}</p>}
                      />
                    </>
                    }
                    {!!financeAmount && <>
                      <p>Finance Amount</p>
                      <NumberFormat
                        className="red-price-tag"
                        value={financeAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value, props) => <p {...props}>{value}</p>}
                      />
                    </>}
                  </div>
                </div>
              </div>
              <div className="row mt-95">
                <div className="col-xl-12">
                  {/* <div className="row space-between btns-mobile-center m-lr-0"> */}
                  <div className="row space-between btns-mobile-center padd-horizontal-15">
                    <div>
                      <Link
                        to={`${
                          prevURL ? `/${prevURL.split("/")[3]}` : `/offers`
                        }`}
                      >
                        <button className="back-btn">
                          <img
                            src={BackArrow}
                            className="backArrow"
                            alt="back arrow"
                          />
                          Back
                        </button>
                      </Link>
                    </div>

                    <div>
                      <button
                        className="offer-detail-actions"
                        onClick={() =>
                          this.setState({
                            offerActions: !offerActions,
                          })
                        }
                      >
                        {offerActions ? "Hide Actions" : "Actions"}{" "}
                        <img src={DropDownArrow} />
                      </button>

                      <button
                        onClick={this.handleVeifyOffer}
                        className="verify-offer-btn"
                        disabled={verifiedLoading}
                        title="OfferTrends shows the percentage of offers received which terms have been verified"
                        id="Tooltip"
                      >
                        {offer.verified
                          ? "Verified Offer Input"
                          : "Verify Offer Input"}
                      </button>
                      <Tooltip
                        placement="top"
                        isOpen={this.state.isTooltip}
                        target="Tooltip"
                        toggle={toggle}
                        className="info-tooltip"
                      >
                        OfferTrends shows the percentage of offers received
                        which terms below have been verified
                      </Tooltip>
                    </div>
                  </div>
                  {offerActions && (
                    <div className="action-btns">
                      <div className="column flexEnd">
                        {isBuyer ? (
                          <div className="column flexEnd">
                            <ConfirmationModal
                              modalColor="success"
                              title="Confirmation"
                              message={`Are you sure you want to Withdraw this offer?`}
                              action={() =>
                                this.updateOfferStatus("Withdraw", "Withdrawn")
                              }
                            >
                              <button
                                disabled={
                                  status !== "In Review" ||
                                  status === "Executed"
                                }
                                className="red-btn btn-list-margin"
                                onClick={() => {
                                  this.setState({
                                    updateModalText: "Withdraw Offer",
                                  });
                                  this.sendSMSModalToggle();
                                }}
                              >
                                WithDraw Offer
                              </button>
                            </ConfirmationModal>{" "}
                            <button
                              className="green-btn no-margin btn-list-margin"
                              disabled={status === "Executed"}
                              onClick={() =>
                                history.push(
                                  `/makeOffer/${seller}?offer=${offerId}`
                                )
                              }
                            >
                              <img
                                src={Tick}
                                className="backArrow"
                                alt="tick"
                              />
                              Resubmit offer
                            </button>
                            {counterOffers?.length && (
                              <button
                                className="btn-review btn-list-margin"
                                onClick={this.toggleModal}
                              >
                                View Counter Offers
                              </button>
                            )}
                            {status === "Executed" && (
                              <button
                                className="green-btn btn-list-margin"
                                onClick={() => {
                                  this.toggleExecutionModal();
                                }}
                              >
                                View Execution Details
                              </button>
                            )}
                            <ConfirmationModal
                              modalColor="success"
                              title="Confirmation"
                              message={`Are you sure you want to Delete this offer?`}
                              action={this.deleteOffer}
                            >
                              <button
                                className="red-btn btn-list-margin delete-offer-btn"
                                disabled={deletingOffer}
                                onClick={() => {
                                  this.setState({
                                    updateModalText: "Delete Offer",
                                  });
                                  this.sendSMSModalToggle();
                                }}
                              >
                                {deletingOffer
                                  ? "Deleting Offer"
                                  : "Delete Offer"}
                              </button>
                            </ConfirmationModal>
                            <ShareButtonsModal
                              offer={offer}
                              modalColor="success"
                              title="Share Buttons"
                              message={`Share`}
                              action={() =>
                                this.setState({
                                  offerActions: !this.state.offerActions,
                                })
                              }
                            >
                              <button className="red-btn btn-list-margin">
                                <img
                                  src={Share}
                                  alt="Share"
                                  style={{
                                    marginRight: 10,
                                    height: "60%",
                                    opacity: "0.9",
                                    marginBottom: 3,
                                  }}
                                />
                                Share
                              </button>
                            </ShareButtonsModal>
                            <a
                              href={`/makeOffer/${seller}?offer=${offerId}&update=1`}
                              disabled={
                                status !== "In Review" || status === "Executed"
                              }
                              className="offer-detail-actions btn-list-margin"
                            >
                              Update Terms
                            </a>
                          </div>
                        ) : (
                          <div className="column flexEnd">
                            {status !== "Executed" && (
                              <div className="column flexEnd">
                                <ConfirmationModal
                                  showCommentBox={
                                    acceptStatus === "Accept Offer"
                                      ? true
                                      : false
                                  }
                                  showCheckbox
                                  modalColor="success"
                                  title="Confirmation"
                                  message={`Are you sure you want to ${
                                    acceptStatus.split(" ")[0]
                                  } this offer?`}
                                  action={(declineOthers, comment) => {
                                    !this.state.sendSMSModal &&
                                      this.updateOfferStatus(
                                        status === "In Review" ||
                                          status === "Seller Review" ||
                                          status === "Countered"
                                          ? "Accepted"
                                          : "In Review",
                                        acceptStatus.split(" ")[0],
                                        declineOthers,
                                        comment !== "" ? true : false,
                                        comment
                                      );
                                  }}
                                  checkLabel={`Do you want to ${
                                    status === "In Review" ||
                                    status === "Seller Review"
                                      ? "decline"
                                      : "un-decline"
                                  } other offers of this property.`}
                                >
                                  <button
                                    className="green-btn btn-list-margin"
                                    disabled={
                                      status === "Not Accepted" ||
                                      isOtherOfferAccepted ||
                                      status === "Withdraw" ||
                                      status === "Executed"
                                    }
                                    onClick={() => {
                                      this.setState({
                                        updateModalText: acceptStatus.split([
                                          0,
                                        ]),
                                      });
                                      this.sendSMSModalToggle();
                                    }}
                                  >
                                    <img
                                      src={Tick}
                                      className="backArrow"
                                      alt="tick"
                                    />
                                    {acceptStatus}
                                  </button>
                                </ConfirmationModal>
                                <ConfirmationModal
                                  modalColor="danger"
                                  title="Confirmation"
                                  message={`Are you sure you want to ${
                                    declineStatus.split(" ")[0]
                                  } this offer?`}
                                  action={() =>
                                    this.updateOfferStatus(
                                      status === "In Review" ||
                                        status === "Seller Review" ||
                                        status === "Countered"
                                        ? "Not Accepted"
                                        : "In Review",
                                      declineStatus.split(" ")[0]
                                    )
                                  }
                                >
                                  <button
                                    className="red-btn btn-list-margin"
                                    disabled={
                                      status === "Accepted" ||
                                      status === "Withdraw" ||
                                      status === "Executed"
                                    }
                                    onClick={() => {
                                      this.setState({
                                        updateModalText:
                                          declineStatus.split(" ")[0],
                                      });
                                      this.sendSMSModalToggle();
                                    }}
                                  >
                                    <img
                                      src={Cross}
                                      className="backArrow"
                                      alt="back arrow"
                                    />
                                    {declineStatus}
                                  </button>
                                </ConfirmationModal>
                                <button
                                  className="btn-review view-counter-offer fit-content btn-list-margin"
                                  onClick={this.toggleModal}
                                >
                                  Submit/View Counter Offers
                                </button>
                                <ConfirmationModal
                                  modalColor="success"
                                  title="Confirmation"
                                  message={`Are you sure you want to Withdraw this offer?`}
                                  action={() =>
                                    this.updateOfferStatus(
                                      "Withdraw",
                                      "Withdrawn"
                                    )
                                  }
                                >
                                  <button
                                    disabled={
                                      status !== "In Review" ||
                                      status === "Executed"
                                    }
                                    className="red-btn btn-list-margin"
                                    onClick={() => {
                                      this.setState({
                                        updateModalText: "Withdraw Offer",
                                      });
                                      this.sendSMSModalToggle();
                                    }}
                                  >
                                    WithDraw Offer
                                  </button>
                                </ConfirmationModal>{" "}
                                <button
                                  disabled={
                                    status === "Executed" ||
                                    status !== "Accepted"
                                  }
                                  className="green-btn fit-content btn-list-margin"
                                  onClick={() => {
                                    this.setState({
                                      updateModalText: "Execute Offer",
                                    });
                                    this.sendSMSModalToggle();
                                    this.toggleExecutionModal();
                                  }}
                                >
                                  Execute Offer
                                </button>
                                <a
                                  href={`/makeOffer/${seller}?offer=${offerId}&update=1`}
                                  disabled={
                                    status !== "In Review" ||
                                    status === "Executed"
                                  }
                                  className="offer-detail-actions btn-list-margin"
                                >
                                  Update Terms
                                </a>
                              </div>
                            )}
                            {status === "Executed" && (
                              <ConfirmationModal
                                modalColor="success"
                                title="Confirmation"
                                message={`Are you sure you want to Terminate this offer?`}
                                action={this.terminateOffer}
                              >
                                <button
                                  className="red-btn btn-list-margin"
                                  onClick={() => {
                                    this.setState({
                                      updateModalText: "Terminate Offer",
                                    });
                                    this.sendSMSModalToggle();
                                  }}
                                >
                                  Terminate Offer
                                </button>
                              </ConfirmationModal>
                            )}
                            <ShareButtonsModal
                              offer={offer}
                              modalColor="success"
                              title="Share Buttons"
                              message={`Share`}
                              action={() =>
                                this.setState({
                                  offerActions: !this.state.offerActions,
                                })
                              }
                            >
                              <button className="red-btn btn-list-margin">
                                <img
                                  src={Share}
                                  alt="Share"
                                  style={{
                                    marginRight: 10,
                                    height: "60%",
                                    opacity: "0.9",
                                    marginBottom: 3,
                                  }}
                                />
                                Share
                              </button>
                            </ShareButtonsModal>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {isOtherOfferAccepted && (
                    <div className="d-flex flex-row justify-content-end w-100 mt-2">
                      <p>
                        Another offer is already accepted for this property,
                        Please un-accept it first.
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-2 text-center">
                <div className="col-md-12">
                  {this.props.location.state?.curItem > 1 && (
                    <Link
                      to={{
                        pathname: `/offers/${
                          this.props.location.state?.allOffers[
                            this.props.location.state?.curItem - 2
                          ]?.id
                        }`,
                        search: `?curItem=${
                          this.props.location.state?.curItem - 1
                        }&totalItems=${totalItems}${
                          prevURL
                            ? `&prevUrl=${encodeURIComponent(prevURL)}`
                            : ``
                        }`,
                        state: {
                          offer:
                            this.props.location.state?.allOffers[
                              this.props.location.state?.curItem - 2
                            ],
                          curItem: this.props.location.state?.curItem - 1,
                          totalItems,
                          allOffers: this.props.location.state?.allOffers,
                        },
                      }}
                    >
                      {" "}
                      <button className="next-prev-btn mr-2">
                        <img
                          src={nextPrev}
                          className="backArrow"
                          alt="back arrow"
                          style={{ marginBottom: "2px", height: "10px" }}
                        />
                        Previous
                      </button>
                    </Link>
                  )}
                  {this.props.location.state?.curItem <
                    this.props.location.state?.totalItems && (
                    <Link
                      to={{
                        pathname: `/offers/${
                          this.props.location.state?.allOffers[
                            this.props.location.state?.curItem
                          ]?.id
                        }`,
                        search: `?curItem=${
                          this.props.location.state?.curItem + 1
                        }&totalItems=${totalItems}${
                          prevURL
                            ? `&prevUrl=${encodeURIComponent(prevURL)}`
                            : ``
                        }`,
                        state: {
                          offer:
                            this.props.location.state?.allOffers[
                              this.props.location.state?.curItem
                            ],
                          curItem: this.props.location.state?.curItem + 1,
                          totalItems,
                          allOffers: this.props.location.state?.allOffers,
                        },
                      }}
                    >
                      <button className="next-prev-btn">
                        Next
                        <img
                          src={nextPrev}
                          className="backArrow ml-2"
                          alt="back arrow"
                          style={{
                            transform: "rotate(180deg)",
                            marginBottom: "2px",
                            height: "10px",
                          }}
                        />
                      </button>
                    </Link>
                  )}
                </div>
              </div>
              <div className="row mt-40 mb-13">
                <div className="col-xl-6 col-lg-12 col-md-12 overflow">
                  <Table striped>
                    <tbody>
                      {!!offerType && (
                          <tr>
                            <td className="bold-td">Offer Type</td>
                            <td className="normal-td">
                              {offerType}
                            </td>
                          </tr>
                        )}
                      {offerType === 'Verbal' && (
                        <tr>
                          <td className="bold-td">Cash Type</td>
                          <td className="normal-td">
                            {cashType}
                          </td>
                        </tr>
                      )}
                      {!!purchasePrice && (
                          <tr>
                            <td className="bold-td">Purchase Price</td>
                            <td className="normal-td">
                              {/* <span
                                style={{
                                  color:
                                    this.state.seller?.minimum_offer_price &&
                                    Number(purchasePrice) < Number(this.state.seller.minimum_offer_price)
                                      ? "red"
                                      : "inherit",
                                }}
                              >
                                {purchasePrice}
                              </span> */}
                              <NumberFormat
                                value={purchasePrice}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                renderText={(value) => (
                                  <span
                                    style={{
                                      color:
                                        this.state.seller?.minimum_offer_price &&
                                        Number(purchasePrice) < Number(this.state.seller.minimum_offer_price)
                                          ? "red"
                                          : "inherit",
                                    }}
                                  >
                                    {value}
                                  </span>
                                )}
  />
                            {offerType === 'Verbal' && this.state.seller?.minimum_offer_price &&
                              Number(purchasePrice) < Number(this.state.seller.minimum_offer_price) && (
                                <>
                                  <br />
                                  <span
                                    className="red-text"
                                    style={{ fontSize: 12, display: "block" }}
                                  >
                                    Min price term was:{" "}
                                    <strong>
                                      {this.formatNumber(Number(this.state.seller.minimum_offer_price))}
                                    </strong>
                                  </span>
                                </>
                              )}
                              {latestCounterOffer && latestCounterOffer?.purchasePrice && <>
                                <br />
                                <span
                                  onClick={this.toggleModal}
                                  className={`${`counter-offer-table-text-${
                                    Number(latestCounterOffer?.purchasePrice) >=
                                      Number(purchasePrice) || greenColor
                                      ? `green`
                                      : `red`
                                  }`}`}
                                >
                                  {this.formatNumber(
                                    Number(latestCounterOffer?.purchasePrice)
                                  )}
                                  {/^\d+$/.test(
                                    latestCounterOffer?.purchasePrice
                                  ) && (
                                    <i
                                      className={`fa fa-arrow-${
                                        latestCounterOffer?.purchasePrice >=
                                        purchasePrice
                                          ? `up`
                                          : `down`
                                      } ml-2`}
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </span>
                              </>}
                            </td>
                          </tr>
                        )}
                      {!!financingType && <tr>
                        <td className="bold-td">Finance Type</td>
                        <td className="normal-td">
                          {financingType}
                          {latestCounterOffer &&
                            latestCounterOffer?.financingType && (
                              <>
                                <br />
                                <span
                                  onClick={this.toggleModal}
                                  className={`${`counter-offer-table-text-${
                                    financingType ===
                                      latestCounterOffer?.financingType ||
                                    greenColor
                                      ? `green`
                                      : `red`
                                  }`}`}
                                >
                                  {latestCounterOffer?.financingType}
                                </span>
                              </>
                            )}
                        </td>
                      </tr>}
                      {!!downPayment && <tr>
                        <td className="bold-td">Down Payment</td>
                        <NumberFormat
                          className="normal-td"
                          value={downPayment}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value, props) => (
                            <td {...props}>
                              {value}
                              {latestCounterOffer &&
                                latestCounterOffer?.downPayment && (
                                  <>
                                    <br />
                                    <span
                                      onClick={this.toggleModal}
                                      className={`${`counter-offer-table-text-${
                                        Number(
                                          latestCounterOffer?.downPayment
                                        ) >= Number(downPayment) || greenColor
                                          ? `green`
                                          : `red`
                                      }`}`}
                                    >
                                      {this.formatNumber(
                                        Number(latestCounterOffer?.downPayment)
                                      )}
                                      {/^\d+$/.test(
                                        latestCounterOffer?.downPayment
                                      ) && (
                                        <i
                                          className={`fa fa-arrow-${
                                            latestCounterOffer?.downPayment >=
                                            downPayment
                                              ? `up`
                                              : `down`
                                          } ml-2`}
                                          aria-hidden="true"
                                        ></i>
                                      )}
                                    </span>
                                  </>
                                )}
                            </td>
                          )}
                        />
                      </tr>}
                      {!!financeAmount && <tr>
                        <td className="bold-td">Finance Amount</td>
                        <NumberFormat
                          className="normal-td"
                          value={financeAmount}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value, props) => (
                            <td {...props}>
                              {value}
                              {latestCounterOffer &&
                                latestCounterOffer?.financeAmount && (
                                  <>
                                    <br />
                                    <span
                                      onClick={this.toggleModal}
                                      className={`${`counter-offer-table-text-${
                                        Number(
                                          latestCounterOffer?.financeAmount
                                        ) >= Number(financeAmount) || greenColor
                                          ? `green`
                                          : `red`
                                      }`}`}
                                    >
                                      {this.formatNumber(
                                        Number(
                                          latestCounterOffer?.financeAmount
                                        )
                                      )}
                                      {/^\d+$/.test(
                                        latestCounterOffer?.financeAmount
                                      ) && (
                                        <i
                                          className={`fa fa-arrow-${
                                            latestCounterOffer?.financeAmount >=
                                            financeAmount
                                              ? `up`
                                              : `down`
                                          } ml-2`}
                                          aria-hidden="true"
                                        ></i>
                                      )}
                                    </span>
                                  </>
                                )}
                            </td>
                          )}
                        />
                      </tr>}
                      {!!sellerConcessions && <tr>
                        <td className="bold-td">Seller Concession</td>
                        <td className="normal-td">
                          ${sellerConcessions}
                          {latestCounterOffer &&
                            latestCounterOffer?.sellerConcessions && (
                              <>
                                <br />
                                <span
                                  onClick={this.toggleModal}
                                  className={`${`counter-offer-table-text-${
                                    Number(
                                      latestCounterOffer?.sellerConcessions
                                    ) >= Number(sellerConcessions) || greenColor
                                      ? `green`
                                      : `red`
                                  }`}`}
                                >
                                  {this.formatNumber(
                                    Number(
                                      latestCounterOffer?.sellerConcessions
                                    )
                                  )}
                                  {/^\d+$/.test(
                                    latestCounterOffer?.sellerConcessions
                                  ) && (
                                    <i
                                      className={`fa fa-arrow-${
                                        latestCounterOffer?.sellerConcessions >=
                                        sellerConcessions
                                          ? `up`
                                          : `down`
                                      } ml-2`}
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </span>
                              </>
                            )}
                        </td>
                      </tr>}
                      <tr>
                        <td className="bold-td">Earnest Money Deposit</td>
                        <NumberFormat
                          className="normal-td"
                          value={earnestMoney}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value, props) => (
                            <td {...props}>
                              <span
                                style={{
                                  color:
                                    this.state.seller?.deposit_money &&
                                    Number(earnestMoney) < Number(this.state.seller.deposit_money)
                                      ? "red"
                                      : "inherit",
                                }}
                              >
                                {value}
                              </span>
                              {offerType === 'Verbal' && this.state.seller?.deposit_money &&
                              Number(earnestMoney) < Number(this.state.seller.deposit_money) && (
                                <>
                                  <br />
                                  <span
                                    className="red-text"
                                    style={{ fontSize: 12, display: "block" }}
                                  >
                                    Min deposit term was:{" "}
                                    <strong>
                                      {this.formatNumber(Number(this.state.seller.deposit_money))}
                                    </strong>
                                  </span>
                                </>
                              )}
                              {latestCounterOffer &&
                                latestCounterOffer?.earnestMoney && (
                                  <>
                                    <br />
                                    <span
                                      onClick={this.toggleModal}
                                      className={`${`counter-offer-table-text-${
                                        Number(
                                          latestCounterOffer?.earnestMoney
                                        ) >= Number(earnestMoney) || greenColor
                                          ? `green`
                                          : `red`
                                      }`}`}
                                    >
                                      {this.formatNumber(
                                        Number(latestCounterOffer?.earnestMoney)
                                      )}
                                      {/^\d+$/.test(
                                        latestCounterOffer?.earnestMoney
                                      ) && (
                                        <i
                                          className={`fa fa-arrow-${
                                            latestCounterOffer?.earnestMoney >=
                                            earnestMoney
                                              ? `up`
                                              : `down`
                                          } ml-2`}
                                          aria-hidden="true"
                                        ></i>
                                      )}
                                    </span>
                                  </>
                                )}
                            </td>
                          )}
                        />
                      </tr>
                      {!!titlePolicyExpense && <tr>
                        <td className="bold-td">Title Expense</td>
                        <td className="normal-td">
                          {titlePolicyExpense}
                          {latestCounterOffer &&
                            latestCounterOffer?.titlePolicyExpense && (
                              <>
                                <br />
                                <span
                                  onClick={this.toggleModal}
                                  className={`${`counter-offer-table-text-${
                                    titlePolicyExpense ===
                                      latestCounterOffer?.titlePolicyExpense ||
                                    greenColor
                                      ? `green`
                                      : `red`
                                  }`}`}
                                >
                                  {latestCounterOffer?.titlePolicyExpense}
                                </span>
                              </>
                            )}
                        </td>
                      </tr>}
                      {!!residentialServiceContract && <tr>
                        <td className="bold-td">Service Contract</td>
                        <td>
                          ${residentialServiceContract}
                          {latestCounterOffer &&
                            latestCounterOffer?.residentialServiceContract && (
                              <>
                                <br />
                                <span
                                  onClick={this.toggleModal}
                                  className={`${`counter-offer-table-text-${
                                    Number(
                                      latestCounterOffer?.residentialServiceContract
                                    ) >= Number(residentialServiceContract) ||
                                    greenColor
                                      ? `green`
                                      : `red`
                                  }`}`}
                                >
                                  {this.formatNumber(
                                    Number(
                                      latestCounterOffer?.residentialServiceContract
                                    )
                                  )}
                                  {/^\d+$/.test(
                                    latestCounterOffer?.residentialServiceContract
                                  ) && (
                                    <i
                                      className={`fa fa-arrow-${
                                        latestCounterOffer?.residentialServiceContract >=
                                        residentialServiceContract
                                          ? `up`
                                          : `down`
                                      } ml-2`}
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </span>
                              </>
                            )}
                        </td>
                      </tr>}
                      {!!offer_expire_date && (
                        <tr>
                          <td className="bold-td">Offer Expire Date</td>
                          <td className="normal-td">
                            {offer_expire_date}
                            {latestCounterOffer &&
                              latestCounterOffer?.offer_expire_date && (
                                <>
                                  <br />
                                  <span
                                    onClick={this.toggleModal}
                                    className={`${`counter-offer-table-text-${
                                      Number(
                                        latestCounterOffer?.offer_expire_date
                                      ) > Number(offer_expire_date) ||
                                      greenColor
                                        ? `green`
                                        : `red`
                                    }`}`}
                                  >
                                    ${latestCounterOffer?.offer_expire_date}
                                    {/^\d+$/.test(
                                      latestCounterOffer?.offer_expire_date
                                    ) && (
                                      <i
                                        className={`fa fa-arrow-${
                                          latestCounterOffer?.offer_expire_date >
                                          offer_expire_date
                                            ? `up`
                                            : `down`
                                        } ml-2`}
                                        aria-hidden="true"
                                      ></i>
                                    )}
                                  </span>
                                </>
                              )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 overflow">
                  <Table striped>
                    <tbody>
                      <tr>
                        <td className="bold-td">Closing Date</td>
                        <td className="normal-td">
                        <span
                          style={{
                            color:
                              offerType === 'Verbal' &&
                              this.state.seller?.closing_date?.seconds &&
                              closingDate &&
                              moment(closingDate).isAfter(
                                moment(this.state.seller.closing_date.seconds * 1000)
                              )
                                ? "red"
                                : "inherit",
                          }}
                          >
                            {moment(closingDate).format("YYYY-MM-DD")}
                          </span>
                          {offerType === 'Verbal' &&
                          this.state.seller?.closing_date?.seconds &&
                          closingDate &&
                          moment(closingDate).isAfter(
                            moment(this.state.seller.closing_date.seconds * 1000)
                          ) && (
                            <>
                              <br />
                              <span
                                className="red-text"
                                style={{ fontSize: 12, display: "block" }}
                              >
                                Term closing date was:{" "}
                                <strong>
                                  {moment(
                                    this.state.seller.closing_date.seconds * 1000
                                  ).format("YYYY-MM-DD")}
                                </strong>
                              </span>
                            </>
                          )}
                          {latestCounterOffer &&
                            latestCounterOffer?.closingDate && (
                              <>
                                <br />
                                <span
                                  onClick={this.toggleModal}
                                  className={`${`counter-offer-table-text-${
                                    new Date(latestCounterOffer?.closingDate) >=
                                      new Date(closingDate) || greenColor
                                      ? `green`
                                      : `red`
                                  }`}`}
                                >
                                  {latestCounterOffer?.closingDate}
                                </span>
                              </>
                            )}
                        </td>
                      </tr>
                      {!!leasebackDays && <tr>
                        <td className="bold-td">Leaseback Days</td>
                        <td className="normal-td">
                          {leasebackDays} days
                          {latestCounterOffer &&
                            latestCounterOffer?.leasebackDays && (
                              <>
                                <br />
                                <span
                                  onClick={this.toggleModal}
                                  className={`${`counter-offer-table-text-${
                                    Number(latestCounterOffer?.leasebackDays) >=
                                      Number(leasebackDays) || greenColor
                                      ? `green`
                                      : `red`
                                  }`}`}
                                >
                                  {latestCounterOffer?.leasebackDays} days
                                  {/^\d+$/.test(
                                    latestCounterOffer?.leasebackDays
                                  ) && (
                                    <i
                                      className={`fa fa-arrow-${
                                        Number(
                                          latestCounterOffer?.leasebackDays
                                        ) >= Number(leasebackDays)
                                          ? `up`
                                          : `down`
                                      } ml-2`}
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </span>
                              </>
                            )}
                        </td>
                      </tr>}
                      {!!possession && <tr>
                        <td className="bold-td">Possession</td>
                        <td className="normal-td">
                          {possession}
                          {latestCounterOffer &&
                            latestCounterOffer?.possession && (
                              <>
                                <br />
                                <span
                                  onClick={this.toggleModal}
                                  className={`${`counter-offer-table-text-${
                                    latestCounterOffer?.possession ===
                                      possession || greenColor
                                      ? `green`
                                      : `red`
                                  }`}`}
                                >
                                  {latestCounterOffer?.possession}
                                </span>
                              </>
                            )}
                        </td>
                      </tr>}
                      {!!optionFee && <tr>
                        <td className="bold-td">Option Fee and Option Days</td>
                        <td className="normal-td">
                          <NumberFormat
                            value={optionFee}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            renderText={(value, props) => <>{value}</>}
                          />
                          , {optionDays} days
                          <br />
                          {latestCounterOffer &&
                            latestCounterOffer?.optionFee && (
                              <>
                                <span
                                  onClick={this.toggleModal}
                                  className={`${`counter-offer-table-text-${
                                    Number(latestCounterOffer?.optionFee) >=
                                      Number(optionFee) || greenColor
                                      ? `green`
                                      : `red`
                                  }`}`}
                                >
                                  {this.formatNumber(
                                    latestCounterOffer?.optionFee
                                  )}
                                  ,{" "}
                                  {/* {/^\d+$/.test(
                                    latestCounterOffer?.optionFee
                                  ) && (
                                    <i
                                      className={`fa fa-arrow-${
                                        Number(latestCounterOffer?.optionFee) >=
                                        Number(optionFee)
                                          ? `up`
                                          : `down`
                                      } ml-2`}
                                      aria-hidden="true"
                                    ></i>
                                  )} */}
                                </span>
                              </>
                            )}
                          {latestCounterOffer &&
                            latestCounterOffer?.optionDays && (
                              <>
                                <span
                                  onClick={this.toggleModal}
                                  className={`${`counter-offer-table-text-${
                                    Number(latestCounterOffer?.optionDays) >=
                                      Number(optionDays) || greenColor
                                      ? `green`
                                      : `red`
                                  }`}`}
                                >
                                  {latestCounterOffer?.optionDays} days
                                  {/^\d+$/.test(
                                    latestCounterOffer?.optionDays
                                  ) && (
                                    <i
                                      className={`fa fa-arrow-${
                                        Number(latestCounterOffer?.optionFee) >=
                                        Number(optionFee)
                                          ? `up`
                                          : `down`
                                      } ml-2`}
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </span>
                              </>
                            )}
                        </td>
                      </tr>}
                      {!!nonRealtyItem && <tr>
                        <td className="bold-td">Non Realty Item</td>
                        <td className="normal-td">
                          {nonRealtyItem}
                          {latestCounterOffer &&
                            latestCounterOffer?.nonRealtyItem && (
                              <>
                                <br />
                                <span
                                  onClick={this.toggleModal}
                                  className={`${`counter-offer-table-text-${
                                    latestCounterOffer?.nonRealtyItem ===
                                      nonRealtyItem || greenColor
                                      ? `green`
                                      : `red`
                                  }`}`}
                                >
                                  {latestCounterOffer?.nonRealtyItem}
                                </span>
                              </>
                            )}
                        </td>
                      </tr>}
                      {!!saleOfOtherProperty && <tr>
                        <td className="bold-td">Sale of Other Property</td>
                        <td className="normal-td">
                          {saleOfOtherProperty}
                          {latestCounterOffer &&
                            latestCounterOffer?.saleOfOtherProperty && (
                              <>
                                <br />
                                <span
                                  onClick={this.toggleModal}
                                  className={`${`counter-offer-table-text-${
                                    latestCounterOffer?.saleOfOtherProperty ===
                                      saleOfOtherProperty || greenColor
                                      ? `green`
                                      : `red`
                                  }`}`}
                                >
                                  {latestCounterOffer?.saleOfOtherProperty}
                                </span>
                              </>
                            )}
                        </td>
                      </tr>}
                      {!!rightToTerminate && <tr>
                        <td className="bold-td">
                          Right To Terminate Due to Lender’s Appraisal
                        </td>
                        <td className="normal-td">
                          {rightToTerminate}
                          {latestCounterOffer &&
                            latestCounterOffer?.rightToTerminate && (
                              <>
                                <br />
                                <span
                                  onClick={this.toggleModal}
                                  className={`${`counter-offer-table-text-${
                                    latestCounterOffer?.rightToTerminate ===
                                      rightToTerminate || greenColor
                                      ? `green`
                                      : `red`
                                  }`}`}
                                >
                                  {latestCounterOffer?.rightToTerminate}
                                </span>
                              </>
                            )}
                        </td>
                      </tr>}
                    </tbody>
                  </Table>
                </div>
                <div className="col-md-6"></div>
              </div>
              {/* </div> */}
              {/* {this.renderOfferDetails()} */}
            </div>
          </div>

          <div className="container">
            <div className="row offer-package">
              <div className="col-xl-6 col-lg-6 col-sm-12 p-0">
                <h5 className="offersMian">Offer Package</h5>
                {updatedDocs.length &&
                  updatedDocs
                    .filter(
                      (doc) =>
                        !archivedDocs?.map((doc) => doc.name).includes(doc.name)
                    )
                    .map((doc, key) => (
                      <FormGroup
                        className="download-details display-flex"
                        key={key}
                      >
                        <div>
                          {isEditDocName.status &&
                          isEditDocName.doc.name === doc.name ? (
                            <>
                              <input
                                type="text"
                                className="mr-3 name-change-input"
                                value={doc.name}
                                name={doc.name}
                                id={doc.name}
                                onChange={(e) => {
                                  this.handleDocNameChange(e, doc);
                                }}
                              />
                            </>
                          ) : (
                            <Label className="mb2">
                              {doc.name?.replace(/^[\d_]*/, "")}
                            </Label>
                          )}
                          <p className="doc-timestamp">
                            {doc.timestamp
                              ? moment(doc.timestamp).format(
                                  "DD MMM YYYY, h:mm a"
                                )
                              : moment(offer.createdAt * 1000).format(
                                  "DD MMM YYYY, h:mm a"
                                )}
                          </p>
                        </div>
                        {isEditDocName.status &&
                        isEditDocName.doc.name === doc.name ? (
                          <a>
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => {
                                const db = firebase.firestore();
                                db.collection("offers")
                                  .doc(this.state.offer.id)
                                  .update({
                                    documents: updatedDocs,
                                  });
                                this.setState({
                                  isEditDocName: { status: false, doc: {} },
                                });
                              }}
                            ></i>
                          </a>
                        ) : (
                          <>
                            <a
                              href={doc?.path}
                              color="link"
                              target="_blank"
                              download={doc?.name}
                            >
                              <img src={Download} alt="download" />
                            </a>
                            <a
                              onClick={() => this.archiveDoc(doc)}
                              // href={doc?.path}
                              color="link"
                              // target="_blank"
                              // download={doc?.name}
                              className="ml-2"
                            >
                              <img src={Archive} alt="archive" />
                            </a>
                            {offer.seller_id === user.uid && (
                              <a
                                className="ml-2"
                                onClick={() => {
                                  this.setState({
                                    isEditDocName: {
                                      status: true,
                                      doc,
                                    },
                                  });
                                }}
                              >
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            )}
                          </>
                        )}
                      </FormGroup>
                    ))}
                {executeOfferData &&
                  executeOfferData.documents
                    .filter(
                      (doc) =>
                        !archivedDocs?.map((doc) => doc.name).includes(doc.name)
                    )
                    .map((doc, key) => (
                      <FormGroup className="download-details" key={key}>
                        {isEditDocName.status &&
                        isEditDocName.doc.name === doc.name ? (
                          <>
                            <input
                              type="text"
                              className="mr-3"
                              value={doc.name}
                              name={doc.name}
                              id={doc.name}
                              onChange={(e) => {
                                this.handleDocNameChange(e, doc);
                              }}
                            />
                          </>
                        ) : (
                          <Label>{`Execution Document ${key + 1}`}</Label>
                        )}
                        {isEditDocName.status &&
                        isEditDocName.doc.name === doc.name ? (
                          <a>
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => {
                                const db = firebase.firestore();
                                db.collection("offers")
                                  .doc(this.state.offer.id)
                                  .update({
                                    documents: updatedDocs,
                                  });
                                this.setState({
                                  isEditDocName: { status: false, doc: {} },
                                });
                              }}
                            ></i>
                          </a>
                        ) : (
                          <>
                            <a
                              href={doc}
                              color="link"
                              target="_blank"
                              download={doc}
                            >
                              <img src={Download} alt="download" />
                            </a>
                            <a
                              onClick={() => this.archiveDoc(doc)}
                              // href={doc?.path}
                              color="link"
                              // target="_blank"
                              // download={doc?.name}
                              className="ml-2"
                            >
                              <img src={Archive} alt="archive" />
                            </a>
                            {/* <a
                            className="ml-2"
                            onClick={() => {
                              this.setState({
                                isEditDocName: {
                                  status: true,
                                  doc,
                                },
                              });
                            }}
                          >
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                          </a> */}
                          </>
                        )}
                      </FormGroup>
                    ))}
                <FormGroup>
                  <label
                    htmlFor={isUploading ? "" : "file-picker"}
                    className="upload-btn-wrapper"
                  >
                    <div className="upload-btn">
                      {isUploading ? "Uploading..." : "Add Documents"}
                    </div>
                    <input
                      type="file"
                      multiple
                      id="file-picker"
                      onChange={this.uploadDocument}
                      name="myfile"
                    />
                  </label>

                  <label
                    className="ml-2 upload-btn-wrapper"
                    onClick={this.downloadDocs}
                  >
                    <div className="upload-btn">Download All Documents</div>
                  </label>
                </FormGroup>
              </div>

              {(this.state.offerAudio || this.state.offerVideo) && (
                <div className="col-xl-6 col-lg-6 col-sm-12">
                  <h5 className="offersMian">Media</h5>
                  {this.state.offerVideo && (
                    <video
                      className="preview-video-frame"
                      ref={this.videoRef}
                      controls
                    >
                      <source src={this.state.offerVideo} type="video/mp4" />
                    </video>
                  )}

                  {this.state.offerAudio && (
                    <>
                      {isSafari ? (
                        <audio controls>
                          <source
                            src={this.state.offerAudio}
                            type="audio/ogg"
                          />
                          <source
                            src={this.state.offerAudio}
                            type="audio/mpeg"
                          />
                        </audio>
                      ) : (
                        <audio controls src={this.state.offerAudio} />
                      )}
                    </>
                  )}
                </div>
              )}

              {archivedDocs?.filter((doc) => doc.offerId === offerId).length >
                0 && (
                <div className="col-xl-12 col-lg-12 col-sm-12 my-4">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({ showArchived: !this.state.showArchived });
                    }}
                  >
                    <span style={{ color: "red", fontWeight: "600" }}>
                      {showArchived ? "Hide" : "Show"} All Archived Documents
                    </span>
                    <img
                      src={DropDownArrowRed}
                      className="ml-2"
                      style={{
                        transform: showArchived
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                  </span>

                  {showArchived &&
                    updatedDocs
                      .filter((doc) =>
                        archivedDocs?.map((doc) => doc.name).includes(doc.name)
                      )
                      .map((doc, key) => (
                        <FormGroup
                          className="download-details display-flex"
                          key={key}
                        >
                          <div>
                            <Label className="mb2">
                              {doc.name?.replace(/^[\d_]*/, "")}
                            </Label>
                            <p className="doc-timestamp">
                              {doc.timestamp
                                ? moment(doc.timestamp).format(
                                    "DD MMM YYYY, h:mm a"
                                  )
                                : moment(offer.createdAt * 1000).format(
                                    "DD MMM YYYY, h:mm a"
                                  )}
                            </p>
                          </div>
                          <a
                            onClick={() => this.unArchiveDoc(doc)}
                            // href={doc?.path}
                            color="link"
                            // target="_blank"
                            // download={doc?.name}
                            className="ml-2"
                          >
                            {/* <img src={Archive} alt="archive" /> */}
                            <i class="fa fa-undo" aria-hidden="true"></i>
                          </a>
                        </FormGroup>
                      ))}
                  {showArchived &&
                    executeOfferData?.documents
                      .filter((doc, key) =>
                        archivedDocs?.map((doc) => doc.name).includes(doc.name)
                      )
                      .map((doc, key) => (
                        <FormGroup
                          className="download-details display-flex"
                          key={key}
                        >
                          <div>
                            <Label>{`Execution Document ${key + 1}`}</Label>
                            <p className="doc-timestamp">
                              {doc.timestamp
                                ? moment(doc.timestamp).format(
                                    "DD MMM YYYY, h:mm a"
                                  )
                                : moment(offer.createdAt * 1000).format(
                                    "DD MMM YYYY, h:mm a"
                                  )}
                            </p>
                          </div>
                          <a
                            onClick={() => this.unArchiveDoc(doc)}
                            // href={doc?.path}
                            color="link"
                            // target="_blank"
                            // download={doc?.name}
                            className="ml-2"
                          >
                            {/* <img src={Archive} alt="archive" /> */}
                            <i class="fa fa-undo" aria-hidden="true"></i>
                          </a>
                        </FormGroup>
                      ))}
                </div>
              )}
            </div>
          </div>

          {/* {user?.uid === offer?.seller_id && ( */}
          <div className="container">
            <div className="row offer-package">
              <Tabs
                defaultActiveKey="comments"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                {/* <h5 className="offersMian">Comments</h5> */}
                <Tab eventKey="comments" title="Comments">
                  <div className="offer-comment-setion">
                    {/* <div className="d-inline-block">
                      <select
                        name="offerCommentsFilter"
                        value={offerStatusFilter}
                        onChange={e => this.filterByStatus(e.target.value)}
                        className="search-fields offers-search-fields mr-0 mr-md-4 sellers-status-filter">
                        <option value="">Select status</option>
                        {
                          !!statusFilterOptions.length && statusFilterOptions.map((m, i) => (
                          <option key={i} value={m}>{m}</option>
                          ))
                        }
                      </select>
                    </div> */}
                    <OfferComments
                      offer={offer}
                      offerId={offerId}
                      name={displayName}
                      user={this.props.user}
                      match={this.props.match}
                      offerComment={comments}
                      counterOffers={counterOffers}
                    />
                    {/* <OfferComments
                      offer={offer}
                      offerId={offerId}
                      name={displayName}
                      user={this.props.user}
                      match={this.props.match}
                      offerComment={comments}
                    >
                      {counterOffers?.map(
                        (c, i) =>
                          c.offerData.comments && (
                            <li key={i}>
                              <div className="comment-div d-flex">
                                <div className="profile-img-user">
                                  <a>
                                    <img
                                      src="/images/face-placeholder.png"
                                      alt=""
                                    />
                                  </a>
                                </div>
                                <div className="comment-user-name-dark">
                                  <h5
                                  // style={
                                  //   c.name === name ? { color: "#ff214f" } : null
                                  // }
                                  >
                                    {c.sentBy} -{" "}
                                    <TimeAgo date={c.sentAt * 1000} />{" "}
                                    <span className="text-red">
                                      (Counter Comment)
                                    </span>
                                  </h5>
                                  <p>{c.offerData.comments}</p>
                                </div>
                              </div>
                            </li>
                          )
                      )}
                    </OfferComments> */}
                    <OfferSubmitComment
                      sellerData={this.state.seller}
                      offer={offer}
                      offerId={offerId}
                      name={displayName}
                      user={this.props.user}
                      match={this.props.match}
                    />
                  </div>
                </Tab>
                <Tab
                  eventKey="emails"
                  title="Emails"
                  className="col-lg-12 pl-0"
                >
                  {/* <div className="col-xl-12 col-lg-12"> */}
                  {/* Tabs */}
                  {/* <button className="upload-btn-active">
                Comments
                <i className="fa fa-comment ml-2" aria-hidden="true"></i>
              </button>
              <button className="upload-btn ml-2">
                Emails <i class="fa fa-envelope ml-2" aria-hidden="true"></i>
              </button> */}
                  {/* <div className="container p-0 mt-5"> */}

                  {!!offer && <Emails user={this.props.user} offer={offer} />}
                  {/* </div> */}
                </Tab>
                {/* </div> */}
              </Tabs>
            </div>
          </div>
          {/* )} */}
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <h5 className="history-of-changes">History of changes</h5>
                <div className="history-changes">
                  {updateHistory?.length ? (
                    updateHistory.map((m, key) => <p key={key}>{m}</p>)
                  ) : (
                    <p>The offer has no history yet.</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row m-57">
              <div className="col-lg-12">
                <p className="review-text">
                  Please review all information carefully before consideration.
                  If you have any question, please contact your agent or
                  Homematchx Offer Advisor
                </p>
              </div>
              <div className="col-lg-12 row space-between btns-mobile-center m-lr-0">
                <Link to="/offers">
                  <button className="back-btn">
                    <img
                      src={BackArrow}
                      className="backArrow"
                      alt="back arrow"
                    />
                    Back
                  </button>
                </Link>
                {isBuyer ? (
                  <div>
                    <button
                      className="green-btn no-margin"
                      onClick={() =>
                        history.push(`/makeOffer/${seller}?offer=${offerId}`)
                      }
                    >
                      <img src={Tick} className="backArrow" alt="tick" />
                      Resubmit offer
                    </button>
                  </div>
                ) : (
                  <div>
                    <ConfirmationModal
                      showCheckbox
                      modalColor="success"
                      title="Confirmation"
                      message={`Are you sure you want to ${
                        acceptStatus.split(" ")[0]
                      } this offer?`}
                      action={(declineOthers) =>
                        this.updateOfferStatus(
                          status === "In Review" ? "Accepted" : "In Review",
                          acceptStatus.split(" ")[0],
                          declineOthers
                        )
                      }
                      checkLabel={`Do you want to ${
                        status === "In Review" ? "decline" : "un-decline"
                      } other offers of this property`}
                    >
                      <button
                        className="green-btn"
                        disabled={
                          status === "Not Accepted" || isOtherOfferAccepted
                        }
                      >
                        <img src={Tick} className="backArrow" alt="tick" />
                        {acceptStatus}
                      </button>
                    </ConfirmationModal>
                    <ConfirmationModal
                      modalColor="danger"
                      title="Confirmation"
                      message={`Are you sure you want to ${
                        declineStatus.split(" ")[0]
                      } this offer?`}
                      action={() =>
                        this.updateOfferStatus(
                          status === "In Review" ? "Not Accepted" : "In Review",
                          declineStatus.split(" ")[0]
                        )
                      }
                    >
                      <button
                        className="red-btn"
                        disabled={status === "Accepted"}
                      >
                        <img
                          src={Cross}
                          className="backArrow"
                          alt="back arrow"
                        />
                        {declineStatus}
                      </button>
                    </ConfirmationModal>
                  </div>
                )}
                {isOtherOfferAccepted && (
                  <div className="d-flex flex-row justify-content-end w-100 mt-2">
                    <p>
                      Another offer is already accepted for this property,
                      Please un-accept it first.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="all-offers-filter-section"></div>

          {(globalOfferDataAccess || offer.sellerEmail === user.email) &&
            (this.state.seller?.MlsStatus === "Sold" ||
              user?.uid === offer?.seller_id) && (
              <div className="container">
                <div
                  className="table-responsive all-offers other-agent-all-offers"
                  style={{ boxShadow: "none" }}
                >
                  <h5 className="offersMian">Other Agents Offers</h5>
                  <div className="other-agent-all-offers-table">
                    <table className="table table-customized">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Agent Name</th>
                          <th>Purchase Price</th>
                          <th>Verified</th>
                          <th>Status</th>
                          <th>Finance Type</th>
                          <th>Possession</th>
                          <th>Closing Date</th>
                          <th className="width-200 text-center">
                            Actions{" "}
                            {offersToShare.length ? (
                              <Button
                                className="btn btn-theme btn btn-secondary share-btn-dashboard d-inline ml-2"
                                onClick={() =>
                                  this.setState({ shareOffersModel: true })
                                }
                              >
                                Share
                              </Button>
                            ) : (
                              <></>
                            )}
                          </th>
                        </tr>
                      </thead>
                      {allOffers.filter((o) => o.id !== offer.id).length ? (
                        <tbody>
                          {allOffers &&
                            allOffers
                              .filter(
                                (o) =>
                                  o.id !== offer.id && o.status !== "Deleted"
                              )
                              .map((offer, key) => {
                                return (
                                  <>
                                    <tr
                                      key={key}
                                      className="all-offers-table-row analytics-find-buyer-table"
                                    >
                                      <td>
                                        <div className="custom-control custom-checkbox ml-6">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={offer.id}
                                            onChange={(e) => {
                                              if (e.target.checked)
                                                this.setState({
                                                  offersToShare: [
                                                    ...offersToShare,
                                                    offer,
                                                  ],
                                                });
                                              else
                                                this.setState({
                                                  offersToShare:
                                                    offersToShare.filter(
                                                      (o) => o.id !== offer.id
                                                    ),
                                                });
                                            }}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={offer.id}
                                          ></label>
                                        </div>
                                      </td>
                                      <AgentInformationModal
                                        key={offer.id}
                                        offer={offer}
                                      />
                                      <td>
                                        {offer.purchasePrice
                                          ? "$" +
                                            offer.purchasePrice
                                              ?.toString()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {offer.verified && (
                                          <img src={VerifiedListing} />
                                        )}
                                      </td>
                                      <td>
                                        {offer.status === "In Review" ? (
                                          <span className="mr-2 badge review-badge">
                                            <i className="fa fa-repeat"></i>In
                                            Review
                                          </span>
                                        ) : offer.status === "Seller Review" ? (
                                          <span className="mr-2 badge seller-review-badge">
                                            <i className="fa fa-repeat"></i>
                                            Seller Review
                                          </span>
                                        ) : offer.status === "Countered" ? (
                                          <span className="mr-2 badge executed-badge status-badge">
                                            <i className="fa fa-repeat"></i>
                                            Countered
                                          </span>
                                        ) : offer.status === "Not Accepted" ? (
                                          <span className="mr-2 badge not-accepted-badge">
                                            <i className="fa fa-times"></i>Not
                                            Accepted
                                          </span>
                                        ) : offer.status === "Withdraw" ? (
                                          <span className="mr-2 badge badge-black status-badge">
                                            <i className="fa fa-check"></i>
                                            Withdrawn
                                          </span>
                                        ) : offer.status === "Terminated" ? (
                                          <span className="mr-2 badge badge-black status-badge">
                                            <i className="fa fa-check"></i>
                                            Terminated
                                          </span>
                                        ) : offer.status === "Not Accepted" ? (
                                          <span className="mr-2 badge not-accepted-badge status-badge">
                                            <i className="fa fa-times"></i>Not
                                            Accepted
                                          </span>
                                        ) : offer.status === "Executed" ? (
                                          <>
                                            <span className="mr-2 badge executed-badge status-badge">
                                              <i className="fa fa-check"></i>
                                              Accepted
                                            </span>
                                            <span className="mr-2 badge executed-badge status-badge">
                                              <i className="fa fa-check"></i>
                                              Executed
                                            </span>
                                          </>
                                        ) : (
                                          <span className="mr-2 badge badge-success status-badge">
                                            <i className="fa fa-check"></i>
                                            Accepted
                                          </span>
                                        )}
                                        <span className="mr-2 badge badge-success status-badge">
                                          <i className="fa fa-check"></i>
                                          {offer?.sellerEmail === user.email
                                            ? "Received"
                                            : "Sent"}
                                        </span>
                                      </td>
                                      <td className="address-content-width">
                                        <p className="table-normal-text">
                                          {offer.financingType}
                                        </p>
                                      </td>
                                      <td>{offer.possession}</td>
                                      <td>{offer.closingDate}</td>
                                      <td className="text-center">
                                        <Button
                                          onClick={() =>
                                            history.push(
                                              `/otherAgent/offer/${offer.id}`
                                            )
                                          }
                                          type="button"
                                          className="btn btn-theme btn btn-secondary other-agent-offers-btn"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          View Offer
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                        </tbody>
                      ) : (
                        <td colSpan="7" className="text-center">
                          No other agent offers
                        </td>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            )}

          <div className="container">
            <div className="row mb-457 mt-5">
              <div className="col-lg-12 p-0">
                <div className="p-lr-30 bg-clr-white">
                  <h5 className="summaries">Offer Summary Definition</h5>
                  <div>
                    <div id="toggler1">
                      {this.state.isOpened1 ? (
                        <div className="accordian">
                          <p>What is Purchase Price?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Purchase Price?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler1"
                      onEntered={() =>
                        this.setState({
                          isOpened1: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened1: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            This is the price offered for your home by the
                            buyer.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler2">
                      {this.state.isOpened2 ? (
                        <div className="accordian">
                          <p>What is Finance Type?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Finance Type?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler2"
                      onEntered={() =>
                        this.setState({
                          isOpened2: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened2: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Finance Type is the type of finance the buyer is
                            using to purchase the home. Finance Type can range
                            from Cash, Conventional, FHA, VA, USDA, and
                            sometimes Seller Finance.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler3">
                      {this.state.isOpened3 ? (
                        <div className="accordian">
                          <p>What is Down Payment?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Down Payment?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler3"
                      onEntered={() =>
                        this.setState({
                          isOpened3: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened3: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Down Payment is the amount the buyer is putting down
                            on the home as their initial up-front partial
                            payment to purchase. This is paid at the time of
                            finalizing the transaction.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler4">
                      {this.state.isOpened4 ? (
                        <div className="accordian">
                          <p>What is a Seller Concession?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is a Seller Concession?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler4"
                      onEntered={() =>
                        this.setState({
                          isOpened4: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened4: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Seller Concessions, also known as Seller
                            Contributions, is an amount the buyer is requesting
                            the seller contribute towards their transaction. The
                            buyer is required to pay for certain home financing
                            costs but anagreement can be made between the buyer
                            and seller where the seller pays for those e cost on
                            behalf ofthe buyer.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler5">
                      {this.state.isOpened5 ? (
                        <div className="accordian">
                          <p>What is a Earnest Money Deposit?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is a Earnest Money Deposit?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler5"
                      onEntered={() =>
                        this.setState({
                          isOpened5: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened5: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Earnest Money Deposit is a good-faith deposit into
                            an escrow account with the title company. It is
                            aspecific form of security deposit made as part of
                            entering into a real estate transaction. This amount
                            implies that the buyer is serious about purchasing
                            and is willing to demonstrate an amount as their
                            goodfaith to complete transaction. This amount can
                            be 1% - 5% of the agreed upon purchase price.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler6">
                      {this.state.isOpened6 ? (
                        <div className="accordian">
                          <p>What is Title Policy?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Title Policy?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler6"
                      onEntered={() =>
                        this.setState({
                          isOpened6: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened6: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            The title policy ensures that the property isn’t
                            encumbered by past liens or other problems. This
                            expenseis customarily paid by the buyer or seller
                            based on the state of where the transaction is taken
                            place. Theamount varies widely from state to state
                            and depends on the price of the home.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler7">
                      {this.state.isOpened7 ? (
                        <div className="accordian">
                          <p>What is a Survey?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is a Survey?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler7"
                      onEntered={() =>
                        this.setState({
                          isOpened7: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened7: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            The survey expense is the cost to hire a land
                            surveyor. The survey effectively gives you a legal
                            description of the exact boundaries of where your
                            property line begins and end. This cost can range
                            from $350 to $700.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler8">
                      {this.state.isOpened8 ? (
                        <div className="accordian">
                          <p>What is a Residential Service Contract?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is a Residential Service Contract?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler8"
                      onEntered={() =>
                        this.setState({
                          isOpened8: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened8: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            A Residential service contract, also known as Home
                            Warranty, covers the repair or replace of major
                            mechanical systems and appliances in the home. These
                            systems include heating, electrical, and
                            plumbing.The cost varies based on the type of
                            service coverage plan.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler9">
                      {this.state.isOpened9 ? (
                        <div className="accordian">
                          <p>What is Closing Date?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Closing Date?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler9"
                      onEntered={() =>
                        this.setState({
                          isOpened9: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened9: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            When it comes to selecting a closing date, you want
                            to be as comfortable as possible. Closing date
                            canvary based on your situation or need. If you are
                            purchasing a new construction your closing date may
                            be extended to accommodate the transaction the
                            closing. Choosing you own closing just might be the
                            control you need. You can even stay in your home
                            after closing with extended stay option. Be sure to
                            ask all the questions to make your process as
                            comfortable as possible.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler10">
                      {this.state.isOpened10 ? (
                        <div className="accordian">
                          <p>What is Leaseback Days?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Leaseback Days?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler10"
                      onEntered={() =>
                        this.setState({
                          isOpened10: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened10: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Leaseback allows a seller to stay in the house after
                            closing for an agreed upon period of time. The
                            seller becomes the tenant and the buyers becomes the
                            landlord. A leaseback can be negotiated at no cost
                            to thetenant up to the daily amount of the buyer’s
                            new monthly mortgage payment. There is a temporary
                            residential lease agreement that is signed, legally
                            binding, and enforced.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler11">
                      {this.state.isOpened11 ? (
                        <div className="accordian">
                          <p>About Possession</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>About Possession</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler11"
                      onEntered={() =>
                        this.setState({
                          isOpened11: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened11: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Possession can be taken by the buyer upon closing
                            and funding or after the temporary lease agreement.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler12">
                      {this.state.isOpened12 ? (
                        <div className="accordian">
                          <p>About Option Fee and Option Days</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>About Option Fee and Option Days</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler12"
                      onEntered={() =>
                        this.setState({
                          isOpened12: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened12: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            The Option Fee (paid to seller) and Option Day, also
                            known as Option Period, gives the buyer a time
                            period to do their inspections on the home. During
                            this process repairs will typically be asked for
                            items that may materially affect the purchase or
                            sell of the home. Items such as the roof, HVAC,
                            foundation,flooring, electrical, plumbing and
                            appliance could be requested for repair. If any
                            repairs are required youwill receive a copy of the
                            inspection report and a list of repairs. This option
                            period gives the buyer the unrestricted right to
                            terminate the contract if both parties cannot agree
                            on repair terms. Having this right allows the buyer
                            to retain their Earnest Money Deposit if the
                            contract is terminated.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler13">
                      {this.state.isOpened13 ? (
                        <div className="accordian">
                          <p>What is Credit Approval?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Credit Approval?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler13"
                      onEntered={() =>
                        this.setState({
                          isOpened13: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened13: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            If the Buyer cannot obtain Buyer Approval, Buyer may
                            give written notice to Seller within a specific
                            number of day after the effective date of the
                            contract and this contract will terminate and the
                            earnest money will be refunded to the Buyer. If the
                            Buyer does not terminate the contract under this
                            provision,the contract shall no longer be subject to
                            the Buyer obtaining Buyer Approval.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler14">
                      {this.state.isOpened14 ? (
                        <div className="accordian">
                          <p>What is Non-Realty Item?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Non-Realty Item?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler14"
                      onEntered={() =>
                        this.setState({
                          isOpened14: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened14: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Non-Realty Item is used if the parties need to add
                            additional items of personal property that are to
                            staywith the property. Items such refrigerator,
                            washer and dryer are transferred using a Non-Realty
                            Item addendum.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler15">
                      {this.state.isOpened15 ? (
                        <div className="accordian">
                          <p>What is Sale of Other Property?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What is Sale of Other Property?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler15"
                      onEntered={() =>
                        this.setState({
                          isOpened15: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened15: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            A Sale of Other Property addendum is used if the
                            Buyer will be unable to buy the new property unless
                            their existing property is sold and closed.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler16">
                      {this.state.isOpened16 ? (
                        <div className="accordian">
                          <p>What are Commission?</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian-opened">
                          <p>What are Commission?</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler16"
                      onEntered={() =>
                        this.setState({
                          isOpened16: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened16: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Real estate commission are negotiated and variable
                            amount based on sales price. The commission is made
                            directly to the real estate brokers for services
                            rendered in the sale or purchase of real property.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <div id="toggler17">
                      {this.state.isOpened17 ? (
                        <div className="accordian">
                          <p>Estimated Net Offer</p>
                          <img src={Minus} />
                        </div>
                      ) : (
                        <div className="accordian">
                          <p>Estimated Net Offer</p>
                          <img src={Plus} />
                        </div>
                      )}
                    </div>
                    <UncontrolledCollapse
                      toggler="#toggler17"
                      onEntered={() =>
                        this.setState({
                          isOpened17: true,
                        })
                      }
                      onExiting={() =>
                        this.setState({
                          isOpened17: false,
                        })
                      }
                    >
                      <Card>
                        <CardBody>
                          <p className="accordian-p">
                            Other Fees to the transaction may include title
                            policy, escrow, estimated taxes, etc. Have
                            questions, need clarification, or would like a
                            seller net sheet for an itemized breakdown of fees
                            click here.
                          </p>
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal isOpen={counterOfferModal} toggle={this.toggleModal} size="lg">
            <ModalHeader toggle={this.toggleModal}>Counter Offer</ModalHeader>
            <ModalBody>
              <table className="table table-responsive table-stripped">
                <thead>
                  <tr>
                    <th></th>
                    <th>Original Offer</th>
                    {counterOffers?.map((offer, i) => (
                      <th key={i}>
                        Offer By{" "}
                        {offer.sentById === this.props.user.uid
                          ? "Me"
                          : offer.sentBy}
                      </th>
                    ))}
                    {
                      // counterOffers?.slice(-1)[0].sentById !==
                      //   this.props.user.uid &&
                      !(
                        (!counterOffers || counterOffers?.length === 0) &&
                        buyer === user.uid
                      ) && <th>Counter Offer</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {counterData.map((data, i) => {
                    let inputField = (
                      <Input
                        name={data.key}
                        onChange={(e) =>
                          this.updateCounterOffer(data.key, e.target.value)
                        }
                      />
                    );
                    if (data.key === "comments")
                      inputField = (
                        <Input
                          type="textarea"
                          name={data.key}
                          onChange={(e) =>
                            this.updateCounterOffer(data.key, e.target.value)
                          }
                        />
                      );
                    if (data.key === "financingType")
                      inputField = (
                        <Input
                          type="select"
                          name={data.key}
                          onChange={(e) =>
                            this.updateCounterOffer(data.key, e.target.value)
                          }
                        >
                          <option value="Select type">Select type</option>
                          <option value="Cash">Cash</option>
                          <option value="Lease">Lease</option>
                          <option value="Conventional">Conventional</option>
                          <option value="FHA">FHA</option>
                          <option value="VA">VA</option>
                          <option value="Seller FinanceVA">
                            Seller Finance
                          </option>
                        </Input>
                      );

                    if (data.key === "possession")
                      inputField = (
                        <Input
                          type="select"
                          name={data.key}
                          onChange={(e) =>
                            this.updateCounterOffer(data.key, e.target.value)
                          }
                        >
                          <option value="Select type">Select type</option>
                          <option value="Upon Close & Fund">
                            Upon Close &amp; Fund
                          </option>
                          <option value="Leaseback">Leaseback</option>
                        </Input>
                      );

                    if (data.key === "saleOfOtherProperty")
                      inputField = (
                        <Input
                          type="select"
                          name={data.key}
                          onChange={(e) =>
                            this.updateCounterOffer(data.key, e.target.value)
                          }
                        >
                          <option value="Select type">Select type</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Input>
                      );

                    if (data.key === "rightToTerminate")
                      inputField = (
                        <Input
                          type="select"
                          name={data.key}
                          onChange={(e) =>
                            this.updateCounterOffer(data.key, e.target.value)
                          }
                        >
                          <option value="Select type">Select type</option>
                          <option value="Full Waiver">Full Waiver</option>
                          <option value="Partial Waiver">Partial Waiver</option>
                          <option value="Additional Right To Terminate">
                            Additional Right To Terminate
                          </option>
                          <option value="No Waiver">No Waiver</option>
                        </Input>
                      );

                    if (data.key === "closingDate")
                      inputField = (
                        <Input
                          type="date"
                          name={data.key}
                          onChange={(e) =>
                            this.updateCounterOffer(data.key, e.target.value)
                          }
                        />
                      );

                    return (
                      <tr key={i}>
                        <td>{data.name}</td>
                        {data.values.map((v, i) => (
                          <td key={i}>{v || "N/A"}</td>
                        ))}
                        {
                          // counterOffers?.slice(-1)[0].sentById !==   this.props.user.uid &&
                          !(
                            (!counterOffers || counterOffers?.length === 0) &&
                            buyer === user.uid
                          ) && <td>{inputField}</td>
                        }
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </ModalBody>
            <ModalFooter>
              {/* {counterOffers?.slice(-1)[0].sentById === this.props.user.uid && <p className="text-left">You cannot send counter offer twice or before receiving a ounter from buyer.</p>} */}
              {(!counterOffers || counterOffers?.length === 0) &&
                buyer === user.uid && (
                  <p className="text-left">
                    You cannot send counter offer before receiving a counter
                    from seller.
                  </p>
                )}
              {isOfferAccepted && (
                <p className="text-left text-success">Offer accepted.</p>
              )}
              <ConfirmationModal
                modalColor="success"
                title="Confirmation"
                message={`Are you sure you want to accept current counter offer?`}
                action={() => this.acceptMyCounterOffer()}
              >
                <Button
                  color="success"
                  disabled={
                    status === "Executed" ||
                    status === "Not Accepted" ||
                    isOfferAccepted
                  }
                >
                  Accept Counter Offer
                </Button>
              </ConfirmationModal>{" "}
              <Button
                color="primary"
                disabled={
                  status === "Executed" ||
                  status === "Not Accepted" ||
                  isOfferAccepted
                  // || counterOffers?.slice(-1)[0].sentById === this.props.user.uid
                }
                onClick={(e) => {
                  this.toggleModal();
                  this.saveCounterOffer();
                }}
              >
                Submit Counter Offer
              </Button>{" "}
              <Button color="secondary" onClick={this.toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={executionModal}
            toggle={this.toggleExecutionModal}
            size="lg"
          >
            <ModalHeader toggle={this.toggleExecutionModal}>
              Execute Offer
            </ModalHeader>
            <ModalBody>
              <table className="table table-responsive table-stripped">
                <thead>
                  <tr>
                    <th></th>
                    <th>Accepted Offer</th>
                    <th>Execution Details</th>
                  </tr>
                </thead>
                <tbody>
                  {counterData.map((data, i) => {
                    let inputField = (
                      <Input
                        name={data.key}
                        onChange={(e) =>
                          this.updateExecutionOffer(data.key, e.target.value)
                        }
                      />
                    );
                    if (data.key === "comments")
                      inputField = (
                        <Input
                          type="textarea"
                          name={data.key}
                          onChange={(e) =>
                            this.updateExecutionOffer(data.key, e.target.value)
                          }
                        />
                      );
                    if (data.key === "financingType")
                      inputField = (
                        <Input
                          type="select"
                          name={data.key}
                          onChange={(e) =>
                            this.updateExecutionOffer(data.key, e.target.value)
                          }
                        >
                          <option value={null}>Select type</option>
                          <option value="Cash">Cash</option>
                          <option value="Lease">Lease</option>
                          <option value="Conventional">Conventional</option>
                          <option value="FHA">FHA</option>
                          <option value="VA">VA</option>
                          <option value="Seller Finance">Seller Finance</option>
                        </Input>
                      );

                    if (data.key === "possession")
                      inputField = (
                        <Input
                          type="select"
                          name={data.key}
                          onChange={(e) =>
                            this.updateExecutionOffer(data.key, e.target.value)
                          }
                        >
                          <option value={null}>Select type</option>
                          <option value="Upon Close & Fund">
                            Upon Close &amp; Fund
                          </option>
                          <option value="Leaseback">Leaseback</option>
                        </Input>
                      );

                    if (data.key === "saleOfOtherProperty")
                      inputField = (
                        <Input
                          type="select"
                          name={data.key}
                          onChange={(e) =>
                            this.updateExecutionOffer(data.key, e.target.value)
                          }
                        >
                          <option value={null}>Select type</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Input>
                      );

                    if (data.key === "rightToTerminate")
                      inputField = (
                        <Input
                          type="select"
                          name={data.key}
                          onChange={(e) =>
                            this.updateExecutionOffer(data.key, e.target.value)
                          }
                        >
                          <option value={null}>Select type</option>
                          <option value="Full Waiver">Full Waiver</option>
                          <option value="Partial Waiver">Partial Waiver</option>
                          <option value="Additional Right To Terminate">
                            Additional Right To Terminate
                          </option>
                          <option value="No Waiver">No Waiver</option>
                        </Input>
                      );

                    if (data.key === "closingDate")
                      inputField = (
                        <Input
                          type="date"
                          name={data.key}
                          onChange={(e) =>
                            this.updateExecutionOffer(data.key, e.target.value)
                          }
                        />
                      );

                    return (
                      <tr key={i}>
                        <td>{data.name}</td>
                        <td>{data.values[0]}</td>
                        {executeOfferData?.length && (
                          <td>
                            {executeOfferData?.offerData[data.key]
                              ? executeOfferData?.offerData[data.key]
                              : "N/A"}
                          </td>
                        )}
                        {buyer !== user.uid && status !== "Executed" && (
                          <td>{inputField}</td>
                        )}
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={(executeOfferData?.length || 0) + 3}>
                      <div className="terms-and-conditions-heading">
                        Contract Execution Document:
                      </div>
                      {dropzone}

                      {this.state.executeOfferData.executionDocument && (
                        <a
                          target="_blank"
                          href={this.state.executeOfferData.executionDocument}
                        >
                          <strong>Uploaded Execution Document</strong>
                        </a>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalBody>
            <ModalFooter>
              {buyer !== user.uid && status !== "Executed" && (
                <Button
                  disabled={this.state.uploadingDocument}
                  color="primary"
                  onClick={(e) => {
                    if (!this.state.executeOfferData.executionDocument) {
                      toast.error(`Please select document.`, {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                      return;
                    } else if (!offer.verified) {
                      this.setState({
                        updateModalText: "Execute Offer",
                      });
                      this.verifyOfferModalToggle();
                      return;
                    }
                    this.toggleExecutionModal();
                    this.saveExecuteOffer();
                  }}
                >
                  Execute Offer
                </Button>
              )}{" "}
              <Button color="secondary" onClick={this.toggleExecutionModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          {/* Send SMS Modal */}

          <Modal
            isOpen={this.state.sendSMSModal}
            toggle={() => {
              this.sendSMSModalToggle();
              this.setState({
                sendingOffersToSellerSMS: false,
              });
            }}
            size="md"
          >
            <ModalHeader
              toggle={() => {
                this.sendSMSModalToggle();
                this.setState({
                  sendingOffersToSellerSMS: false,
                });
              }}
            >
              Send SMS
            </ModalHeader>
            <ModalBody>
              <div className="custom-control custom-checkbox m-vertical-7 my-4">
                <input
                  className="custom-control-input propertyFilterChkBox"
                  type="checkbox"
                  name="sendSMS"
                  id="sendSMS"
                  checked={this.state.sendMessage}
                  onChange={(e) => {
                    this.setState({
                      sendMessage: e.target.checked,
                    });
                  }}
                />
                <label
                  style={{
                    margin: "0 !important",
                  }}
                  className="custom-control-label custom-offers-label m-0"
                  htmlFor="sendSMS"
                >
                  {this.state.sendingOffersToSellerSMS
                    ? "Check to send ‘Offer Sent to Seller' text to Buyer's Agent"
                    : "Do you want to send SMS to notify the Buyers Agent?"}
                </label>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  backgroundColor: "#28a745",
                }}
                onClick={(e) => {
                  this.sendSMSModalToggle();
                  if (this.state.sendingOffersToSellerSMS) {
                    this.setState({
                      sendOfferToSellerModal: true,
                    });
                  }
                }}
              >
                {this.state.updateModalText}
              </Button>
            </ModalFooter>
          </Modal>

          {/* Veirfy Offer Modal */}

          <Modal
            isOpen={this.state.verifyOfferModal}
            toggle={() => {
              this.verifyOfferModalToggle();
            }}
            size="md"
          >
            <ModalHeader toggle={() => this.verifyOfferModalToggle()}>
              Verify Offer
            </ModalHeader>
            <ModalBody>
              <div className="custom-control custom-checkbox m-vertical-7 my-4">
                <input
                  className="custom-control-input propertyFilterChkBox"
                  type="checkbox"
                  name="sendSMS"
                  id="sendSMS"
                  checked={this.state.verifyOffer}
                  onChange={(e) => {
                    this.setState({
                      verifyOffer: e.target.checked,
                    });
                  }}
                />
                <label
                  style={{
                    margin: "0 !important",
                  }}
                  className="custom-control-label custom-offers-label m-0"
                  htmlFor="sendSMS"
                >
                  Do you want to verify this offer?
                </label>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  backgroundColor: "#28a745",
                }}
                onClick={(e) => {
                  this.verifyOfferModalToggle();
                  this.toggleExecutionModal();
                  this.saveExecuteOffer();
                }}
              >
                {this.state.updateModalText}
              </Button>
            </ModalFooter>
          </Modal>

          {/* sms blast modal */}
          <Modal
            isOpen={this.state.smsBlast}
            size="md"
            fade={false}
            toggle={() =>
              this.setState({
                smsBlast: !this.state.smsBlast,
              })
            }
          >
            <ModalHeader
              className="modal-header"
              toggle={() =>
                this.setState({
                  smsBlast: !this.state.smsBlast,
                })
              }
            ></ModalHeader>
            <ModalBody className="modal-body">
              <Container>
                <Row>
                  <Col>
                    <div className="form-content-box content-box-signupCheck">
                      <div className="details">
                        <h3>Select listings to send</h3>
                        {/* <p>
                          Audience: <b>{smsBlastAudience}</b>
                        </p> */}
                        <p>Audience: <b>{this.state?.smsBlastSelectedAgents?.length}</b></p>
                        <form>
                          <FormGroup className="form-group pt-14 text-left">

                          <Label for="agents" className="label-font">Select Buyer Agents</Label>
                            <div className="m-0 p-0 share-offers-modal-container">
                              {allOffers?.length > 1 && <div
                                className="custom-control custom-checkbox m-vertical-7">
                                <input
                                  className="custom-control-input propertyFilterChkBox"
                                  type="checkbox"
                                  onChange={this.toggleSelectAll}
                                  checked={
                                    this.state.smsBlastSelectedAgents?.length === allOffers?.length
                                  }
                                  id={"all"}
                                />
                                <label
                                  className="custom-control-label custom-offers-label m-0"
                                  for="all"
                                >
                                  Select All
                                </label>
                              </div>}
                                {allOffers?.map((offer, key) => {
                                  return (
                                    <div
                                      className="custom-control custom-checkbox m-vertical-7"
                                      key={key}
                                    >
                                      <input
                                        className="custom-control-input propertyFilterChkBox"
                                        type="checkbox"
                                        checked={this.state.smsBlastSelectedAgents?.includes(offer.id)}
                                        onChange={(e) => this.handleAgentSelection(e, offer.id)}
                                        id={`agent_${offer.id}`}
                                        name={`agent_${offer.id}`}
                                        value={offer.id}
                                      />
                                      <label
                                        className="custom-control-label custom-offers-label m-0"
                                        for={`agent_${offer.id}`}
                                      >
                                        {" "}
                                        {offer.agentName || offer.buyerEmail || "Unknown Agent"}
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                            <Label for="templates" className="label-font">
                              Select Text Message Template
                            </Label>
                            <Select
                              isMulti={false}
                              name="templates"
                              options={smsTemplates}
                              onChange={this.handleTemplateChange}
                              value={{
                                label: this.state.smsTxt,
                                value: this.state.smsTxt
                                  .toLowerCase()
                                  .replace(" ", "_"),
                              }}
                            />

                            {this.state.smsTxt.endsWith("%deadline%.") && (
                              <>
                                <Label for="deadline" className="label-font">
                                  Select Deadline
                                </Label>
                                <Input
                                  type="datetime-local"
                                  name="deadline"
                                  value={this.state.deadline}
                                  onChange={(e) =>
                                    this.setState({
                                      deadline: e.target.value,
                                    })
                                  }
                                />
                              </>
                            )}
                            <Label for="comments" className="label-font">
                              Type Message
                            </Label>
                            <Input
                              className="textarea-follow-listing"
                              type="textarea"
                              name="comments"
                              value={this.state.smsTxt}
                              onChange={(e) =>
                                this.setState({ smsTxt: e.target.value })
                              }
                              placeholder="Message"
                            />
                          </FormGroup>

                          <div className="form-group mb-0">
                            <button
                              type="button"
                              className="btn-md button-theme btn-block mb-20"
                              onClick={this.sendSMSToAll}
                            >
                              {this.state.sendingSMS ? "Sending..." : "Send"}
                            </button>
                            <a
                              onClick={() =>
                                this.setState({
                                  smsBlast: false,
                                })
                              }
                            >
                              No
                            </a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          {/* Bulk Share Modal */}
          <Modal
            isOpen={shareOffersModel}
            toggle={() =>
              this.setState({
                shareOffersModel: !shareOffersModel,
              })
            }
            className="modal-danger"
          >
            <ModalHeader>Confirmation</ModalHeader>
            <ModalBody>
              <Label>Name</Label>
              <Input
                type="text"
                name="name"
                className="input mb-2"
                placeholder="Name"
                onChange={(e) =>
                  this.setState({ shareOfferName: e.target.value })
                }
              />

              <Label>Email</Label>
              <Input
                type="text"
                name="email"
                className="input mb-4"
                placeholder="Email address"
                onChange={(e) =>
                  this.setState({ shareOfferEmail: e.target.value })
                }
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                onClick={() =>
                  this.setState({
                    shareOffersModel: !shareOffersModel,
                  })
                }
              >
                Cancel
              </Button>{" "}
              <Button
                color="success"
                disabled={sendingOffers}
                onClick={this.handleShareOffers}
              >
                {sendingOffers ? "Sending..." : "Send"}
              </Button>
            </ModalFooter>
          </Modal>

          {/* Accept Comment Modal */}
          <Modal
            isOpen={this.state.acceptCommentModal}
            size="md"
            fade={false}
            toggle={() =>
              this.setState({
                acceptCommentModal: !this.state.acceptCommentModal,
              })
            }
          >
            <ModalBody className="modal-body py-5 text-center">
              <h5 className="p-0 m-0">Reason for Accepted Offer</h5>
              <p className="p-0 m-0 mt-3">{acceptComment}</p>
              <Button
                color="success mt-3 w-25"
                onClick={() => {
                  this.setState({
                    acceptCommentModal: !this.state.acceptCommentModal,
                  });
                }}
              >
                Close
              </Button>
            </ModalBody>
          </Modal>

          {/* Send to seller Modal */}
          <Modal
            isOpen={this.state.sendOfferToSellerModal}
            toggle={() =>
              this.setState({
                sendOfferToSellerModal: !this.state.sendOfferToSellerModal,
              })
            }
            className="modal-danger"
          >
            <ModalHeader>Confirmation</ModalHeader>
            <ModalBody>
              <Label className="my-3">Select Sellers</Label>
              <div className="m-0 p-0 share-offers-modal-container mb-4">
                {this.state.seller?.agents?.map((value, key) => {
                  return (
                    <div
                      className="custom-control custom-checkbox m-vertical-7"
                      key={key}
                    >
                      <input
                        className="custom-control-input propertyFilterChkBox"
                        type="checkbox"
                        checked={this.state.selectedSellers.includes(
                          value.agent_email
                        )}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({
                            selectedSellers:
                              this.state.selectedSellers.includes(
                                value.agent_email
                              )
                                ? this.state.selectedSellers.filter(
                                    (item) => item !== value.agent_email
                                  )
                                : [
                                    ...this.state.selectedSellers,
                                    value.agent_email,
                                  ],
                          });
                        }}
                        id={key + "seller_share"}
                        name={value.agent_email}
                        value={value.agent_email}
                      />
                      <label
                        className="custom-control-label custom-offers-label m-0"
                        for={key + "seller_share"}
                      >
                        {" "}
                        {value.agent_email}
                      </label>
                    </div>
                  );
                })}
              </div>
              {/* {this.state.isAllOffers && (
                <>
                  {" "} */}
              <Label>Send Later?</Label>
              <div className="custom-control custom-checkbox m-vertical-7">
                <input
                  className="custom-control-input propertyFilterChkBox"
                  type="checkbox"
                  checked={this.state.isSendFuture}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                      isSendFuture: !this.state.isSendFuture,
                    });
                  }}
                  id={"send_later"}
                  name={"send_later"}
                  value={this.state.isSendFuture}
                />
                <label
                  className="custom-control-label custom-offers-label m-0"
                  for={"send_later"}
                >
                  Send offers to the sellers later.
                </label>
              </div>
              {/* </>
              )} */}
              {this.state.isSendFuture && (
                <>
                  {" "}
                  <Label>Send Date & Time</Label>
                  <div className="custom-control m-vertical-7 p-0">
                    <input
                      type="datetime-local"
                      className="form-control"
                      name="futureDate"
                      value={this.state.futureDate}
                      onChange={(e) => {
                        this.setState({
                          futureDate: e.target.value,
                        });
                      }}
                    />
                  </div>
                </>
              )}
              <Label>
                Send All Offers {this.state.isSendFuture && "Later"}
              </Label>
              <div className="custom-control custom-checkbox m-vertical-7">
                <input
                  className="custom-control-input propertyFilterChkBox"
                  type="checkbox"
                  checked={this.state.isAllOffers}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                      isAllOffers: !this.state.isAllOffers,
                    });
                  }}
                  id={"all_offers"}
                  name={"all_offers"}
                  value={this.state.isAllOffers}
                />
                <label
                  className="custom-control-label custom-offers-label m-0"
                  for={"all_offers"}
                >
                  Send all offers of this listing{" "}
                  {this.state.isSendFuture && "later"}
                </label>
              </div>
              <Label>Attatch Spreadsheet to Email</Label>
              <div className="custom-control custom-checkbox m-vertical-7">
                <input
                  className="custom-control-input propertyFilterChkBox"
                  type="checkbox"
                  checked={this.state.isAttachSpreadsheet}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                      isAttachSpreadsheet: !this.state.isAttachSpreadsheet,
                    });
                  }}
                  id={"attach_spreadsheet"}
                  name={"attach_spreadsheet"}
                  value={this.state.isAttachSpreadsheet}
                />
                <label
                  className="custom-control-label custom-offers-label m-0"
                  for={"attach_spreadsheet"}
                >
                  Do you want to attach the Offer Spreadsheet to the email?
                </label>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                onClick={() =>
                  this.setState({
                    sendOfferToSellerModal: !this.state.sendOfferToSellerModal,
                    sendingOffersToSellerSMS: false,
                  })
                }
              >
                Cancel
              </Button>{" "}
              <Button
                color="success"
                disabled={this.state.sendingOffersToSeller}
                onClick={() => {
                  if (this.state.isAttachSpreadsheet) {
                    this.spreadSheetToggle();
                    this.setState({
                      sendOfferToSellerModal:
                        !this.state.sendOfferToSellerModal,
                    });
                  } else {
                    this.handleSendOffers();
                  }
                }}
              >
                {this.state.sendingOffersToSeller
                  ? "Sending..."
                  : this.state.isAttachSpreadsheet
                  ? "Create Spreadsheet"
                  : "Send"}
              </Button>
            </ModalFooter>
          </Modal>

          {/* Send to title modal */}
          <Modal
            size={sendToTitleIncludeOptions ? "md" : "lg"}
            isOpen={this.state.sendToTitleModal}
            toggle={() =>
              this.setState({
                sendToTitleModal: !this.state.sendToTitleModal,
                sendToTitleIncludeOptions: false,
              })
            }
            className="modal-danger"
          >
            <ModalHeader>Confirmation</ModalHeader>
            <ModalBody>
              <Form>
                <div className="row">
                  {!sendToTitleIncludeOptions ? (
                    <>
                      <div className="col-md-4">
                        <FormGroup className="mb-4">
                          <Label for="title">Title</Label>
                          <Input
                            type="text"
                            name="title"
                            className="input"
                            value={this.state.seller.title_company}
                            onChange={(e) => {
                              this.setState({
                                seller: {
                                  ...this.state.seller,
                                  title_company: e.target.value,
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-4">
                          <Label for="executedDate">Executed Date</Label>
                          <Input
                            type="date"
                            name="executedDate"
                            value={
                              moment(
                                keyDates.executedDate,
                                "YYYY/MM/DD"
                              ).format("YYYY-MM-DD") || ""
                            }
                            onChange={(e) => {
                              this.handleKeyDates(e);
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-4">
                          <Label for="earnestMoneyDue">
                            Earnest/Option Money Due
                          </Label>
                          <Input
                            type="date"
                            onChange={(e) => {
                              this.handleKeyDates(e);
                            }}
                            name="earnestMoneyDue"
                            value={
                              moment(
                                keyDates.earnestMoneyDue,
                                "YYYY/MM/DD"
                              ).format("YYYY-MM-DD") || ""
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-4">
                          <Label for="optionPeriodEnd">Option Period End</Label>
                          <Input
                            type="date"
                            onChange={(e) => {
                              this.handleKeyDates(e);
                            }}
                            name="optionPeriodEnd"
                            value={
                              moment(
                                keyDates.optionPeriodEnd,
                                "YYYY/MM/DD"
                              ).format("YYYY-MM-DD") || ""
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-4">
                          <Label for="surveyDue">Survey Due</Label>
                          <Input
                            type="date"
                            onChange={(e) => {
                              this.handleKeyDates(e);
                            }}
                            name="surveyDue"
                            value={
                              moment(keyDates.surveyDue, "YYYY/MM/DD").format(
                                "YYYY-MM-DD"
                              ) || ""
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-4">
                          <Label for="financingDeadline">
                            Financing Deadline
                          </Label>
                          <Input
                            type="date"
                            onChange={(e) => {
                              this.handleKeyDates(e);
                            }}
                            name="financingDeadline"
                            value={
                              moment(
                                keyDates.financingDeadline,
                                "YYYY/MM/DD"
                              ).format("YYYY-MM-DD") || ""
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-4">
                          <Label for="hoaDue">HOA Documents Due</Label>
                          <Input
                            type="date"
                            onChange={(e) => {
                              this.handleKeyDates(e);
                            }}
                            name="hoaDue"
                            value={
                              moment(keyDates.hoaDue, "YYYY/MM/DD").format(
                                "YYYY-MM-DD"
                              ) || ""
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-4">
                          <Label for="closingDate">Closing Date</Label>
                          <Input
                            type="date"
                            value={
                              moment(keyDates.closingDate, "YYYY/MM/DD").format(
                                "YYYY-MM-DD"
                              ) || ""
                            }
                            onChange={(e) => {
                              this.handleKeyDates(e);
                            }}
                            name="closingDate"
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-4">
                          <Label for="sellersAgent">Sellers Agent</Label>
                          <Input
                            type="text"
                            name="sellersAgent"
                            className="input"
                            value={this.state.seller?.seller_name}
                            onChange={(e) => {
                              this.setState({
                                seller: {
                                  ...this.state.seller,
                                  seller_name: e.target.value,
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-4">
                          <Label for="sellersAgentTransactionCoordinator">
                            Sellers Agent Transaction Coordinator
                          </Label>
                          <Input
                            type="text"
                            name="sellersAgentTransactionCoordinator"
                            className="input"
                            value={this.state.seller?.transaction_coordinator}
                            onChange={(e) => {
                              this.setState({
                                seller: {
                                  ...this.state.seller,
                                  transaction_coordinator: e.target.value,
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-4">
                          <Label for="sellers">Sellers</Label>
                          <Select
                            id="sellers"
                            name="sellers"
                            isMulti
                            value={sellerAgents}
                            options={this.state.seller?.agents?.map((agent) => {
                              return {
                                value: agent.agent_name,
                                label: agent.agent_name,
                              };
                            })}
                            onChange={(e) => {
                              this.setState({
                                sellerAgents: e,
                              });
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-4">
                          <Label for="buyersAgent">Buyers Agent</Label>
                          <Input
                            type="text"
                            name="buyersAgent"
                            className="input"
                            value={offer.agentName || offer.buyerName}
                            onChange={(e) => {
                              this.setState({
                                offer: {
                                  ...this.state.offer,
                                  agentName: e.target.value,
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-4">
                          <Label for="buyers">Buyers</Label>
                          <Input
                            type="text"
                            name="buyers"
                            className="input"
                            onChange={(e) => {
                              this.setState({
                                buyers: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-4">
                          <Label for="lender">Lender</Label>
                          <Input
                            type="text"
                            name="lender"
                            className="input"
                            value={this.state.offer?.lenderCompany}
                            onChange={(e) => {
                              this.setState({
                                offer: {
                                  ...this.state.offer,
                                  lenderCompany: e.target.value,
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup className="mb-4">
                          <Label for="lender">Email Body</Label>
                          <Input
                            type="textarea"
                            name="emailBody"
                            className="input"
                            value={this.state.emailBody}
                            onChange={(e) => {
                              this.setState({
                                emailBody: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup className="mb-4">
                          <Label for="lender">Email Closing</Label>
                          <Input
                            type="textarea"
                            name="emailClosing"
                            className="input"
                            value={this.state.emailClosing}
                            onChange={(e) => {
                              this.setState({
                                emailClosing: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-12">
                        <Label>Select Documents to send to Title Company</Label>
                        {sendToTitleUpdatedDocs.map((doc, key) => (
                          <div className="custom-control custom-checkbox m-vertical-7" key={key}>
                            <Input
                              className="custom-control-input propertyFilterChkBox"
                              type="checkbox"
                              name={doc.name}
                              id={doc.name}
                              onChange={(e) => {
                                this.handleSendToTitleDocs(e, doc);
                              }}
                            />
                            <i
                              class="fa fa-file-pdf-o mr-2"
                              aria-hidden="true"
                            ></i>

                            <label
                              style={{
                                margin: "0 !important",
                              }}
                              className="custom-control-label custom-offers-label m-0"
                              htmlFor={doc.name}
                            >
                              {doc.name?.replace(/^[\d_]*/, "")}
                            </label>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-12">
                        <Label>
                          Would you like to include the following in send to
                          title email?
                        </Label>

                        {options.map((o, i) => (
                          <div className="custom-control custom-checkbox m-vertical-7">
                            <input
                              className="custom-control-input propertyFilterChkBox"
                              type="checkbox"
                              checked={includeOptions[o.value]}
                              name={o.value}
                              id={o.value}
                              onChange={(e) => {
                                this.handleIncludeOptionsChange(e);
                              }}
                            />
                            <label
                              style={{
                                margin: "0 !important",
                              }}
                              className="custom-control-label custom-offers-label m-0"
                              htmlFor={o.value}
                            >
                              {o.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </Form>
            </ModalBody>
            <ModalFooter>
              {sendToTitleIncludeOptions && (
                <>
                  <Button
                    color="primary"
                    onClick={() => {
                      this.setState({
                        sendToTitleIncludeOptions: false,
                      });
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    color="warning"
                    onClick={() => {
                      localStorage["send-to-title-email-preview"] =
                        JSON.stringify({
                          inputs: {
                            ...keyDates,
                            sellers_agent: includeOptions.listingAgent
                              ? this.state.seller?.seller_name
                              : "",
                            transaction_coordinator:
                              includeOptions.transactionCoordinator
                                ? this.state.seller?.transaction_coordinator
                                : "",
                            transaction_coordinator_email:
                              this.state.seller?.transaction_coordinator_email,
                            sellers: includeOptions.sellers
                              ? sellerAgents.map((agent) => agent.value)
                              : [],
                            buyers_agent: includeOptions.buyersAgent
                              ? offer.agentName || offer.buyerName
                              : "",
                            buyers: this.state.buyers,
                            lender: includeOptions.lender
                              ? offer.lenderCompany
                              : "",
                            title_company: this.state.seller?.title_company,
                            createdAt: offer.createdAt,
                            agentName: offer.agentName || offer.buyerName,
                            body: this.state.emailBody,
                            closing: this.state.emailClosing,
                            sellerEmail: this.state.seller.seller_email,
                            phoneNumber: this.state.seller.seller_phone,
                            profilePhoto: this.props.user?.profilePhoto["64"],
                            brokerageName: this.props.user?.brokerage_name,
                          },
                          seller: this.state.seller,
                          offerId: offer.id,
                        });
                    }}
                  >
                    <Link
                      target="_blank"
                      to={{
                        pathname: "/send-to-title-email-preview",
                      }}
                      className="preview-link text-white"
                    >
                      Preview Email
                    </Link>
                  </Button>
                </>
              )}
              <Button
                color="danger"
                onClick={() =>
                  this.setState({
                    sendToTitleModal: !this.state.sendToTitleModal,
                    sendToTitleIncludeOptions: false,
                  })
                }
              >
                Cancel
              </Button>
              <Button
                color="success"
                disabled={this.state.sendingOfferToTitle}
                onClick={() => {
                  if (!sendToTitleIncludeOptions) {
                    this.setState({
                      sendToTitleIncludeOptions: !sendToTitleIncludeOptions,
                    });
                  } else {
                    this.handleSendOfferToTitle();
                  }
                }}
              >
                {!sendToTitleIncludeOptions
                  ? "Next"
                  : this.state.sendingOfferToTitle
                  ? "Sending..."
                  : "Send"}
                {/* {this.state.sendingOfferToTitle ? "Sending..." : "Send"} */}
              </Button>
            </ModalFooter>
          </Modal>

          {/* spreadsheet modal */}
          <Modal
            isOpen={this.state.spreadSheetModal}
            size="lg"
            fade={false}
            toggle={this.spreadSheetToggle}
            className="offers-modal"
          >
            <ModalHeader
              className="modal-header"
              toggle={this.spreadSheetToggle}
            ></ModalHeader>
            <ModalBody className="modal-body">
              <Container className="mb-40">
                <Row>
                  <Col>
                    <h5 className="modal-title text-center">
                      Drag Spreadsheet Attributes To Right
                    </h5>
                  </Col>
                </Row>
                <Row className="mt-20">
                  <Col>
                    <h6 className="text-center">Fields</h6>
                  </Col>
                  <Col>
                    <h6 className="text-center">Export</h6>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="drag-and-drop-container">
                      <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              style={getList1Style(snapshot.isDraggingOver)}
                            >
                              {this.state.spreadsheetOptions.map(
                                (item, index) => (
                                  <Draggable
                                    key={item.key}
                                    draggableId={item.key}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        {item.name}
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                        <Droppable droppableId="droppable2">
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              style={getList2Style(snapshot.isDraggingOver)}
                            >
                              {this.state.spreadsheetSelected.map(
                                (item, index) => (
                                  <Draggable
                                    key={item.key}
                                    draggableId={item.key}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        {item.name}
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="flex-center flex-wrap">
                      <button
                        className="cancel-btn"
                        onClick={() => {
                          this.spreadSheetToggle();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="gnerate-file"
                        onClick={() => {
                          this.handleSendOffers();
                        }}
                      >
                        {this.state.sendingOffersToSeller
                          ? "Sending..."
                          : "Send"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Offers;
