import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducers from "./Store/Reducers";
import App from './App';
// import createBrowserHistory from 'history/createBrowserHistory';
import firebase, { FirebaseContext } from './components/Firebase';
import { BrowserRouter as Router } from "react-router-dom";
import ReactBreakpoints from 'react-breakpoints'

const store = createStore(combineReducers(reducers), compose(applyMiddleware(thunk)));
const breakpoints = {
    mobile: 320,
    mobileLandscape: 480,
    tablet: 768,
    tabletLandscape: 1024,
    desktop: 1200,
    desktopLarge: 1500,
    desktopWide: 1920,
}

const removeUnwantedIframe = () => {
    const iframe = document.querySelector('iframe[style*="z-index: 2147483647"]');
    if (iframe) {
        iframe.parentNode.removeChild(iframe);
        console.log("Removed unwanted iframe:", iframe);
    }
};

// Remove the iframe initially and observe for new additions
removeUnwantedIframe();
const observer = new MutationObserver(removeUnwantedIframe);
observer.observe(document.body, { childList: true, subtree: true });

window.addEventListener('beforeunload', () => observer.disconnect());

ReactDOM.render(<FirebaseContext.Provider value={firebase}>
    <Router forceRefresh={true}>
        <ReactBreakpoints breakpoints={breakpoints}>
            <Provider store={store}>
                <App />
            </Provider>
        </ReactBreakpoints>
    </Router>
</FirebaseContext.Provider>, document.getElementById('root'));
